export class Location {

    constructor(
        type,
        coordinates,
        
    ) {
        this.type = type || "Point";
        this.coordinates = coordinates || [14.117355, 37.650413];
    }
}