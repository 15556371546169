import axios from 'axios';
import router from '../router';
import VueCookies from 'vue-cookies';

const client = axios.create();

client.interceptors.request.use(function (config) {
    if (config.url.includes('login')) return config;

    const token = VueCookies.get('atlas_token');

    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
}, function (err) {
    return Promise.reject(err);
});

client.interceptors.response.use(
    (res) => res,
    function (err) {
        const res = err.response;
        if (!res.config.url.includes('login') && !res.config.url.includes('change-password')) {
            if (res.status == 401) {
                const hasToken = VueCookies.isKey('atlas_token');
                if (hasToken) {
                    VueCookies.remove('atlas_token');
                    VueCookies.remove('atlas_user');
                    VueCookies.remove('atlas_role');
                }
                router.push('/login');
                
            }

        }
        if (res.status == 402) {
            //console.log(res.data)
            router.push({
                name: 'ProviderStripeConfiguration',
                params: { info: res.data.user.stripeConnectLink},
              })
            return
        }

        return Promise.reject(err);
    });

export { client }