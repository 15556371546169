var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"fieldObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.emitCategory)}}},[_c('validation-provider',{attrs:{"name":_vm.$t('common.name'),"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"id":"category-name-it-fieldset","label":((_vm.$t('common.name')) + " (" + (_vm.$t('language.it.short')) + ")"),"label-cols":"4","label-for":"category-name-it"}},[_c('b-form-input',{attrs:{"id":"category-name-it","placeholder":((_vm.$t(
            'admin.platformManagement.categories.edit.categoryName'
          )) + " (" + (_vm.$t('language.it.full')) + ")"),"state":_vm.getValidationState(validationContext)},model:{value:(_vm.newCategory.name[0].text),callback:function ($$v) {_vm.$set(_vm.newCategory.name[0], "text", $$v)},expression:"newCategory.name[0].text"}}),_c('b-form-invalid-feedback',{attrs:{"id":"category-name-it-live-feedback"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"id":"category-name-en-fieldset","label":((_vm.$t('common.name')) + " (" + (_vm.$t('language.en.short')) + ")"),"label-cols":"4","label-for":"category-name-en"}},[_c('b-form-input',{attrs:{"id":"category-name-en","placeholder":((_vm.$t(
          'admin.platformManagement.categories.edit.categoryName'
        )) + " (" + (_vm.$t('language.en.full')) + ")")},model:{value:(_vm.newCategory.name[1].text),callback:function ($$v) {_vm.$set(_vm.newCategory.name[1], "text", $$v)},expression:"newCategory.name[1].text"}})],1),_c('b-form-group',{attrs:{"id":"category-description-it-fieldset","label":((_vm.$t('common.description')) + " (" + (_vm.$t('language.it.short')) + ")"),"label-cols":"4","label-for":"category-description-it"}},[_c('b-form-textarea',{attrs:{"id":"category-description-it","placeholder":((_vm.$t(
          'admin.platformManagement.categories.edit.categoryDescription'
        )) + " (" + (_vm.$t('language.it.full')) + ")")},model:{value:(_vm.newCategory.description[0].text),callback:function ($$v) {_vm.$set(_vm.newCategory.description[0], "text", $$v)},expression:"newCategory.description[0].text"}})],1),_c('b-form-group',{attrs:{"id":"category-description-en-fieldset","label":((_vm.$t('common.description')) + " (" + (_vm.$t('language.en.short')) + ")"),"label-cols":"4","label-for":"category-description-en"}},[_c('b-form-textarea',{attrs:{"id":"category-description-en","placeholder":((_vm.$t(
          'admin.platformManagement.categories.edit.categoryDescription'
        )) + " (" + (_vm.$t('language.en.full')) + ")")},model:{value:(_vm.newCategory.description[1].text),callback:function ($$v) {_vm.$set(_vm.newCategory.description[1], "text", $$v)},expression:"newCategory.description[1].text"}})],1),_c('h6',[_vm._v(_vm._s(_vm.$t("admin.platformManagement.extraFields.edit.icon")))]),_c('IconsTable',{attrs:{"selected":_vm.newCategory.icon},on:{"selected-icon":function($event){_vm.newCategory.icon = $event}}}),_c('br'),_c('b-row',{attrs:{"align-h":"end","no-gutters":""}},[_c('FlatButton',{nativeOn:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(_vm._s(_vm.$t("actions.cancel")))]),_c('RaisedButton',{staticClass:"ml-3",attrs:{"type":"submit","variant":"success"}},[_vm._v(_vm._s(_vm.$t("actions.confirm")))])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }