<template>
  <div class="external-links-editor">
    <b-row align-v="end" align-h="center" no-gutters class="mb-3">
      <RaisedButton type="button" @click.native="openLinkModal">
        <font-awesome-icon icon="plus"></font-awesome-icon>
        <span class="ml-2">{{
          $t("components.contentEditPage.addExternalLink")
        }}</span>
      </RaisedButton>
    </b-row>

    <CustomAlert type="info" v-if="!externalLinks || !externalLinks.length > 0">
      <font-awesome-icon icon="info-circle"></font-awesome-icon>
      <span class="ml-2">{{
        $t("components.externalLinkEditor.noExternalLinks")
      }}</span>
    </CustomAlert>

    <template v-else>
      <div class="external-links-list">
        <div
          class="external-link-item"
          v-for="(link, index) of externalLinks"
          :key="index"
        >
          <div class="external-link-icon">
            <font-awesome-icon icon="link"></font-awesome-icon>
          </div>

          <b-col>
            <h6 class="mb-0">{{ getFieldTranslation(link, "title") }}</h6>
            <p class="text-muted mb-0">
              {{ getFieldTranslation(link, "description") }}
            </p>
            <a :href="link.url" target="_blank">{{ link.url }}</a>
          </b-col>

          <div class="px-3">
            <IconButton @click.native="editLink(index)">
              <font-awesome-icon icon="pen"></font-awesome-icon>
            </IconButton>
            <IconButton @click.native="deleteExternalLink(index)" class="ml-3">
              <font-awesome-icon
                class="text-danger"
                icon="trash"
              ></font-awesome-icon>
            </IconButton>
          </div>
        </div>
      </div>
    </template>

    <b-modal
      hide-footer
      :title="
        selectedIndex == undefined
          ? $t('components.contentEditPage.newExternalLink')
          : $t('components.contentEditPage.updateExternalLink')
      "
      id="external-link-modal"
      size="lg"
      centered
    >
      <validation-observer ref="externalLinkObserver" v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(submitExternalLink)">
          <!-- Riga IT -->
          <b-row>
            <b-col cols="12" lg="4">
              <validation-provider
                name="title-it"
                :rules="{
                  required: true,
                }"
                v-slot="validationContext"
              >
                <b-form-group
                  :id="'external-link-title-field-it'"
                  class="mb-3"
                  :label="$t('components.pricePlanEditor.titleIt')"
                  :label-for="'external-link-title-it'"
                >
                  <b-form-input
                    :id="'external-link-title-it'"
                    v-model.trim="newExternalLink.title[0].text"
                    :placeholder="$t('components.pricePlanEditor.titleIt')"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="8">
              <validation-provider
                name="description-it"
                :rules="{
                  required: true,
                }"
                v-slot="validationContext"
              >
                <b-form-group
                  :id="'external-link-description-fieldset-it'"
                  class="mb-3"
                  :label="$t('components.pricePlanEditor.descriptionIt')"
                  :label-for="'external-link-description-it'"
                >
                  <b-form-input
                    :id="'external-link-description-it'"
                    v-model.trim="newExternalLink.description[0].text"
                    :placeholder="
                      $t('components.externalLinkEditor.descriptionIt')
                    "
                    :state="getValidationState(validationContext)"
                  ></b-form-input>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Riga EN -->
          <b-row>
            <b-col cols="12" lg="4">
              <b-form-group
                :id="'external-link-title-field-en'"
                class="mb-3"
                :label="$t('components.pricePlanEditor.titleEn')"
                :label-for="'external-link-title-en'"
              >
                <b-form-input
                  :id="'external-link-title-en'"
                  v-model.trim="newExternalLink.title[1].text"
                  :placeholder="$t('components.pricePlanEditor.titleEn')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="8">
              <b-form-group
                :id="'external-link-description-fieldset-en'"
                class="mb-3"
                :label="$t('components.pricePlanEditor.descriptionEn')"
                :label-for="'external-link-description-en'"
              >
                <b-form-input
                  :id="'external-link-description-en'"
                  v-model.trim="newExternalLink.description[1].text"
                  :placeholder="
                    $t('components.externalLinkEditor.descriptionEn')
                  "
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Riga URL -->
          <b-row>
            <b-col cols="12" lg="12">
              <validation-provider
                name="Url"
                :rules="{
                  regex:
                    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
                  required: true,
                }"
                v-slot="validationContext"
              >
                <b-form-group
                  :id="'external-link-url-fieldset'"
                  class="mb-3"
                  label="Url"
                  :label-for="'external-link-url'"
                >
                  <b-form-input
                    :id="'external-link-url'"
                    v-model.trim="newExternalLink.url"
                    placeholder="https://example.com"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>

                  <b-form-invalid-feedback :id="'external-link-url'">
                    {{ $t("components.externalLinkEditor.invalidUrl") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row align-h="end" class="mr-1">
            <b-button
              variant="light"
              type="button"
              class="border mr-3"
              @click="resetModal"
              >{{ $t("actions.cancel") }}</b-button
            >
            <b-button variant="success" type="submit">{{
              selectedIndex == undefined
                ? $t("components.contentEditPage.newExternalLink")
                : $t("components.contentEditPage.updateExternalLink")
            }}</b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import "@/variables.scss";

.external-links-editor {
  .external-links-list {
    max-height: 500px;
    overflow-y: auto;
    flex-wrap: wrap;
    border-radius: 0.25rem;
    border: 1px solid $default-gray;

    .external-link-item {
      display: flex;
      align-items: center;
      padding: 0.5rem;

      &:not(:last-of-type) {
        border-bottom: 1px solid $default-gray;
      }

      .external-link-icon {
        padding: 0 1rem;
        font-size: 1.5rem;
      }
    }
  }
}
</style>

<script>
import { ExternalLink } from "../models/ExternalLink";
import IconButton from "../components/IconButton.vue";
import RaisedButton from "../components/RaisedButton.vue";
import CustomAlert from "../components/CustomAlert.vue";
import { cloneDeep } from "lodash";

export default {
  name: "ExternalLinksEditor",
  components: {
    RaisedButton,
    IconButton,
    CustomAlert,
  },
  props: {
    externalLinks: Array,
  },
  data() {
    return {
      newExternalLink: new ExternalLink(),
      selectedIndex: undefined,
    };
  },
  methods: {
    editLink(index) {
      this.selectedIndex = index;
      this.newExternalLink = cloneDeep(this.externalLinks[index]);
      this.$bvModal.show("external-link-modal");
    },
    deleteExternalLink(index) {
      this.externalLinks.splice(index, 1);
    },
    resetModal() {
      this.newExternalLink = new ExternalLink();
      this.$bvModal.hide("external-link-modal");
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    openLinkModal() {
      this.$bvModal.show("external-link-modal");
    },
    submitExternalLink() {
      if (this.selectedIndex != undefined) {
        this.externalLinks[this.selectedIndex] = this.newExternalLink;
        this.resetModal();
        this.selectedIndex = undefined;
      } else {
        this.externalLinks.push(this.newExternalLink);
        this.resetModal();
      }
    },
  },
};
</script>