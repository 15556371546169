<template>
  <div class="provider-initialization">
    <b-container class="pt-4">
      <b-card>
        <div class="text-right"><LocalesCombo class="locales-combo" /></div>

        <div class="text-center mb-4">
          <img src="../assets/scialari.svg" width="200px" class="mb-4" />

          <h5>{{ $t("pages.provider.lastStep") }}</h5>
          <span class="text-muted">
            {{ $t("pages.provider.stripeConfig") }}
          </span>
        </div>

        <b-row align-h="center" class="mb-2">
          <b-col cols="6">
            <div class="text-center mt-4">
              <RaisedButton type="submit" variant="success" @click.native="goToStripeVerification">
                <font-awesome-icon icon="shield-alt"></font-awesome-icon>
                <span class="ml-2"> {{ $t("pages.recovery.proceed") }}</span>
              </RaisedButton>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { Recovery } from "../api";
import RaisedButton from "../components/RaisedButton.vue";
import LocalesCombo from "../components/LocalesCombo.vue";
export default {
  components: {
    RaisedButton,
    LocalesCombo,
  },
  name: "ProviderStripeConfiguration",

  data() {
    return {
        url:"",
      email: "",
      lang: this.$i18n.locale,
    };
  },
  created() {
    this.url = this.$route.params.info;
  },
  methods: {
    goToStripeVerification(){
        window.location.href = this.url;
    },
    forgotPassword() {
      let account = { username: this.email, lang: this.lang };

      this.$loading.show();

      Recovery.forgotPassword(account).then(
        () => {
          this.$loading.hide();
          this.$root.$bvToast.toast(this.$t("messages.passwordResetMsg"),
            {
              title: this.$t("messages.passwordReset"),
              variant: "success",
              solid: true,
            }
          );
          this.$router.push({ name: "Login" });
        },
        () => {
          this.$loading.hide();
          this.$bvToast.toast(this.$t("messages.resetErrorMsg"), {
            title: this.$t("messages.resetError"),
            variant: "danger",
            solid: true,
          });
        }
      );
    },
  },
};
</script>
