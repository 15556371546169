<template>
  <b-form-input
    :id="id"
    v-model="displayValue"
    :placeholder="placeholder"
    @blur="isActive = false"
    @focus="isActive = true"
  ></b-form-input>
</template>

<script>
export default {
  name: "PriceInput",
  props: {
    value: Number,
    id: {
      type: String,
      default: "price-input",
    },
    placeholder: {
      type: String,
      default: "Insert price",
    },
    currency: {
      type: String,
      default: "euro",
    },
  },
  data: () => ({
    isActive: false,
  }),
  computed: {
    currencySymbol() {
      return this.currency == "euro" ? "€" : "$";
    },
    displayValue: {
      get() {
        if (this.isActive) {
          // Cursor is inside the input field. unformat display value for user
          return this.value ? this.value.toString() : "";
        } else {
          // User is not modifying now. Format display value for user interface
          return this.value
            ? `${this.currencySymbol} ` +
                this.value
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
            : "";
        }
      },
      set(value) {
        let newValue = parseFloat(value.replace(/[^\d.]/g, ""));
        // Ensure that it is not NaN
        if (isNaN(newValue)) {
          newValue = 0;
        }
        // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
        // $emit the event so that parent component gets it
        this.$emit("input", newValue);
      },
    },
  },
};
</script>