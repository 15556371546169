<template>
  <div id="app">
    <router-view />

    <OverlayLoader />

    <cookie-law theme="blood-orange--rounded"></cookie-law>
  </div>
</template>

<style lang="scss">
#app {
  min-height: 100vh;
  height: 100%;
  background: #e5e5e5;
}
</style>

<script>
import CookieLaw from "vue-cookie-law";

export default {
  name: "App",
  components: {
    CookieLaw,
  },
  created: function () {
    navigator.permissions.query({ name: "geolocation" }).then((res) => {
      const allowed = res.state == "granted";
      this.$cookies.set("USER_POSITION_ALLOWED", allowed);
      if (!allowed) this.$cookies.remove("USER_POSITION");
    });

    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.$cookies.set("USER_POSITION_ALLOWED", true);
        const userLocation = [pos.coords.longitude, pos.coords.latitude];
        this.$cookies.set("USER_POSITION", userLocation.join(","));
      },
      (err) => {
        if (err.code == err.PERMISSION_DENIED) {
          this.$cookies.set("USER_POSITION_ALLOWED", false);
        }
        if (err.code == err.POSITION_UNAVAILABLE) {
          this.$bvModal.msgBoxOk(
            "Your current position is unavailable. Please, enable GPS in your device to improve your experience.",
            {
              title: "Position Error",
              buttonSize: "sm",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
            }
          );
        }
      }
    );

    this.checkLocale();
  },
  methods: {
    checkLocale() {
      const lastLocale = localStorage.getItem('geo_locale');
      if(lastLocale) {
        this.$i18n.locale = lastLocale;
      }
      else {
        localStorage.setItem('geo_locale', this.$i18n.locale);
      }
    },
  },
};
</script>