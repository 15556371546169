export class Price {

    constructor(
        id, 
        perPerson,
        adultPrice,
        kidPrice,
        isFree,
        options,
    ) {
        this.id = id || undefined;
        this.adultPrice = adultPrice || undefined;
        this.kidPrice = kidPrice || undefined;
        this.perPerson = perPerson || true;
        this.isFree = isFree || false;
        this.options = options || [];
    }
}