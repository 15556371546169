import Vue from 'vue'
import VueRouter from 'vue-router'

import Root from '../views/Root.vue'
import Home from '../views/Home.vue'
// import ContentsMap from '../views/ContentsMap.vue'
import ContentsCatalog from '../views/ContentsCatalog.vue'
import ContentDetail from '../views/ContentDetail.vue'
import AvailabilityForm from '../components/AvailabilityForm.vue'
import About from '../views/About.vue'
import WhoAreWe from '../views/WhoAreWe.vue'
import Contacts from '../views/Contacts.vue'
import BookingCheckout from '../views/BookingCheckout.vue'
import BookingEsit from '../views/BookingEsit.vue'
import Registration from '../views/Registration.vue'

import AdminRoot from '../views/admin/AdminRoot.vue'
import AdminPages from '../views/admin/AdminPages.vue'
import Login from '../views/Login.vue'
import Account from '../views/Account.vue'
import ContentEdit from '../views/ContentEdit.vue'
import ContentDraft from '../views/ContentDraft.vue'
import ContentsList from '../views/ContentsList.vue'
import UsersList from '../views/admin/users-management/UsersList.vue'
import UserDetail from '../views/admin/users-management/UserDetail.vue'
import UserEdit from '../views/admin/users-management/UserEdit.vue'
import RequestsList from '../views/admin/requests/RequestsList.vue'
import RequestDetail from '../views/admin/requests/RequestDetail.vue'
import Settings from '../views/Settings.vue'
import Cart from '../views/Cart.vue'
import ForgotPassword from '../views/recovery/ForgotPassword.vue'
import ResetPassword from '../views/recovery/ResetPassword.vue'
import DiscountsPage from '../views/admin/DiscountsPage.vue'

import ProviderInitialization from '../views/ProviderInitialization.vue'
import ProviderStripeConfiguration from '../views/ProviderStripeConfiguration.vue'

import VueCookies from 'vue-cookies';

Vue.use(VueRouter)

function authGuard(to, from, next) {
  var isAuthenticated = false;

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const routeWithRoles = to.matched.find(record => record.meta.roles);
  const roles = routeWithRoles ? routeWithRoles.meta.roles : [];

  const token = VueCookies.get('atlas_token');
  const role = VueCookies.get('atlas_role');
  if (token) isAuthenticated = true;

  if (requiresAuth && !isAuthenticated) next({ name: 'AdminLogin' });
  else if (requiresAuth && !roles.includes(role)) next({ name: 'Account' });
  else if (to.name == 'AdminLogin' && isAuthenticated) next({ name: 'ContentsList' });
  else next();
}

const NestedRouteContainer = {
  template: '<router-view></router-view>',
};

const routes = [
  {
    path: '/admin',
    component: AdminRoot,
    children: [
      { path: '', redirect: 'login' },
      {
        path: 'login',
        name: 'AdminLogin',
        component: Login,
        meta: { isAdmin: true },
      },
      {
        path: 'pages',
        component: AdminPages,
        children: [
          { path: '', redirect: 'contents' },
          {
            path: 'contents',
            component: NestedRouteContainer,
            meta: { requiresAuth: true, roles: ['admin', 'provider'] },
            children: [
              { path: '', name: 'ContentsList', component: ContentsList, },
              { path: 'create', name: 'ContentCreate', component: ContentEdit, },
              { path: ':id', name: 'ContentPreview', component: ContentDetail, },
              { path: ':id/edit', name: 'ContentEdit', component: ContentEdit, },
              { path: ':id/draft', name: 'ContentDraft', component: ContentDraft, },
            ]
          },
          {
            path: 'users',
            component: NestedRouteContainer,
            meta: { requiresAuth: true, roles: ['admin'] },
            children: [
              { path: '', name: 'UsersList', component: UsersList, },
              { path: 'register-provider', name: 'RegisterProvider', component: UserEdit, },
              { path: 'register', name: 'UserCreate', component: UserEdit, },
              { path: ':id', name: 'UserDetail', component: UserDetail, },
              { path: ':id/edit', name: 'UserEdit', component: UserEdit, },
            ]
          },
          {
            path: 'requests',
            component: NestedRouteContainer,
            meta: { requiresAuth: true, roles: ['provider', 'user'] },
            children: [
              { path: '', name: 'RequestsList', component: RequestsList, },
              { path: ':id', name: 'RequestDetail', component: RequestDetail, },
            ]
          },
          {
            path: 'cart',
            name: 'Cart',
            component: Cart,
            meta: { requiresAuth: true, roles: ['user'] },
          },
          {
            path: 'discounts',
            name: 'Discounts',
            component: DiscountsPage,
            meta: { requiresAuth: true, roles: ['admin'] },
          },
          {
            path: 'settings',
            name: 'Settings',
            component: Settings,
            meta: { requiresAuth: true, roles: ['admin'] },
          },
          {
            path: 'account',
            name: 'Account',
            component: Account,
            meta: { requiresAuth: true, roles: ['god', 'admin', 'provider', 'user'] },
          },
          { path: '*', redirect: 'contents/create' },
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/recovery',
    name: 'Recovery',
    component: NestedRouteContainer,
    children: [
      { 
        path: '', 
        redirect: 'home'
      },
      { 
        path: 'forgot-password', 
        name: 'ForgotPassword', 
        component: ForgotPassword 
      },
      { 
        path: 'reset-password', 
        name: 'ResetPassword', 
        component: ResetPassword 
      },
    ]
  },
  {
    path: '/register',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/booking-success',
    name: 'BookingConfirmation',
    component: BookingEsit,
    meta: { bookingSuccess: true },
  },
  {
    path: '/booking-error',
    name: 'BookingError',
    component: BookingEsit,
    meta: { bookingSuccess: false },
  },
  {
    path: '/',
    component: Root,
    children: [
      { path: '', redirect: 'home' },
      // {
      //   path: 'contents-map',
      //   name: 'ContentsMap',
      //   component: ContentsMap,
      // },
      {
        path: 'contents-catalog',
        name: 'ContentsCatalog',
        component: ContentsCatalog,
      },
      {
        path: 'home',
        name: 'Home',
        component: Home
      },
      {
        path: 'contents/:id',
        name: 'ContentDetail',
        component: ContentDetail
      },
      {
        path: 'cart',
        name: 'GuestCart',
        component: Cart,
      },
      {
        path: 'contents/:selectedContent/book-now/:pricePlan',
        name: 'RequestAvailability',
        component: AvailabilityForm
      },
      {
        path: 'about',
        name: 'About',
        component: About
      },
      {
        path: 'who-are-we',
        name: 'WhoAreWe',
        component: WhoAreWe
      },
      {
        path: 'contacts',
        name: 'Contacts',
        component: Contacts
      },
      {
        path: 'booking-checkout', // ?booking=612e493e90edf0528de1eefb&content=610a67ca6db6ce98b1bba078
        name: 'BookingCheckout',
        component: BookingCheckout,
      },
      {
        path: 'account-activation',
        name: 'ProviderInitializaton',
        component: ProviderInitialization,
      },
      {
        path: 'stripe-activation/:info',
        name: 'ProviderStripeConfiguration',
        component: ProviderStripeConfiguration
      }
    ]
  },
  {
    path: '*',
    redirect: 'home'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() { // (to, from, savedPosition)
    return { x: 0, y: 0 }
  }
})

router.beforeEach(authGuard);

export default router
