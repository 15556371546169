<template>
  <div class="settings">
    <CustomTabset :tabs="settingsTabs" initialTab="extra">
      <template slot="tab-pane-extra">
        <ExtraFields />
      </template>

      <template slot="tab-pane-categories">
        <Categories />
      </template>

      <template slot="tab-pane-tags">
        <Tags />
      </template>

      <!-- <template slot="tab-pane-other">
        <div class="other" key="altre">
          <h6 class="page-title mb-1">Altro</h6>
          <span class="page-subtitle">Impostazioni aggiuntive generiche.</span>
        </div>
      </template> -->
    </CustomTabset>
  </div>
</template>

<style lang="scss">
.settings {
  background-color: white;
  border-radius: 0.25rem;
}
</style>

<script>
import ExtraFields from "./admin/platform-settings/ExtraFields.vue";
import Categories from "./admin/platform-settings/Categories.vue";
import Tags from "./admin/platform-settings/Tags.vue";

import CustomTabset from "../components/CustomTabset.vue";

export default {
  name: "Settings",
  components: {
    Tags,
    Categories,
    ExtraFields,
    CustomTabset,
  },
  data: () => ({
    currentTab: 0,
    settingsTabs: [
      { id: "extra", title: "Campi Extra" },
      { id: "categories", title: "Categorie" },
      { id: "tags", title: "Tag" },
      // { id: "other", title: "Altro" },
    ],
  }),
};
</script>