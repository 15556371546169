<template>
  <div class="price-plan-card">
    <div class="d-flex flex-wrap">
      <div class="plan-title">
        {{ getFieldTranslation(plan, "title") }}
      </div>

      <div class="plan-price">
        <PriceLabel :price="plan.price.adultPrice" />
        <!-- <span class="price d-block">{{ plan.price.adultPrice }} €</span> -->
        <span v-if="plan.price.perPerson" class="text-muted">{{
          $t("components.pricePlanCard.perPerson")
        }}</span>
      </div>
    </div>

    <div class="text-right text-muted">
      <span
        v-if="
          plan.price.kidPrice && plan.price.kidPrice != plan.price.adultPrice
        "
        >{{
          $t("components.pricePlanCard.children", {
            price: plan.price.kidPrice,
          })
        }}</span
      >
    </div>

    <div class="plan-description mb-2">
      {{ getFieldTranslation(plan, "description") }}
    </div>

    <div class="plan-options">
      <table class="w-100">
        <tbody>
          <tr v-for="(option, index) of plan.options" :key="index">
            <th scope="col">{{ getFieldTranslation(option, "title") }}</th>
            <td class="text-right">
              <span>+{{ option.adults }}€</span>
              <span
                class="ml-2 text-muted"
                v-if="option.kids || option.kids != option.adults"
                >(+{{
                  $t("components.pricePlanCard.perPerson", {
                    price: option.kids,
                  })
                }})</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <slot></slot>
  </div>
</template>

<style lang="scss">
.price-plan-card {
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  padding: 1rem;

  .plan-title {
    font-size: 1.25rem;
    font-weight: 500;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .plan-description {
    font-family: "Quicksand";
    padding: 1rem 0;
  }
  .plan-price {
    text-align: right;

    .price {
      display: block;
      font-family: "Quicksand";
      font-size: 1.5rem;
    }
  }

  .plan-options {
    max-height: 500px;
    overflow-y: auto;

    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }

    .options-list {
      b {
        font-weight: 500;
        margin-right: 0.5rem;
      }
    }
  }
}
</style>

<script>
import PriceLabel from "./PriceLabel.vue";

export default {
  name: "PricePlanCard",
  components: {
    PriceLabel,
  },
  props: {
    plan: Object,
  },
};
</script>