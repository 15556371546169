<template>
  <div class="authenticated">
    <Navbar :user="user" :admin="true" />

    <div class="authenticated-body">
      <b-nav vertical class="authenticated-navigation" v-if="loggedUserRoutes">
        <template v-for="(route, index) of navigation">
          <b-nav-item
            :to="route.url"
            :key="index"
            active-class="active-link"
            v-if="canNavigateTo(route.url)"
          >
            <font-awesome-icon
              :icon="route.icon"
              fixed-width
            ></font-awesome-icon>
            <span class="ml-3">{{ $t(route.label) }}</span>
          </b-nav-item>
        </template>
      </b-nav>

      <div class="col p-3 page-wrapper" v-if="user">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/variables.scss";

.authenticated {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .authenticated-body {
    flex: 1 0;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
  }

  .authenticated-navigation {
    flex-wrap: nowrap;
    width: 20%;
    padding: 1rem;
    height: 100%;
    background-color: #efefef;
    box-shadow: 1px 0 5px rgba(black, 0.1);

    .nav-link {
      padding: 0.3rem 0.75rem;
      border-radius: 0.25rem;
      color: #555;
      margin-bottom: 0.5rem;

      &:hover {
        color: #333;
        background-color: rgba(black, 0.05);
      }

      &.active-link {
        font-weight: 600;
        color: $geo-info;
        background-color: rgba($geo-info, 0.075);
        box-shadow: 0 0 0 1px $geo-info;
      }
    }
  }

  .page-wrapper {
    overflow-y: auto;
    height: 100%;
  }
}
</style>

<script>
import Navbar from "@/components/Navbar";
import { Account } from "../../api";
import { logout, ROLES_ROUTES } from "../../utils";
import { handleError } from "../../utils";

export default {
  name: "AdminPages",
  components: {
    Navbar,
  },
  data: () => ({
    user: null,
    loggedUserRoutes: null,
    navigation: [
      {
        icon: "list-ul",
        label: "admin.navigation.contentsList",
        url: "/admin/pages/contents",
      },
      {
        icon: "users",
        label: "admin.navigation.usersList",
        url: "/admin/pages/users",
      },
      {
        icon: "clipboard-list",
        label: "admin.navigation.requestsList",
        url: "/admin/pages/requests",
      },
      {
        icon: "cogs",
        label: "admin.navigation.platformManagement",
        url: "/admin/pages/settings",
      },
      {
        icon: "tags",
        label: "admin.navigation.discount",
        url: "/admin/pages/discounts",
      },
      {
        icon: "user",
        label: "admin.navigation.account",
        url: "/admin/pages/account",
      },
    ],
  }),
  created() {
    if (this.$cookies.isKey("atlas_user")) {
      this.user = this.$cookies.get("atlas_user");
      this.loggedUserRoutes = ROLES_ROUTES.get(this.user.role) || [];
    } else {
      Account.getAccountInfo().then(
        ({ data: user }) => {
          this.user = user;
          this.loggedUserRoutes = ROLES_ROUTES.get(this.user.role) || [];
          const date = new Date();
          date.setDate(date.getDate() + 1);
          this.$cookies.set("atlas_user", user, date);
        },
        (err) => {
          console.log(err);
          handleError(
            this,
            err,
            "messages.userInfoErrorMsg",
            "messages.userError"
          );
          this.$router.push({ name: "Login" });
        }
      );
    }
  },
  methods: {
    logout() {
      logout();
    },
    canNavigateTo(url) {
      return this.loggedUserRoutes.includes(url);
    },
  },
};
</script>