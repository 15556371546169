<template>
  <button
    class="raised-button"
    :type="type"
    :class="[size, 'bg-' + variant, { round: round }]"
    v-b-tooltip.hover
    :title="tooltip"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<style lang="scss">
.raised-button {
  border: none;
  box-shadow: 0 0.12rem 0.25rem rgba(black, 0.2);
  transition: box-shadow 0.25s ease;
  font-weight: 500;
  border-radius: 0.25rem;
  color: white;
  padding: 0.25rem 0.75rem;

  &:disabled {
    opacity: 0.65;
  }

  &.round {
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 100px;
  }

  &:not(:disabled):hover {
    box-shadow: 0 0.12rem 0.25rem rgba(black, 0.35);
  }

  &.bg-basic {
    color: black;
    background-color: #f7f7f7;
  }

  &.bg-bar {
    color: rgba(black, 0.5);
    background: #f8f9fa;
    border-radius: 0.25rem;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
  }

  &:focus,
  &:active {
    outline: none;
  }
  &:not(:disabled):active {
    box-shadow: inset 0 0rem 0.5rem 0.1rem rgba(black, 0.2);
  }

  &.sm {
    padding: 0.2rem 0.5rem;
    font-size: 0.85rem;
  }
  &.lg {
    padding: 1rem;
    font-size: 1.2rem;
  }
  &.block-75 {
    width: 75%;
    padding: 0.5rem;
  }
}
</style>

<script>
export default {
  name: "RaisedButton",
  props: {
    tooltip: String,
    type: {
      type: String,
      default: "button",
    },
    size: {
      type: String,
      default: "md",
    },
    variant: {
      type: String,
      default: "basic",
    },
    round: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>