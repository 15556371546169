<template>
  <div class="gallery-editor bg-dark">
    <b-col
      cols="2"
      v-for="(image, index) of uploadedImages"
      :key="index"
      :class="['image-item', 'p-0', 'mx-2', { deleted: image.deleted }]"
      @click="selected = index"
    >
      <img :src="image.url" />

      <div
        class="main-label"
        v-if="!image.deleted"
        @click.stop="setMainImage(index)"
      >
        <b-icon
          :icon="image.mainImage ? 'check-square-fill' : 'square'"
        ></b-icon>
        <span class="ml-2">{{ $t("components.galleryEditor.mainImage") }}</span>
      </div>

      <IconButton
        :class="['image-button', image.deleted ? 'restore' : 'remove']"
        @click.native.stop="toggleImage(index)"
      >
        <font-awesome-icon
          :icon="image.deleted ? 'sync-alt' : 'times'"
        ></font-awesome-icon>
      </IconButton>
    </b-col>

    <VueGallery
      :images="imageUrls"
      :index="selected"
      :options="{ continuous: false }"
      @close="selected = null"
    ></VueGallery>

    <ImageUpload
      :multiple="true"
      @uploaded-images="updateUploadedImages"
      ref="imageUpload"
    >
      <div class="image-item add-image mx-2">
        <b-icon icon="plus"></b-icon>
      </div>
    </ImageUpload>
  </div>
</template>

<style lang="scss">
@import "@/variables.scss";

.gallery-editor {
  display: flex;
  overflow-x: auto;
  padding: 0.75rem 0;
  box-shadow: 0 0 1rem rgba(black, 0.1);
  height: 150px;

  .image-item {
    border: 1px solid #8f8f8f;
    cursor: pointer;
    position: relative;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(.add-image):not(.deleted) {
      img {
        opacity: 0.75;
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;

        &:hover {
          opacity: 1;
        }
      }
    }

    &.deleted {
      img {
        opacity: 0.5;
      }
    }

    .main-label {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      background-color: #00000088;
      color: white;
      font-size: 0.75rem;
      padding: 0.25rem;

      .b-icon {
        font-size: 1rem;
      }
    }

    img {
      width: 100%;
      height: auto;
      max-height: 100%;
    }

    .image-button {
      position: absolute;
      top: -10px;
      right: -10px;
      opacity: 1;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      box-shadow: -0.125rem 0.125rem 0.25rem #00000088;

      &.remove {
        color: white;
        background-color: $geo-danger;
      }
      &.restore {
        background-color: $geo-basic;
      }
    }

    &.add-image {
      font-size: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      height: 100%;
      width: 150px;

      &:hover {
        box-shadow: inset 0 0 0 10rem #ffffff22;
      }
    }
  }
}
</style>

<script>
import ImageUpload from "../components/ImageUpload.vue";
import IconButton from "../components/IconButton.vue";

import VueGallery from "vue-gallery";

export default {
  name: "GalleryEditor",
  props: {
    existingImages: Array,
  },
  data: () => ({
    selected: undefined,
    uploadedImages: null,
  }),
  components: {
    IconButton,
    ImageUpload,
    VueGallery,
  },
  created: function () {
    this.uploadedImages = this.existingImages || [];
  },
  computed: {
    imageUrls() {
      return this.uploadedImages.map((f) => f.url);
    },
  },
  methods: {
    toggleImage(i) {
      if (this.uploadedImages[i].deleted) {
        delete this.uploadedImages[i].deleted;
      } else {
        if (this.uploadedImages[i].id) {
          this.uploadedImages[i].deleted = true;
        } else {
          this.uploadedImages.splice(i, 1);
          this.$refs.imageUpload.propagateDeletionOfFile(this.uploadedImages);
        }
      }

      this.$forceUpdate();
      this.emitUploadedImages();
    },
    setMainImage(i) {
      this.uploadedImages.forEach((img, index) => {
        img.mainImage = index == i;
      });
      this.$forceUpdate();
      this.emitUploadedImages();
    },
    updateUploadedImages(images) {
      const newImages = Array.from(images);
      this.uploadedImages.push(...newImages);
      this.emitUploadedImages();
    },
    emitUploadedImages() {
      this.$emit("uploaded-images", this.uploadedImages);
    },
  },
};
</script>