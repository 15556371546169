<template>
  <validation-observer ref="fieldObserver" v-slot="{ handleSubmit }">
    <b-form
      class="reservation-form"
      @submit.prevent="handleSubmit(submitReservation)"
    >
      <b-row no-gutters>
        <b-col class="pl-0" cols="12" xl="8">
          <validation-provider
            :name="$t('common.name')"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              id="user-name-fieldset"
              :label="$t('common.name')"
              label-cols="4"
              label-for="user-name-field"
            >
              <b-form-input
                id="user-name-field"
                :placeholder="$t('common.name')"
                v-model.trim="reservation.name"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="user-name-field-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            :name="$t('common.email')"
            :rules="{ required: true, email: true }"
            v-slot="validationContext"
          >
            <b-form-group
              id="user-email-fieldset"
              :label="$t('common.email')"
              label-cols="4"
              label-for="user-email-field"
            >
              <b-form-input
                id="user-email-field"
                :placeholder="$t('common.email')"
                type="email"
                v-model.trim="reservation.email"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback id="user-email-field-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            :name="$t('common.telephone')"
            :rules="{ regex: /^[+]?[(]?[0-9]{1,4}[)]?[- \./0-9]*$/ }"
            v-slot="validationContext"
          >
            <b-form-group
              id="user-telephone-fieldset"
              :label="$t('common.telephone')"
              label-cols="4"
              label-for="user-telephone-field"
            >
              <b-form-input
                id="user-telephone-field"
                type="tel"
                :placeholder="$t('common.telephone')"
                :state="getValidationState(validationContext)"
                v-model.trim="reservation.phone"
              ></b-form-input>
              <b-form-invalid-feedback id="user-telephone-field-live-feedback">
                {{ $t("errors.invalidTelephone") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <br />

          <div class="calendar-wrapper">
            <label for="">{{ $t("common.date") }}</label>

            <b-row no-gutters>
              <b-col md="5" class="text-center">
                <AvailabilityCalendar
                  :contentId="contentId"
                  :showTime="typeBook == 'free'"
                  :rangeMode="typeBook == 'daily'"
                  @range-changed="rangeChanged"
                  @show-slots="showSlots"
                  @day-time="dayTime"
                />
                <div class="invalid-feedback d-block" v-if="rangeError">
                  {{
                    $t("components.availabilityForm.selectError", {
                      range:
                        typeBook == "daily"
                          ? $t("common.interval")
                          : $t("common.date"),
                    })
                  }}
                </div>
              </b-col>

              <b-col class="px-2" md="7" v-if="typeBook == 'slots'">
                <CustomAlert v-if="!selectedDate">
                  <font-awesome-icon icon="info-circle"></font-awesome-icon>
                  <span class="ml-2">{{
                    $t("components.availabilityForm.selectDay")
                  }}</span>
                </CustomAlert>

                <template v-else>
                  <h6>
                    {{ $t("components.availabilityForm.availableSlots") }}
                    {{ selectedDate | formatDate(true) }}
                  </h6>

                  <div class="slots-list" v-if="slots">
                    <BookingSlotItem
                      class="action"
                      v-for="(slot, index) of slots"
                      :key="index"
                      :slotInfo="slot"
                      @click.native="reservation.slotid = slot.slotId"
                      v-model="reservation.slotid"
                    />
                  </div>
                </template>
              </b-col>
            </b-row>
          </div>

          <br />

          <b-row>
            <b-col>
              <validation-provider
                :name="$t('common.adults')"
                :rules="{
                  required: true,
                  numeric: true,
                }"
                v-slot="validationContext"
              >
                <b-form-group
                  id="reservation-adults-fieldset"
                  class="mb-0"
                  :label="$t('common.adults')"
                  label-cols="8"
                  label-for="reservation-adults-field"
                >
                  <b-form-input
                    id="reservation-adults-field"
                    type="number"
                    min="0"
                    :placeholder="$t('common.adults')"
                    v-model.number="reservation.adults"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="reservation-adults-field-live-feedback"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col class="pl-0">
              <b-form-group
                id="reservation-kids-fieldset"
                class="mb-0"
                :label="$t('common.children')"
                label-cols="8"
                label-for="reservation-kids-field"
              >
                <b-form-input
                  id="reservation-kids-field"
                  type="number"
                  min="0"
                  :placeholder="$t('common.children')"
                  v-model.number="reservation.children"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" v-if="participantsError">
              <div class="invalid-feedback d-block">
                {{
                  $t(
                    "components.availabilityForm.peopleSumError",
                    { minPeople: pricePlan.minPeople },
                    { maxPeople: pricePlan.maxPeople }
                  )
                }}
              </div>
            </b-col>
          </b-row>

          <br />

          <b-form-group
            id="reservation-notes-fieldset"
            class="mb-0"
            label="Note"
            label-for="reservation-notes-field"
          >
            <b-form-textarea
              id="reservation-notes-field"
              rows="4"
              placeholder="Note aggiuntive"
              v-model="reservation.note"
            ></b-form-textarea>
          </b-form-group>

          <br />

          <CustomAlert type="warning" class="mb-2" v-if="pricePlan.minPeople">
            <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
            <span class="ml-2"
              >{{$t("components.availabilityForm.minPeopleError",{minPeople:pricePlan.minPeople})}}</span
            >
          </CustomAlert>

          <CustomAlert type="warning" v-if="pricePlan.maxPeople">
            <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
            <span class="ml-2"
              >
              {{$t("components.availabilityForm.maxPeopleError",{maxPeople:pricePlan.maxPeople})}}</span
            >
          </CustomAlert>
        </b-col>

        <b-col class="pl-3" cols="12" xl="4">
          <PricePlanCard class="mb-3 bg-light" :plan="pricePlan" />

          <h4>{{$t("components.availabilityForm.summary")}}</h4>
          <table class="mb-2">
            <thead>
              <th width="5%"></th>
              <th></th>
              <th width="30%"></th>
            </thead>
            <tbody>
              <template v-if="pricePlan.perPerson">
                <tr>
                  <td>x{{ reservation.adults }}</td>
                  <td>{{$t("common.adults")}}</td>
                  <th scope="col" class="text-right">{{ adultsPartial }} €</th>
                </tr>
                <tr>
                  <td>x{{ reservation.children }}</td>
                  <td>{{$t("common.children")}}</td>
                  <th scope="col" class="text-right">{{ kidsPartial }} €</th>
                </tr>
              </template>
              <tr v-for="(option, index) of reservationOptions" :key="index">
                <td>
                  <b-form-checkbox v-model="option.selected"></b-form-checkbox>
                </td>
                <td>{{ option.text }}</td>
                <th scope="col" class="text-right">
                  <span v-if="option.selected">
                    {{
                      option.perPerson
                        ? reservation.adults * option.adults +
                          reservation.children * option.kids
                        : option.adults
                    }}
                    €
                  </span>
                </th>
              </tr>
            </tbody>
          </table>
          <div class="reservation-total">{{ reservationTotal }} €</div>
        </b-col>
      </b-row>

      <b-row class="pt-3" no-gutters align-h="end">
        <div class="invalid-feedback d-block" v-if="captchaError">
         {{$t("errors.captchaError")}}
        </div>
        <vue-recaptcha
          ref="recaptcha"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired"
          :sitekey="siteKey"
          :loadRecaptchaScript="true"
        ></vue-recaptcha>
      </b-row>

      <CustomAlert class="mt-3" type="danger" v-if="availabilityError">
        <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
        <span class="ml-2"
          >{{$t("components.availabilityForm.availabilityError")}}</span
        >
      </CustomAlert>

      <div class="d-flex justify-content-between mt-4">
        <b-button
          variant="light"
          type="button"
          class="border"
          @click="$emit('cancel')"
          >{{$t("actions.cancel")}}</b-button
        >
        <b-button variant="success" type="submit">{{$t("actions.sendRequest")}}</b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<style lang="scss">
.reservation-form {
  .price {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Quicksand";
  }

  .selected-plan {
    border: 1px solid #e5e5e5;
    padding: 1rem;
    background-color: #f8f8f8;

    .plan-detail {
      display: flex;
      justify-content: space-between;

      b {
        font-weight: 500;
      }
    }
  }
  .reservation-total {
    font-family: "Quicksand";
    font-size: 3.5rem;
    padding: 1rem 2rem;
    text-align: right;
    border-top: 2px solid #ddd;
  }
  .slots-list {
    border-radius: 0.25rem;
    border: 1px solid #ddd;
    max-height: 500px;
    overflow-y: auto;
  }
}
</style>

<script>
import { RECAPTCHA_SITE_KEY } from "../globals";
import moment from "moment";
import PricePlanCard from "./PricePlanCard.vue";
import CustomAlert from "./CustomAlert.vue";
import AvailabilityCalendar from "./AvailabilityCalendar.vue";
import BookingSlotItem from "./BookingSlotItem.vue";

import VueRecaptcha from "vue-recaptcha";

export default {
  name: "AvailabilityForm",
  components: {
    VueRecaptcha,
    BookingSlotItem,
    AvailabilityCalendar,
    CustomAlert,
    PricePlanCard,
  },
  props: {
    pricePlan: Object,
    typeBook: String, // slots, daily, free
    contentId: String,
  },
  data() {
    return {
      reservation: {
        planid: this.pricePlan.id,
        children: 0,
        adults: 0,
        from: null,
        to: null,
        slotid: null,
      },
      currentAvailability: null,
      selectedRange: null,
      selectedDate: null,
      availabilityError: false,
      rangeError: false,
      participantsError: false,
      captchaError: false,
      siteKey: RECAPTCHA_SITE_KEY,
      reservationOptions: this.pricePlan.options.map((option) => ({
        text: option.title[this.locale],
        selected: false,
        id: option.id,
        adults: option.adults,
        kids: option.kids,
        perPerson: option.perPerson,
      })),
      slots: null,
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.reservation.captcha = recaptchaToken;
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    submitReservation() {
      const participants = this.reservation.adults + this.reservation.children;
      if (
        participants < this.pricePlan.minPeople ||
        participants > this.pricePlan.maxPeople
      ) {
        this.participantsError = true;
        return;
      }
      this.participantsError = false;

      if (!this.reservation.captcha) {
        this.captchaError = true;
        return;
      }
      this.captchaError = false;

      if (this.typeBook == "daily") {
        if (!this.selectedRange) {
          this.rangeError = true;
          return;
        }
        this.rangeError = false;

        this.reservation.from =
          moment(this.selectedRange.start)
            .hour(0)
            .minute(0)
            .second(0)
            .format("YYYY-MM-DDTHH:mm:ss") + "Z";
        this.reservation.to =
          moment(this.selectedRange.end)
            .hour(0)
            .minute(0)
            .second(0)
            .format("YYYY-MM-DDTHH:mm:ss") + "Z";
      }

      this.reservation.sum = this.reservationTotal;
      this.reservation.selectedOptions = this.reservationOptions.filter(
        (option) => option.selected
      );

      if (this.validAvailability(participants)) {
        this.$emit("submitReservation", this.reservation);
      }
    },
    validAvailability(participants) {
      if (
        this.typeBook == "free" &&
        participants > this.currentAvailability.summary.total
      ) {
        this.availabilityError = true;
        return false;
      }

      if (this.typeBook == "slots") {
        const selectedSlot = this.slots.find(
          (slot) => slot.slotId == this.reservation.slotid
        );

        if (participants > selectedSlot.amount) {
          this.availabilityError = true;
          return false;
        }
      }

      if (this.typeBook == "daily") {
        const invalid = this.currentAvailability.some(
          (availability) => participants > availability.slots[0].amount
        );

        if (invalid) {
          this.availabilityError = true;
          return false;
        }
      }
      return true;
    },
    showSlots(date, availability) {
      this.selectedDate = date;
      this.slots = availability.slots;
      this.reservation.slotid = this.slots[0].slotId;
      this.reservation.from = date;
      this.reservation.to = date;
    },
    dayTime(date, availability) {
      this.reservation.from = date;
      this.reservation.to = date;
      this.reservation.slotid = availability.slots[0].slotId;
      this.reservation.time = date;
      this.currentAvailability = availability;
    },
    rangeChanged(range, availability) {
      this.selectedRange = range;
      this.reservation.slotid = availability[0].slots[0].slotId;
      this.currentAvailability = availability;
    },
  },
  computed: {
    adultsPartial() {
      return this.pricePlan.perPerson
        ? this.reservation.adults * this.pricePlan.baseAdults
        : this.pricePlan.baseAdults;
    },
    kidsPartial() {
      return this.reservation.children * this.pricePlan.baseKids;
    },
    reservationTotal() {
      const optionsPartial = this.reservationOptions
        .filter((option) => option.selected)
        .reduce((accumulator, current) => {
          if (!current.perPerson) return accumulator + current.adults;
          else {
            const optionKidsTotal = current.kids * this.reservation.children;
            const optionAdultsTotal = current.adults * this.reservation.adults;

            return (accumulator = optionKidsTotal + optionAdultsTotal);
          }
        }, 0);

      return this.kidsPartial + this.adultsPartial + optionsPartial;
    },
  },
};
</script>