import { EventBus } from '../eventBus';
import FullScreenModal from '../components/FullScreenModal.vue';

export default {
    install(Vue) {
        Vue.component(FullScreenModal.name, FullScreenModal);

        Vue.prototype.$fsModal = {
            show: function (name) {
                EventBus.$emit('show-fs-modal', name);
                document.body.classList.add('modal-open');
            },
            hide: function (name) {
                EventBus.$emit('hide-fs-modal', name);
                document.body.classList.remove('modal-open');
            }
        }
    }
}