import { Location } from "./Location";
import { NewPackage } from "./NewPackage";
import { Translation } from "./Translation";
export class NewContent {

    constructor({
        id,
        title, // required
        description, // required
        abstract,
        category, // required
        tags,
        location,
        address,
        extra,
        price,
        contacts,
        duration,
        typeBook,
        externalLinks,

        //New Model
        showcasePrice,
        packages,
        visibility,
        status,
        bookingType,
        updatedFields,
    }) {
        this.id = id || undefined
        this.title = title || new Translation();
        this.description = description || new Translation();
        this.abstract = abstract || new Translation();
        this.category = category || null;
        this.tags = tags || [];
        this.location = location || new Location();
        this.address = address || {};
        this.extra = extra || [];
        this.price = price || {
            showcase: 0.00,
            perPerson: true,
            currency: 'euro',
            plans: [
                // new PricePlan({ it_IT: 'Piano base' }),
            ],
        };
        this.contacts = contacts || {};
        this.duration = duration || null;
        this.typeBook = typeBook || null;
        this.externalLinks = externalLinks || [];

        //New Model
        this.showcasePrice = showcasePrice || null;
        this.packages = packages || [new NewPackage()];
        this.visibility = visibility || [];
        this.status = status || null;
        this.bookingType = bookingType || null;
        this.updatedFields = updatedFields || null;
    }
}