export class User {

  constructor() {
    this.username = '';
    this.password = '';
    this.firstName = '';
    this.lastName = '';
    this.captcha = '';
    this.category = 'user'; // user/business
    this.contacts = {
      email: '',
      telephone: '',
    };
    this.companyName = null;
    this.companyAddress = null;
    this.vatNumber = null;
    this.platformFee = 0;
  }

}