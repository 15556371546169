<template>
  <div class="icons-table">
    <CustomSearch
      class="mb-3"
      v-model="searchText"
      placeholder="Search icon..."
    />

    <b-pagination
      v-model="currentPage"
      align="center"
      :total-rows="filteredIcons.length"
      :per-page="perPage"
    >
    </b-pagination>

    <div class="d-flex flex-wrap">
      <b-col
        cols="3"
        md="2"
        class="px-2 mb-2"
        v-for="icon in pageIcons"
        :key="icon.title"
      >
        <div
          class="icon-item"
          :class="{ selected: selected == icon.title }"
          @click="$emit('selected-icon', icon.title)"
        >
          <div class="mb-3">
            <font-awesome-icon
              :icon="icon.definition"
              size="lg"
            ></font-awesome-icon>
          </div>
          <div class="icon-title">{{ icon.title }}</div>
        </div>
      </b-col>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/variables.scss";

.icons-table {
  .icon-item {
    border: 1px solid #ddd;
    cursor: pointer;
    color: #5f5f5f;
    text-align: center;
    padding: 1rem;
    height: 100%;

    &.selected {
      background-color: $primary;
      color: white;
    }

    &:not(.selected):hover {
      background-color: #f3f3f3;
    }

    .icon-title {
      font-size: 0.75rem;
    }
  }
}
</style>

<script>
import CustomSearch from "./CustomSearch.vue";
import { fas } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "IconsTable",
  components: {
    CustomSearch,
  },
  props: {
    selected: String,
  },
  created: function() {
    this.icons = Object.values(fas).map((definition) => ({
      title: definition.iconName,
      definition,
    }));
  },
  data: () => ({
    icons: [],
    searchText: "",
    currentPage: 1,
    perPage: 60,
  }),
  computed: {
    filteredIcons() {
      return this.icons.filter((icon) => icon.title.includes(this.searchText));
    },
    pageIcons() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredIcons.slice(start, end);
    },
  },
};
</script>