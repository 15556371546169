<template>
  <div class="verify-availability m-3">
    <b-row class="mb-2">
      <b-col>
        <b-form-group
          id="reservation-adults-fieldset"
          class="mb-0"
          :label="$t('common.adults')"
          label-cols="8"
          label-for="reservation-adults-field"
        >
          <b-form-input
            id="reservation-adults-field"
            type="number"
            min="0"
            :placeholder="$t('common.adults')"
            :value="reservation.adultAmount"
            @input="updateAdultAmount"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col class="pl-0">
        <b-form-group
          id="reservation-kids-fieldset"
          class="mb-0"
          :label="$t('common.children')"
          label-cols="8"
          label-for="reservation-kids-field"
        >
          <b-form-input
            id="reservation-kids-field"
            type="number"
            min="0"
            :placeholder="$t('common.children')"
            :value="reservation.kidAmount"
            @input="updateKidAmount"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <template v-if="validationError">
        <b-col cols="12" class="mt-3" v-if="minParticipantsError">
          <CustomAlert type="danger">
            <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
            <span class="ml-2">{{
              $t("components.bookingWizard.minParticipants")
            }}</span>
          </CustomAlert>
        </b-col>
        <b-col cols="12" class="mt-3" v-if="participantsError && date">
          <CustomAlert type="danger">
            <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
            {{
              $t("components.availabilityForm.peopleSumError", {
                minPeople: pack.minPeople,
                maxPeople: selectedSlot
                  ? selectedSlot.amount
                  : realAvailability,
              })
            }}
          </CustomAlert>
        </b-col>
        <b-col cols="12" class="mt-3" v-if="date == null">
          <CustomAlert type="danger">
            <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
            <span class="ml-2">{{
              $t("components.availabilityForm.noDate")
            }}</span>
          </CustomAlert>
        </b-col>
      </template>
    </b-row>
    <hr />
    <b-row no-gutters align-h="start">
      <b-col md="6">
        <vue-date-picker
          ref="datepicker"
          :locale="locale"
          :value="date"
          :columns="2"
          :step="1"
          :min-page="minDate"
          :disabled-dates="{}"
          :available-dates="availableDates"
          :isRange="true"
          @update:from-page="monthChanged"
          @input="dayClick"
        />
      </b-col>
      <b-col md="6" v-if="timeSlots.length > 0">
        <div>
          <h6 v-if="formatDate(date.start) == formatDate(date.end)">
            {{
              $t("components.availabilityForm.availableSlots", {
                day: formatDate(date.start),
              })
            }}
          </h6>
          <h6 v-else>
            {{
              $t("components.availabilityForm.availableSlotsInRange", {
                from: formatDate(date.start),
                to: formatDate(date.end),
              })
            }}
          </h6>
        </div>
        <CustomRadios :options="timeSlots" v-model="selectedSlotId" />
      </b-col>
    </b-row>
    <br />

    <b-row no-gutters>
      <b-col md="6" class="pr-3">
        <CustomAlert type="info">
          <font-awesome-icon icon="info-circle"></font-awesome-icon>
          <span class="ml-2">{{
            $t("components.availabilityForm.datepicker")
          }}</span>
        </CustomAlert>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>
/deep/ .custom-radios .radio-option {
  display: flex;

  .radio-icon {
    margin: 0 0.25rem;
  }
}
</style>

<script>
import { Availability } from "../../api";
import CustomAlert from "../CustomAlert.vue";
import CustomRadios from "../CustomRadios.vue";

import {
  endOfMonth,
  startOfMonth,
  format,
  add,
  isEqual,
  //isAfter,
  isBefore,
  setMonth,
  isSameMonth,
  isSameYear,
  //parseISO,
} from "date-fns";

export default {
  name: "VerifyAvailability",
  components: {
    CustomAlert,
    CustomRadios,
  },
  props: {
    pack: Object,
  },
  data() {
    return {
      noSlotPackage: false,
      realAvailability: null,
      validationError: false,
      date: null,
      today: null,
      nextMonth: null,
      minDate: {},
      currentAvailability: [],
      availableDates: [],
      timeSlots: [],
      availability: [],
      selectedSlotId: null,
      initialized: false,
      reservation: {
        kidAmount: 0,
        adultAmount: 0,
        start: null,
        end: null,
        slotId: null,
        slot: { from: null, to: null },
      },
      locale: this.$i18n.locale,
    };
  },
  created: function () {
    this.today = new Date();

    this.nextMonth = endOfMonth(
      add(this.today, {
        months: 1,
      })
    );
    this.minDate = {
      day: this.today.getDate(),
      month: this.today.getMonth() + 2,
      year: this.today.getFullYear(),
    };

    this.calculateAvailableDates();
  },
  mounted: function () {
    //Necessario poichè:
    //disabilitando la navigazione verso il mese precedente a quello in corso
    //la tab di sinistra presenterà il mese successivo
    //ES: Mese Attuale Aprile, le tab mostrerebbero Maggio - Giugno
    const datepicker = this.$refs.datepicker;
    datepicker.move(-1, { force: true });
  },
  computed: {
    participantsError() {
      if (!this.noSlotPackage && this.selectedSlotId) {
        if (this.selectedSlot.amount == 0) {
          return false;
        }
        return (
          this.reservation.adultAmount + this.reservation.kidAmount >
          this.selectedSlot.amount
        );
      } else {
        if (this.realAvailability == 0) {
          return false;
        }
        return (
          this.reservation.adultAmount + this.reservation.kidAmount >
          this.realAvailability
        );
      }
    },
    minParticipantsError() {
      return this.reservation.adultAmount + this.reservation.kidAmount == 0;
    },
    selectedSlot() {
      return this.selectedSlotId
        ? this.timeSlots.find((slot) => this.selectedSlotId == slot.value)
        : null;
    },
  },
  methods: {
    updateKidAmount(evt) {
      this.reservation.kidAmount = parseInt(evt);
      this.adaptAvailabilityToPeople();
    },
    updateAdultAmount(evt) {
      this.reservation.adultAmount = parseInt(evt);
      this.adaptAvailabilityToPeople();
    },
    adaptAvailabilityToPeople() {
      let newAvailability = this.currentAvailability.filter((availability) => {
        let maxAmount = 0;
        if (!this.noSlotPackage) {
          availability.slots.forEach((slot) => {
            if (slot.amount == 0) {
              slot.amount = Number.POSITIVE_INFINITY;
            }
            if (slot.amount > maxAmount) {
              maxAmount = slot.amount;
            }
          });
        } else {
          if (availability.amount == 0) {
            maxAmount = Number.POSITIVE_INFINITY;
          } else {
            maxAmount = availability.amount;
          }
        }
        return (
          this.reservation.adultAmount + this.reservation.kidAmount <= maxAmount
        );
      });

      this.availableDates =
        this.fromSingleDateListToRangeDateList(newAvailability);
    },
    calculateAvailableDates(start, end) {
      Availability.getAvailability(
        this.pack.id,
        start || format(this.today, "yyyy-MM-dd"),
        end || format(this.nextMonth, "yyyy-MM-dd")
      ).then((res) => {
        this.currentAvailability = res.data;

        this.initialized = true;

        if (
          this.currentAvailability[0] &&
          !this.currentAvailability[0].slots.length
        ) {
          this.noSlotPackage = true;
        }

        this.adaptAvailabilityToPeople();

        this.availability = res.data.reduce((accumulator, date) => {
          let key = date["day"];
          accumulator[key] = this.noSlotPackage ? date.amount : date["slots"];
          return accumulator;
        }, {});

      });
    },
    fromSingleDateListToRangeDateList(currentAvailability) {
      let dates = currentAvailability.map((availability) => ({
        date: new Date(availability.day),
        string: availability.day,
      }));

      let watchedDate = dates[0];
      let result = [];
      dates.forEach((dateItem, i) => {
        const { date, string } = dateItem;
        // If last date, add range
        if (i == dates.length - 1) {
          result.push({ start: watchedDate.string, end: string });
        } else {
          const nextDate = dates[i + 1];
          let diff = nextDate.date.getTime() - date.getTime();
          if (diff > 24 * 60 * 60 * 1000) {
            result.push({ start: watchedDate.string, end: string });
            watchedDate = nextDate;
          }
        }
      });
      return result;
    },
    formatDate(date) {
      return format(date, "dd-MM-yyyy");
    },
    dayClick(evt) {
      this.date = evt;
      let start = evt.start;
      let end = evt.end;
      this.evaluateAvailableAmountInRange(this.getDatesBetween(start, end));
      this.reservation.start = format(start, "yyyy-MM-dd");
      this.reservation.end = format(end, "yyyy-MM-dd");
    },
    getDatesBetween(start, end) {
      let current = start;
      let allDates = [];

      while (isEqual(current, end) || isBefore(current, end)) {
        allDates.push(format(current, "yyyy-MM-dd"));
        current = add(current, { days: 1 });
      }

      return allDates;
    },
    evaluateAvailableAmountInRange(range) {
      const slots = range.map((date) => this.availability[date]);

      let realAvailability = slots[0];
      slots.forEach((slot, indx) => {
        if (!this.noSlotPackage) {
          slot.forEach((values, index) => {
            if (values.amount < realAvailability[index].amount) {
              realAvailability[index] = values;
            }
          });
        } else {
          if (slot < realAvailability[indx]) {
            realAvailability[indx] = slot;
          }
        }
      });

      this.realAvailability = realAvailability;

      if (!this.noSlotPackage) {
        this.timeSlots = realAvailability.map((slot) => ({
          value: slot.slotId,
          label: `${slot.from} - ${slot.to}`,
          trailing: this.$t("components.bookingSlotItem.availableSpots", {
            amount: slot.amount != "Infinity" ? slot.amount : "∞",
          }),
          from: slot.from,
          to: slot.to,
          amount: slot.amount,
        }));
      }

    },
    monthChanged(evt) {
      if (this.initialized) {
        let newDate = new Date();

        let newMonth;
        if (newDate.getFullYear() === evt.year) {
          newMonth = startOfMonth(setMonth(newDate, evt.month - 1));
        } else {
          newDate.setFullYear(evt.year);
          newMonth = startOfMonth(setMonth(newDate, evt.month - 1));
        }
        let endOfNewMonth = endOfMonth(add(newMonth, { months: 1 }));

        if (
          isSameMonth(newMonth, this.today) &&
          isSameYear(newMonth, this.today)
        ) {
          newMonth = this.today;
        }
        this.calculateAvailableDates(
          format(newMonth, "yyyy-MM-dd"),
          format(endOfNewMonth, "yyyy-MM-dd")
        );
      }
    },
    validate() {
      if (!this.noSlotPackage) {
        this.reservation.slotId = this.selectedSlotId;
        this.reservation.slot.from = this.selectedSlot.from;
        this.reservation.slot.to = this.selectedSlot.to;
      }

      let valid =
        !this.participantsError &&
        !this.minParticipantsError &&
        this.reservation.start != null &&
        this.reservation.end != null;

      if (!this.noSlotPackage) {
        valid =
          valid &&
          this.reservation.slotId != null &&
          this.reservation.slot.from != null &&
          this.reservation.slot.to != null;
      }

      this.$emit("on-validate", valid, this.reservation);
      valid ? (this.validationError = false) : (this.validationError = true);
      return valid;
    },
  },
};
</script>
