<template>
  <SlickList class="extra-field-editor" helperClass="dragging" axis="xy" v-model="gridItems" tag="div">
    <SlickItem v-for="(field, i) of fields" :key="field.id" :index="i" class="col-12 col-lg-6 mb-3" tag="div">
      <div class="field-frame">
        <b-form-group id="field-value-fieldset" label-cols="3" label-for="field-value" class="mb-0">
          <template slot="label">
            <font-awesome-icon v-if="field.icon" :icon="field.icon || 'info'"></font-awesome-icon>
            <span class="ml-2">{{ getFieldTranslation(field, "title") }}</span>
          </template>

          <template v-if="field.type == 'boolean'">
            <b-form-checkbox class="bool-extra-field-input" id="field-value" v-if="field.type == 'boolean'"
              v-model="field.boolValue">
              <span>{{ field.boolValue ? "TRUE" : "FALSE" }}</span>
            </b-form-checkbox>
          </template>

          <template v-else>
            <b-form-input id="field-value" :type="field.type == 'string' ? 'text' : 'number'"
              v-model="field.stringValue[index].text" placeholder="Valore campo">
            </b-form-input>
          </template>
        </b-form-group>

        <button type="button" class="remove-button" @mousedown="$emit('remove', i)">
          <font-awesome-icon icon="times"></font-awesome-icon>
        </button>
      </div>
    </SlickItem>

    <b-col cols="12" lg="6" class="p-2" v-if="availableFields.length">
      <b-dropdown no-caret variant="light">
        <template slot="button-content">
          <font-awesome-icon icon="plus"></font-awesome-icon>
        </template>
        <b-dropdown-item v-for="(available, index) of availableFields" :key="index"
          @click="addExtraField(available.id)">
          <font-awesome-icon :icon="available.icon" fixed-width></font-awesome-icon>
          <span class="ml-3">{{
            getFieldTranslation(available, "title")
          }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
  </SlickList>
</template>

<style lang="scss">
@import "@/variables.scss";

.dragging .field-frame {
  box-shadow: 0 0 0 2px $geo-info, 0 0 0.5rem rgba($geo-info, 0.75);
}

.field-frame {
  border: 1px solid #ddd;
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  position: relative;
  background-color: white;

  .remove-button {
    position: absolute;
    top: -10px;
    right: -10px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: $geo-danger;
    color: white;
    border: none;
  }
}

.extra-field-editor {
  display: flex;
  flex-wrap: wrap;

  .dropdown-toggle {
    border: 1px solid #ddd;
    height: 60px;
    width: 60px;
  }

  .bool-extra-field-input input {
    z-index: 1;
  }
}
</style>

<script>
import _ from "lodash";

import { SlickList, SlickItem } from "vue-slicksort";

export default {
  name: "ExtraFieldsEditor",
  props: {
    fields: Array,
    availableFields: Array,
    currentLanguage: String,
  },
  data: function () {
    return {
      index: this.currentLanguage == "it_IT" ? 0 : 1,
    };
  },
  components: {
    SlickList,
    SlickItem,
  },
  computed: {
    gridItems: {
      get() {
        return this.fields;
      },
      set(value) {
        this.$emit("sorted", value);
      },
    },
  },
  methods: {
    addExtraField(id) {
      const existingField = this.availableFields.find(
        (field) => field.id == id
      );
      const newField = _.cloneDeep(existingField);
      this.$emit("new-field", newField);
    },
  },
};
</script>