<template>
  <b-alert class="geo-alert" :variant="type" show>
    <slot></slot>
  </b-alert>
</template>

<style lang="scss">
.alert.geo-alert {
  border-radius: 0.5rem;
  font-size: 0.85rem;
  padding: 0.5rem 1.5rem;
  text-align: center;
  font-family: 'Quicksand';
  font-weight: 600;
  margin-bottom: 0;
}
</style>

<script>
export default {
  name: "CustomAlert",
  props: {
    type: {
      type: String,
      default: 'info',
    },
  },
};
</script>