var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"fieldObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.emitField)}}},[_c('validation-provider',{attrs:{"name":"Name","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"id":"field-name-it-fieldset","label":((_vm.$t('common.name')) + " (" + (_vm.$t('language.it.short')) + ")"),"label-cols":"4","label-for":"field-name-it"}},[_c('b-form-input',{attrs:{"id":"field-name-it","placeholder":((_vm.$t(
            'admin.platformManagement.extraFields.edit.placeholders.name'
          )) + " (" + (_vm.$t('language.it.full')) + ")"),"state":_vm.getValidationState(validationContext)},model:{value:(_vm.newField.title[0].text),callback:function ($$v) {_vm.$set(_vm.newField.title[0], "text", $$v)},expression:"newField.title[0].text"}}),_c('b-form-invalid-feedback',{attrs:{"id":"field-name-it-live-feedback"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"id":"field-name-en-fieldset","label":((_vm.$t('common.name')) + " (" + (_vm.$t('language.en.short')) + ")"),"label-cols":"4","label-for":"field-name-en"}},[_c('b-form-input',{attrs:{"id":"field-name-en","placeholder":((_vm.$t(
          'admin.platformManagement.extraFields.edit.placeholders.name'
        )) + " (" + (_vm.$t('language.en.full')) + ")")},model:{value:(_vm.newField.title[1].text),callback:function ($$v) {_vm.$set(_vm.newField.title[1], "text", $$v)},expression:"newField.title[1].text"}})],1),_c('validation-provider',{attrs:{"name":"Tipo","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"id":"field-type-fieldset","label":_vm.$t('admin.platformManagement.extraFields.edit.type'),"label-cols":"4","label-for":"field-type"}},[_c('b-form-select',{attrs:{"id":"field-type","placeholder":"Tipo","state":_vm.getValidationState(validationContext)},model:{value:(_vm.newField.type),callback:function ($$v) {_vm.$set(_vm.newField, "type", $$v)},expression:"newField.type"}},[_c('b-form-select-option',{attrs:{"value":"number"}},[_vm._v(_vm._s(_vm.$t("admin.platformManagement.extraFields.edit.typeNumber")))]),_c('b-form-select-option',{attrs:{"value":"string"}},[_vm._v(" "+_vm._s(_vm.$t("admin.platformManagement.extraFields.edit.typeString"))+" ")]),_c('b-form-select-option',{attrs:{"value":"boolean"}},[_vm._v(" "+_vm._s(_vm.$t("admin.platformManagement.extraFields.edit.typeBoolean"))+" ")])],1),_c('b-form-invalid-feedback',{attrs:{"id":"field-type-live-feedback"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"id":"field-value-fieldset","label":_vm.$t('admin.platformManagement.extraFields.edit.value'),"label-cols":"4","label-for":"field-value"}},[(_vm.newField.type == 'string')?_c('b-form-input',{attrs:{"id":"field-value","placeholder":((_vm.$t(
          'admin.platformManagement.extraFields.edit.value'
        )) + " (" + (_vm.$t('language.it.full')) + ")")},model:{value:(_vm.newField.stringValue[0].text),callback:function ($$v) {_vm.$set(_vm.newField.stringValue[0], "text", $$v)},expression:"newField.stringValue[0].text"}}):_vm._e(),(_vm.newField.type == 'number')?_c('b-form-input',{attrs:{"id":"field-value","type":"number","placeholder":_vm.$t('admin.platformManagement.extraFields.edit.defaultValue')},model:{value:(_vm.newField.stringValue[0].text),callback:function ($$v) {_vm.$set(_vm.newField.stringValue[0], "text", $$v)},expression:"newField.stringValue[0].text"}}):_vm._e(),(_vm.newField.type == 'boolean')?_c('b-form-checkbox',{attrs:{"id":"field-value"},model:{value:(_vm.newField.boolValue),callback:function ($$v) {_vm.$set(_vm.newField, "boolValue", $$v)},expression:"newField.boolValue"}},[_c('span',[_vm._v(_vm._s(_vm.newField.boolValue ? "TRUE" : "FALSE"))])]):_vm._e()],1),(_vm.newField.type == 'string')?[_c('b-form-group',{attrs:{"id":"field-value-en-fieldset","label":((_vm.$t(
          'admin.platformManagement.extraFields.edit.value'
        )) + " (" + (_vm.$t('language.en.full')) + ")"),"label-cols":"4","label-for":"field-value-en"}},[_c('b-form-input',{attrs:{"id":"field-value-en","placeholder":((_vm.$t(
            'admin.platformManagement.extraFields.edit.value'
          )) + " (" + (_vm.$t('language.en.full')) + ")")},model:{value:(_vm.newField.stringValue[1].text),callback:function ($$v) {_vm.$set(_vm.newField.stringValue[1], "text", $$v)},expression:"newField.stringValue[1].text"}})],1)]:_vm._e(),_c('h6',[_vm._v(_vm._s(_vm.$t("admin.platformManagement.extraFields.edit.icon")))]),_c('IconsTable',{attrs:{"selected":_vm.newField.icon},on:{"selected-icon":function($event){_vm.newField.icon = $event}}}),_c('br'),_c('b-row',{attrs:{"align-h":"end","no-gutters":""}},[_c('FlatButton',{nativeOn:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(_vm._s(_vm.$t("actions.cancel")))]),_c('RaisedButton',{staticClass:"ml-3",attrs:{"type":"submit","variant":"success"}},[_vm._v(_vm._s(_vm.$t("actions.save")))])],1)],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }