<template>
  <div style="height: 84vh;" class="contacts">
    <div class="overflow-hidden ima" style="height: 110%;">
      <div id="cont" class="d-flex flex-column align-items-center justify-content-center">
        <div id="cont2" class="d-flex flex-column ">
          <h1 class="red">{{ $t("pages.contacts.head") }}</h1>
          <span style="font-weight: bold;" class="orange ">{{ $t("pages.contacts.company") }}</span>
          <span style="font-weight: bold;" class="text-white ">{{ $t("pages.contacts.companyName") }}</span>
          <span style="font-weight: bold;" class="orange ">{{ $t("pages.contacts.registeredOffice") }}</span>
          <span style="font-weight: bold;" class="text-white ">{{ $t("pages.contacts.registeredOfficeName") }}</span>
          <span style="font-weight: bold;" class="orange ">{{ $t("pages.contacts.headquarters") }}</span>
          <span style="font-weight: bold;" class="text-white "> {{ $t("pages.contacts.headquartersStreet") }}</span>
          <span style="font-weight: bold;" class="orange "> {{ $t("pages.contacts.website") }}</span>
          <span style="font-weight: bold;" class="text-white "> {{ $t("pages.contacts.websiteName") }}</span>
          <span style="font-weight: bold;" class="orange "> {{ $t("pages.contacts.number") }}</span>
          <span style="font-weight: bold;" class="text-white ">{{ $t("pages.contacts.contactNumber") }}</span>
          
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.ima {
  width: 100%;
  background-image: url(../assets/v2.jpg);
  background-position: center;
  background-size: cover;
  background-position: top;
}

#cont {
  width: 20%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  gap: 10px;
}

#cont2 {
  gap: 1rem;
}

.red {
  color: #de3b4a;
  font-weight: bold;
}

.orange {
  color: #d49b13;
  font-weight: bold;
}
</style>

<script>
export default {
  name: "Contacts",
};
</script>