<template>
  <button :type="type" class="icon-button">
    <slot></slot>
  </button>
</template>

<style lang="scss">
.icon-button {
  background: transparent;
  border: none;
  opacity: 0.75;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 1;
  }
}
</style>

<script>
export default {
  name: "IconButton",
  props: {
    type: {
      type: String,
      default: "button",
    },
  },
};
</script>