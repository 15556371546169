<template>
  <div class="range-list">
    <b-row
      class="range-item"
      v-for="(range, index) of rangeList"
      :key="index"
      no-gutters
    >
      <div class="leading">
        <font-awesome-icon
          size="lg"
          :class="isNegative ? 'text-danger' : 'text-success'"
          :icon="isNegative ? 'calendar-times' : 'calendar-check'"
        ></font-awesome-icon>
      </div>

      <b-col>
        <span class="mb-0">{{ formatDate(range[0]) }}</span>
        <font-awesome-icon
          class="mx-2 text-muted"
          size="sm"
          icon="angle-double-right"
        ></font-awesome-icon>
        <span class="mb-0">{{ formatDate(range[1]) }}</span>
      </b-col>

      <IconButton class="mr-2" @click.native="editRange(index)">
        <font-awesome-icon icon="pen"></font-awesome-icon>
      </IconButton>
      <IconButton class="text-danger" @click.native="deleteRange(index)">
        <font-awesome-icon icon="trash"></font-awesome-icon>
      </IconButton>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "@/variables.scss";

.range-list {
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid $default-gray;
  border-radius: 0.5rem;

  .range-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;

    .leading {
      padding: 0.25rem 1rem;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $default-gray;
    }
  }
}
</style>

<script>
import IconButton from "./IconButton.vue";

import moment from "moment";

export default {
  name: "AvailabilityRangesList",
  components: {
    IconButton,
  },
  props: {
    rangeList: Array,
    isNegative: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /*      
        @add-negative="addNegativeRange"
        @add-positive="addPositiveRange"
        @edit-positive="editPositiveRange"
        @edit-negative="editNegativeRange"
*/
    editRange(index) {
      this.$emit("edit-range", index, this.isNegative);
    },
    deleteRange(index) {
      this.$emit("delete-range", index, this.isNegative);
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("ddd D MMM YYYY");
    },
  },
};
</script>