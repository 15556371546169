export class Marker {

    constructor(
        id,
        coordinates,
        title,
        image,
        address,
        category
    ) {
        this.id = id;
        this.coordinates = coordinates;
        this.title = title;
        this.image = image;
        this.address = address;
        this.category = category;
        this.setColor();
    }

    setColor() {
        switch (this.category) {
            case "event":
            default:
                this.color = "#0095ff";
                break;

            case "monument":
                this.color = "#00d68f";
                break;

            case "service":
                this.color = "#ff3d71";
                break;
        }
    }
}