<template>
  <div class="booking-checkout">
    <Loader :size="6" v-if="(!booking || !content) && !error" />

    <template v-else>
      <CustomAlert type="danger" class="text-xl mb-0" v-if="error">
        <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
        <span class="ml-2">An error occured, please try again.</span>
      </CustomAlert>

      <template v-else>
        <BookingRecap
          :content="content"
          :booking="booking"
          :showStatus="booking.status != 'confirmed_pending_payment'"
        />

        <br />

        <b-container v-if="booking.status == 'confirmed_pending_payment'">
          <b-row align-v="center" align-h="center">
            <RaisedButton
              @click.native="stripeCheckout"
              variant="success"
              size="lg"
            >
              <font-awesome-icon icon="credit-card"></font-awesome-icon>
              <span class="ml-2">Procedi al pagamento</span>
            </RaisedButton>
          </b-row>
        </b-container>
      </template>
    </template>
  </div>
</template>

<style lang="scss">
.booking-checkout {
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.25rem;
  background-color: white;

  .text-xl {
    font-size: 1.25rem;
  }
}
</style>

<script>
import { Booking, Contents, Payments } from "../api";

import Loader from "../components/Loader.vue";
import RaisedButton from "../components/RaisedButton.vue";
import BookingRecap from "../components/BookingRecap.vue";
import CustomAlert from "../components/CustomAlert.vue";
import { handleError } from "../utils";
export default {
  name: "BookingCheckout",
  components: {
    CustomAlert,
    BookingRecap,
    RaisedButton,
    Loader,
  },
  data: () => ({
    content: null,
    booking: null,
    contentId: null,
    bookingId: null,
    error: false,
  }),
  created() {
    this.getBooking();
  },
  methods: {
    getBooking() {
      this.bookingId = this.$route.query.booking;
      this.contentId = this.$route.query.content;

      Booking.getContentRequest(this.contentId, this.bookingId).then(
        (res) => {
          this.booking = res.data;
          this.getContent();
        },
        (err) => {
          console.log(err);
          this.error = true;
        }
      );
    },
    getContent() {
      Contents.getContent(this.contentId).then(
        (res) => {
          this.content = res.data;
        },
        (err) => {
          console.log(err);
          this.error = true;
        }
      );
    },
    stripeCheckout() {
      this.$loading.show();

      Payments.createCheckoutSession(this.bookingId).then(
        (res) => {
          const payload = res.data;
          this.$stripe.redirectToCheckout({ sessionId: payload.sessionID });
        },
        (err) => {
          console.log(err);
          handleError(
            this,
            err,
            "messages.bookingCheckoutErrorMsg",
            "messages.bookingCheckoutError"
          );

          this.$loading.hide();
        }
      );
    },
  },
};
</script>