<template>
  <div class="availability-calendar">
    <b-row>
      <vue-calendar
        :is-expanded="isExpanded"
        :locale="locale"
        :min-page="minDate"
        :available-dates="validDates"
        :disabled-dates="{}"
        :attributes="attributes"
        :step="1"
        @update:from-page="monthChange"
        @dayclick="dayClick"
      >
      </vue-calendar>
    </b-row>
    <b-row>
      <div v-if="daySelected && slots.length > 0" class="p-2">
        <h6>
          {{
            $t("components.availabilityForm.availableSlots", {
              day: daySelectedFormatted,
            })
          }}
        </h6>

        <b-row no-gutters>
          <b-col v-for="(slot, index) of slots" :key="index">
            <div class="border p-2 m-1">
              <h6 class="mb-0">{{ slot.from }} » {{ slot.to }}</h6>
              <span class="text-muted text-truncate">
                {{
                  $t("components.bookingSlotItem.availableSpots", {
                    amount: slot.amount,
                  })
                }}</span
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </b-row>
  </div>
</template>

<script>
import {
  endOfMonth,
  startOfMonth,
  format,
  setMonth,
  isSameMonth,
  parseISO,
  setYear,
  isSameYear,
} from "date-fns";
import { Availability } from "../api";

export default {
  name: "AvailabilityCalendar",
  props: {
    packageId: {
      type: String,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    locale: this.$i18n.locale,
    availabilities: null,
    attributes: [
      {
        key: "selectedDay",
        highlight: "blue",
        dates: null,
      },
    ],
    availabilityMap: {},
    validDates: [],
    today: null,
    minDate: null,
    initialized: false,
    daySelected: null,
    slots: [],
  }
  },
  created() {
    this.today = new Date();
    this.minDate = {
      day: this.today.getDate(),
      month: this.today.getMonth() + 1,
      year: this.today.getFullYear(),
    };
    this.calculateAvailableDates();
  },
  computed: {
    daySelectedFormatted() {
        return format(parseISO(this.daySelected), "dd-MM-yyyy");
    },
  },
  methods: {
    calculateAvailableDates(start, end) {
      Availability.getAvailability(
        this.packageId,
        start || format(this.today, "yyyy-MM-dd"),
        end || format(endOfMonth(this.today), "yyyy-MM-dd")
      ).then((res) => {
        this.availabilities = res.data;
        this.initialized = true;

        this.validDates = this.availabilities.map((date) => ({
          start: date.day,
          end: date.day,
        }));

        this.availabilityMap = this.availabilities.reduce(
          (accumulator, date) => {
            let key = date["day"];
            accumulator[key] = date["slots"];
            return accumulator;
          },
          {}
        );
      });
    },
    monthChange(evt) {
      if (this.initialized) {
        let newMonth = startOfMonth(setYear(setMonth(new Date(), evt.month - 1),evt.year));
        let endOfNewMonth = endOfMonth(newMonth);

        if (isSameMonth(newMonth, this.today) && isSameYear(newMonth, this.today)) {
          newMonth = this.today;
        }
        this.calculateAvailableDates(
          format(newMonth, "yyyy-MM-dd"),
          format(endOfNewMonth, "yyyy-MM-dd")
        );
      }
    },
    dayClick(evt) {
      if (this.availabilityMap[`${evt.id}`]) {
        this.daySelected = evt.id;
        this.attributes[0].dates = new Date(evt.id);
        this.slots = this.availabilityMap[`${evt.id}`];
      } else {
        this.attributes[0].dates = null;
        this.daySelected = null;
      }
    },
  },
};
</script>