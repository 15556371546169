<template>
  <div class="login-component">
    <b-container class="pt-5">
      <b-row align-h="center" align-v="center">
        <b-col md="8" lg="6">
          <b-card class="px-3 pt-5 pb-3">
            <FlatButton
              class="cancel-button"
              @click.native="$router.push({ name: 'Home' })"
            >
              <font-awesome-icon icon="chevron-left" />
              <span class="ml-2">{{ $t("actions.cancel") }}</span>
            </FlatButton>
            <LocalesCombo class="locales-combo" />

            <div class="text-center py-4">
              <router-link :to="{ name: 'Home' }">
                <img src="../assets/scialari.svg" width="150" />
              </router-link>
            </div>

            <validation-observer ref="loginObserver" v-slot="{ handleSubmit }">
              <b-form @submit.stop.prevent="handleSubmit(submitCredentials)">
                <validation-provider
                  name="Email"
                  :rules="{ required: true, email: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="email-input-group"
                    label="Email"
                    label-for="email-input"
                  >
                    <b-form-input
                      id="email-input"
                      name="geo-email"
                      :placeholder="$t('pages.login.placeholders.userEmail')"
                      v-model="username"
                      :state="getValidationState(validationContext)"
                      aria-describedby="email-input-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="email-input-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  name="Password"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="password-input-group"
                    label="Password"
                    label-for="password-input"
                  >
                    <b-form-input
                      id="password-input"
                      name="geo-password"
                      :placeholder="$t('pages.login.placeholders.userPassword')"
                      v-model="password"
                      type="password"
                      :state="getValidationState(validationContext)"
                      aria-describedby="password-input-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="password-input-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <div class="text-center">
                   <router-link :to="{ name: 'ForgotPassword'}">{{ $t("pages.login.forgotPassword") }}</router-link>
                </div>
                <br />

                <div class="text-center">
                  <RaisedButton
                    type="submit"
                    variant="info"
                    class="w-50 wide-button"
                  >
                    <font-awesome-icon icon="sign-in-alt"></font-awesome-icon>
                    <span class="ml-3">{{ $t("actions.signIn") }}</span>
                  </RaisedButton>

                  <template v-if="!adminLogin">
                    <div class="or-divider text-muted">
                      {{ $t("pages.login.or") }}
                    </div>

                    <FlatButton
                      type="button"
                      class="text-success w-50 wide-button"
                      @click.native="$router.push({ name: 'Registration' })"
                    >
                      <font-awesome-icon icon="user-plus" />
                      <span class="ml-2">{{ $t("pages.login.register") }}</span>
                    </FlatButton>
                  </template>
                </div>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>

        <b-col class="d-none d-md-block" md="4" v-if="!adminLogin">
          <img src="../assets/mobile-localization.svg" width="100%" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
.login-component {
  .locales-combo,
  .cancel-button {
    position: absolute;
    top: 1rem;
  }
  .locales-combo {
    right: 1rem;
  }
  .cancel-button {
    left: 1rem;
  }

  .wide-button {
    min-width: 200px;
  }

  .or-divider {
    text-align: center;
    position: relative;
    padding: 1.5rem 0;
    text-transform: uppercase;
    font-size: 0.825rem;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40%;
      border-bottom: 1px solid #ccc;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
}
</style>

<script>
import { Auth } from "../api";

import FlatButton from "../components/FlatButton.vue";
import RaisedButton from "../components/RaisedButton.vue";
import LocalesCombo from "../components/LocalesCombo.vue";

import jwt_decode from "jwt-decode";

export default {
  name: "Login",
  data: () => ({
    username: null,
    password: null,
    adminLogin: false,
  }),
  components: {
    RaisedButton,
    FlatButton,
    LocalesCombo,
  },
  created() {
    this.adminLogin = this.$route.meta.isAdmin;
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.username = null;
      this.password = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    submitCredentials() {
      this.$loading.show();
      const body = {
        username: this.username,
        password: this.password,
      };

      Auth.login(body).then(
        ({ data: { token } }) => {
          const date = new Date();
          date.setDate(date.getDate() + 1); // token lasts 1 day

          this.$cookies.set("atlas_token", token, date); // 24h

          const decoded = jwt_decode(token);
          this.$cookies.set("atlas_role", decoded.role, date); // 24h

          this.$root.$bvToast.toast(this.$t("messages.loggedIn"), {
            title: this.$t("messages.success"),
            variant: "success",
            solid: true,
          });
          this.$loading.hide();
          if (decoded.role == "admin" || decoded.role == "provider")
            this.$router.push({ name: "ContentsList" });
          else this.$router.push({ name: "Account" });
        },
        ({ response }) => {
          const error = response.data;

          if (response.status == 401) {
            this.$bvToast.toast(error.error, {
              title: error.message,
              variant: "danger",
              solid: true,
            });
          } else if(response.status == 402){
            
           this.$router.push({ name: "ProviderStripeConfiguration" });
          
          } else if (response.status == 403) {
            this.$bvToast.toast(this.$t("messages.userInactive"), {
              title: error.error,
              variant: "danger",
              solid: true,
            });
          } else if (response.status == 404) {
            this.$bvToast.toast(this.$t("messages.userUnregistered"),
              {
                title: error.error,
                variant: "danger",
                solid: true,
              }
            );
          } else {
            this.$bvToast.toast(error.message, {
              title: error.error,
              variant: "danger",
              solid: true,
            });
          }

          this.$loading.hide();
        }
      );
    },
  },
};
</script>
