<template>
  <b-card no-body class="border-none">
    <div class="content-item">
      <b-col cols="12" :lg="mapMode ? 12 : 4" :xl="mapMode ? 12 : 3" class="content-image w-100" :style="{
        'background-image': 'url(' + imageUrl + content.id + '/main)',
      }">
      </b-col>

      <b-col class="content-body">
        <div class="content-title text-truncate" :title="getFieldTranslation(content, 'title')">
          {{ getFieldTranslation(content, "title") }}
        </div>

        <div class="content-info text-truncate" v-if="
          content.address.street ||
          content.address.city ||
          content.address.country
        ">
          <font-awesome-icon icon="map-marker-alt"></font-awesome-icon>
          <span class="ml-1">{{ content.address | contentAddress }}</span>
        </div>

        <div class="content-description">
          {{ getFieldTranslation(content, "abstract") }}
        </div>

        <!-- <div class="content-info" v-if="content.duration">
        <b-icon icon="calendar-event"></b-icon>
        <span class="ml-1">{{ content.duration }}</span>
      </div> -->

        <div class="content-distance" v-if="showDistance && content.distance">
          <font-awesome-icon icon="arrows-alt-h"></font-awesome-icon>
          <span class="ml-1">{{ content.distance | distance }}</span>
        </div>

        <div class="content-price-card" v-if="
          content.showcasePrice &&
          content.showcasePrice !== 0 &&
          (!content.discount || content.discount === 0)
        ">
          <span class="mini-txt pb-1 mr-2">{{ $t("common.startPrice") }}</span>
          <!-- {{ content.showcasePrice }} € -->
          <PriceLabel :price="content.showcasePrice" />
        </div>

        <div v-else-if="content.showcasePrice !== 0" class="content-price-card">
          <span class="mini-txt pb-1 mr-2">{{ $t("common.startPrice") }}</span>
          <div>
            <span class="price-cut ml-4">{{ content.showcasePrice }}€</span>
            <PriceLabel class="discounted" :price="evaluateDiscount()" />
          </div>
        </div>

      </b-col>
    </div>
  </b-card>
</template>

<style lang="scss">
@import "@/variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.content-item {
  overflow: hidden;
  position: relative;
  box-shadow: 0 0.12rem 0.25rem rgba(black, 0.2);
  display: flex;
  // flex-wrap: wrap;

  &:hover {
    box-shadow: 0 0 0.5rem $geo-info;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  .discounted {
    transform: skewY(-8deg);
    background-color: #ff3a56;
    border-radius: 3px 4px 4px 3px;
    position: relative;
    margin-bottom: 0.25rem;
    color: white;
    padding: 0 10px 0 10px;
  }

  .content-image {
    padding: 0;
    min-height: 200px;
    background-color: #ccc;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .content-body {
    padding: 0.25rem 0.5rem;
    padding-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      overflow: hidden;
    }

    .content-title {
      font-weight: 600;
      line-height: 1.5;
      font-size: calc(1rem + 0.15vw);
      padding: 0.25rem;
    }

    .content-description {
      padding: 0.25rem;
      display: -webkit-box;
      font-size: 0.85rem;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .content-info {
      font-size: 0.85rem;
      color: $geo-info;
      padding: 0.25rem;
    }

    .content-distance {
      font-size: 0.8rem;
      font-weight: 500;
      color: $accent;
      position: absolute;
      bottom: 0.5rem;
    }

    .content-price-card {
      display: flex;
      align-items: flex-end;
      font-size: 1.2rem;
      font-weight: 500;
      position: absolute;
      bottom: 0.5rem;
      right: 0.75rem;

      .mini-txt {
        font-size: 0.6rem;
      }
    }
  }
}
</style>

<script>
import { MULTIMEDIA_API } from "../globals";
import PriceLabel from "./PriceLabel.vue";

export default {
  name: "ContentItem",
  components: {
    PriceLabel,
  },
  props: {
    content: Object,
    highlight: Boolean,
    mapMode: Boolean,
  },
  data: () => ({
    imageUrl: `${MULTIMEDIA_API}/data/`,
    showDistance: false,
  }),
  created: function () {
    this.showDistance = this.$cookies.get("USER_POSITION") != undefined;
  },
  methods: {
    evaluateDiscount() {
      return (
        this.content.showcasePrice -
        (this.content.showcasePrice / 100) * this.content.discount
      );
    },
  },
};
</script>