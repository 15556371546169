<template>
  <button
    class="flat-button"
    :type="type"
    :class="[size, 'bg-' + variant]"
    v-b-tooltip.hover
    :title="tooltip"
  >
    <slot></slot>
  </button>
</template>

<style lang="scss">
.flat-button {
  border: none;
  background: transparent;
  &:hover {
    background-color: #eee;
  }

  &:disabled {
    opacity: 0.65;
  }

  &:focus,
  &:active {
    outline: none;
  }
  &:active {
    background-color: #ddd;
  }

  &.sm {
    padding: 0.12rem;
    font-size: 0.85rem;
  }
  &.md {
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
  }
  &.lg {
    padding: 1rem;
    font-size: 1.2rem;
  }
}
</style>

<script>
export default {
  name: "FlatButton",
  props: {
    tooltip: String,
    type: {
      type: String,
      default: "button",
    },
    size: {
      type: String,
      default: "md",
    },
    variant: {
      type: String,
      default: "basic"
    }
  },
};
</script>