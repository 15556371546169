export class BookingCheckout {

    constructor(
        userId,
        name,
        email,
        phone,
        captcha,
        bookings) {
        this.userId = userId || "";
        this.name = name || "";
        this.email = email || "";
        this.phone = phone || "";
        this.captcha = captcha || "";
        this.bookings = bookings || [];
    }
}