import { Translation } from "./Translation";
export class PricePlanOption {

    constructor(
        title, // required
        adultPrice,
        kidPrice,
        perPerson,
    ) {
        this.title = title || new Translation();
        this.adultPrice = adultPrice || undefined;
        this.kidPrice = kidPrice || undefined;
        this.perPerson = perPerson || false;
    }
}