<template>
  <b-row align-h="center" no-gutters>
    <b-spinner
      label="Spinning"
      :variant="variant"
      class="geo-loader"
      :style="{ height: size + 'em', width: size + 'em' }"
    ></b-spinner>
    <h6 v-if="message">{{ message }}</h6>
  </b-row>
</template>

<script>
export default {
  name: "Loader",
  props: {
    size: Number,
    variant: String,
    message: String,
  },
};
</script>