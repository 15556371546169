<template>
  <div class="provider-initialization">
    <b-container class="pt-4">
      <b-card>
        <div class="text-right"><LocalesCombo class="locales-combo" /></div>

        <div class="text-center mb-4">
          <img src="../../assets/scialari.svg" width="200px" class="mb-4" />

          <h5>{{ $t("pages.recovery.restorePassword") }}</h5>
          <span class="text-muted">
            {{ $t("pages.recovery.newPassword") }}
          </span>
        </div>

        <b-row align-h="center" class="mb-2">
          <b-col cols="6">
            <validation-observer
              ref="passwordObserver"
              v-slot="{ handleSubmit }"
            >
              <b-form @submit.prevent="handleSubmit(resetPassword)">
                <validation-provider
                  name="newPassword"
                  :rules="{ required: true, password: '@confirmPassword' }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="new-password-fieldset"
                    label="New Password"
                    label-for="new-password"
                    :label-cols="4"
                  >
                    <b-form-input
                      id="new-password"
                      v-model.trim="newPassword"
                      placeholder="Account password"
                      :state="getValidationState(validationContext)"
                      type="password"
                    ></b-form-input>

                    <b-form-invalid-feedback id="new-password-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  name="confirmPassword"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="confirm-password-fieldset"
                    label="Confirm Password"
                    label-for="confirm-password"
                    :label-cols="4"
                  >
                    <b-form-input
                      id="confirm-password"
                      v-model.trim="confirmPassword"
                      placeholder="Confirm previous password"
                      :state="getValidationState(validationContext)"
                      type="password"
                    ></b-form-input>

                    <b-form-invalid-feedback
                      id="confirm-password-live-feedback"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <div class="text-center mt-4">
                  <RaisedButton
                    type="submit"
                    variant="success"
                    :disabled="newPassword != confirmPassword"
                  >
                    <font-awesome-icon icon="shield-alt"></font-awesome-icon>
                    <span class="ml-2">{{ $t("pages.recovery.proceed") }}</span>
                  </RaisedButton>
                </div>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { Recovery } from "../../api";
import RaisedButton from "../../components/RaisedButton.vue";
import LocalesCombo from "../../components/LocalesCombo.vue";
import { handleError } from "../../utils";
export default {
  components: {
    RaisedButton,
    LocalesCombo,
  },
  name: "ResetPassword",
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      tkn: null,
    };
  },
  created() {
    this.tkn = this.$route.query.ref;
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetPassword() {
      let credentials = { token: this.tkn, password: this.newPassword };

      this.$loading.show();

      Recovery.resetPassword(credentials).then(
        () => {
          this.$loading.hide();
          this.$root.$bvToast.toast(this.$t("messages.credentialResetMsg"), {
            title: this.$t("messages.passwordReset"),
            variant: "success",
            solid: true,
          });
          this.$router.push({ name: "Login" });
        },
        (err) => {
          this.$loading.hide();
          handleError(
            this,
            err,
            "messages.resetErrorMsg",
            "messages.resetError"
          );
          this.$router.push({ name: "Login" });
        }
      );
    },
  },
};
</script>
