import { Translation } from "./Translation";
export class ExtraField {

    constructor(
        title,
        type,
        icon,
        boolValue,
        stringValue
    ) {
        this.id = null;
        this.title = title || new Translation();
        this.type = type || 'string'; // number | string | boolean
        this.icon = icon || '';
        this.boolValue = boolValue || false
        this.stringValue = stringValue ||  new Translation();
    }  
}