<template>
  <Loader :size="8" v-if="!content" />

  <div v-else>
    <CustomAlert v-if="error" type="danger">
      <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
      <span class="ml-2">{{ $t("message.errorOccured") }}</span>
    </CustomAlert>

    <template v-else>
      <CustomBreadcrumb class="mb-3">
        <b-breadcrumb-item :to="{ name: 'ContentsList' }">{{
          $t("admin.navigation.contentsList")
        }}</b-breadcrumb-item>
        <b-breadcrumb-item v-if="content.id" :to="{ name: 'ContentPreview', params: { id: content.id } }">{{
          getFieldTranslation(content, "title") }}</b-breadcrumb-item>
        <b-breadcrumb-item class="text-truncate" active>
          {{ content.id ? "Modifica" : "Nuovo Contenuto" }}
        </b-breadcrumb-item>
      </CustomBreadcrumb>

      <div class="content-edit">
        <GalleryEditor v-if="contentImages" :existingImages="contentImages" @uploaded-images="newImages = $event" />

        <validation-observer ref="contentObserver" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(saveContent)">
            <CustomTabset :tabs="contentTabs">
              <template slot="tab-pane-overview">
                <div class="content-overview" key="overview">
                  <validation-provider name="Category" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group id="content-category-fieldset" label="Category" label-for="content-category">
                      <b-form-select id="content-category" v-model="content.category" :options="categoriesOptions"
                        :state="getValidationState(validationContext)">
                        <b-form-select-option hidden :value="null">Select content category</b-form-select-option>
                      </b-form-select>

                      <b-form-invalid-feedback id="content-category-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider name="Type" :rules="{ required: true }" v-slot="validationContext">
                    <b-form-group id="content-booking-typology-fieldset"
                      :label="$t('components.contentEditPage.bookingTypeLabel')" label-for="booking-tipology">
                      <b-form-select id="booking-tipology" v-model="content.bookingType" :options="bookingOptions"
                        :state="getValidationState(validationContext)">
                        <b-form-select-option hidden :value="null">{{
                          $t("components.contentEditPage.selectBookingType")
                        }}</b-form-select-option>
                      </b-form-select>

                      <b-form-invalid-feedback id="content-category-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <CustomTabset :tabs="languageTabs">
                    <template slot="tab-pane-ita">
                      <div class="content-i18n" key="i18n-ita">
                        <b-row>
                          <b-col cols="12" lg="6">
                            <validation-provider name="Nome" :rules="{ required: true }" v-slot="validationContext">
                              <b-form-group id="content-name-fieldset" class="mb-3" label="Nome"
                                label-for="content-name">
                                <b-form-input id="content-name" v-model.trim="content.title[0].text"
                                  placeholder="Nome contenuto (italiano)"
                                  :state="getValidationState(validationContext)"></b-form-input>

                                <b-form-invalid-feedback id="content-name-live-feedback">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>

                            <validation-provider name="Descrizione breve" :rules="{ required: true }"
                              v-slot="validationContext">
                              <b-form-group id="content-abstract-fieldset" label="Descrizione breve"
                                label-for="content-abstract">
                                <b-form-textarea id="content-abstract" v-model.trim="content.abstract[0].text" rows="4"
                                  placeholder="Descrizione Breve Contenuto (italiano)"
                                  :state="getValidationState(validationContext)"></b-form-textarea>

                                <b-form-invalid-feedback id="content-abstract-live-feedback">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>

                          <b-col cols="12" lg="6">
                            <b-form-group id="content-abstract-it-format-fieldset" label="Descrizione completa"
                              label-for="content-abstract-format-it">
                              <wysiwyg id="content-abstract-format-it" :class="{
                                'border-danger': descriptionError,
                              }" v-model.trim="content.description[0].text" />
                            </b-form-group>
                            <b-form-invalid-feedback class="d-block" v-if="descriptionError"
                              id="content-description-live-feedback">
                              {{ $t("messages.invalidDescription") }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>

                        <template v-if="content.extra">
                          <br />
                          <h6>
                            {{ $t("admin.contents.edit.sections.extra") }}
                          </h6>
                          <ExtraFieldsEditor :fields="content.extra" :availableFields="availableFields || []"
                            currentLanguage="it_IT" @new-field="content.extra.push($event)"
                            @sorted="content.extra = $event" @remove="content.extra.splice($event, 1)" />
                        </template>
                      </div>
                    </template>

                    <template slot="tab-pane-eng">
                      <div class="content-i18n" key="i18n-eng">
                        <b-row>
                          <b-col cols="12" lg="6">
                            <b-form-group id="content-name-en-fieldset" class="mb-3" label="Nome"
                              label-for="content-name-en">
                              <b-form-input id="content-name-en" v-model.trim="content.title[1].text"
                                placeholder="Nome contenuto (inglese)"></b-form-input>
                            </b-form-group>

                            <b-form-group id="content-abstract-en-fieldset" label="Descrizione breve"
                              label-for="content-abstract-en">
                              <b-form-textarea id="content-abstract-en" v-model.trim="content.abstract[1].text" rows="4"
                                placeholder="Descrizione Breve Contenuto (inglese)"></b-form-textarea>
                            </b-form-group>
                          </b-col>

                          <b-col cols="12" lg="6">
                            <b-form-group id="content-abstract-en-format-fieldset" label="Descrizione completa"
                              label-for="content-abstract-format-en">
                              <wysiwyg id="content-abstract-format-en" v-model.trim="content.description[1].text" />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <template v-if="content.extra">
                          <br />
                          <h6>
                            {{ $t("admin.contents.edit.sections.extra") }}
                          </h6>
                          <ExtraFieldsEditor :fields="content.extra" :availableFields="availableFields || []"
                            currentLanguage="en_EN" @new-field="content.extra.push($event)"
                            @sorted="content.extra = $event" @remove="content.extra.splice($event, 1)" />
                        </template>
                      </div>
                    </template>
                  </CustomTabset>
                </div>
              </template>

              <template slot="tab-pane-tags">
                <div class="content-tags" key="tags">
                  <div class="mb-1" v-for="(tag, index) of tagsCheckboxes" :key="index">
                    <CustomCheckbox :id="'tag-' + tag.id" :label="getFieldTranslation(tag, 'label')" :value="tag.value"
                      @input="toggleTag($event, index)" />
                  </div>
                </div>
              </template>

              <template slot="tab-pane-location-contacts">
                <div class="content-location-contacts" key="contacts">
                  <div class="content-info-card mb-3">
                    <div class="info-title">
                      <font-awesome-icon icon="map-marked"></font-awesome-icon>
                      <span class="ml-2">{{
                        $t("admin.contents.edit.sections.location")
                      }}</span>
                    </div>

                    <b-row no-gutters>
                      <b-col md="6">
                        <LeafletMap class="mini-map" :placeholderMode="true" :editMarker="true" :zoom="8"
                          :center="invertCoords(content.location.coordinates)" :editMarkerPosition="invertCoords(content.location.coordinates)
                            " @locationUpdate="locationUpdate"></LeafletMap>
                      </b-col>

                      <b-col md="6" class="p-3">
                        <b-form-group label="Longitudine" label-cols="5" label-for="coordinates-longitude">
                          <b-form-input id="coordinates-longitude"
                            :value.sync="content.location.coordinates[0]"></b-form-input>
                        </b-form-group>

                        <b-form-group label="Latitudine" label-cols="5" label-for="coordinates-latitude" class="mb-0">
                          <b-form-input id="coordinates-latitude"
                            v-model.number="content.location.coordinates[1]"></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>

                  <b-row>
                    <b-col class="pr-2" lg="6">
                      <div class="content-info-card">
                        <div class="info-title">
                          <font-awesome-icon icon="address-book"></font-awesome-icon>
                          <span class="ml-2">{{
                            $t("admin.contents.edit.sections.contacts")
                          }}</span>
                        </div>

                        <div class="info-body">
                          <b-form-group id="contact-name-fieldset" label="Name" label-cols-lg="3" label-cols-xl="4"
                            label-for="contact-name">
                            <b-form-input id="contact-name" v-model.trim="content.contacts.title"
                              placeholder="Contact Name"></b-form-input>
                          </b-form-group>

                          <b-form-group id="contact-email-fieldset" label="Email" label-cols-lg="3" label-cols-xl="4"
                            label-for="contact-email">
                            <b-form-input id="contact-email" v-model.trim="content.contacts.email"
                              placeholder="Contact Email"></b-form-input>
                          </b-form-group>

                          <b-form-group id="contact-telephone-fieldset" label="Telephone" label-cols-lg="3"
                            label-cols-xl="4" label-for="contact-telephone" class="mb-1">
                            <b-form-input id="contact-telephone" v-model.trim="content.contacts.telephone" type="tel"
                              placeholder="Contact Telephone"></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                    </b-col>

                    <b-col class="pl-2" lg="6">
                      <div class="content-info-card">
                        <div class="info-title">
                          <font-awesome-icon icon="address-card"></font-awesome-icon>
                          <span class="ml-2">{{
                            $t("admin.contents.edit.sections.address")
                          }}</span>
                        </div>

                        <div class="info-body">
                          <b-form-group id="content-address-street-fieldset" label="Street" label-cols-lg="3"
                            label-cols-xl="4" label-for="content-address-street">
                            <b-form-input id="content-address-street" v-model.trim="content.address.street"
                              placeholder="Address Street"></b-form-input>
                          </b-form-group>

                          <b-form-group id="content-address-city-fieldset" label="City" label-cols-lg="3"
                            label-cols-xl="4" label-for="content-address-city">
                            <b-form-input id="content-address-city" v-model.trim="content.address.city"
                              placeholder="Address City"></b-form-input>
                          </b-form-group>

                          <b-form-group id="content-address-country-fieldset" label="Country" label-cols-lg="3"
                            label-cols-xl="4" class="mb-1" label-for="content-address-country">
                            <b-form-input id="content-address-country" v-model.trim="content.address.country"
                              placeholder="Address Country"></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </template>
              <template slot="tab-pane-package">
                <div class="content-package" key="package">
                  <b-row align-v="end" align-h="end" no-gutters class="mb-3">
                    <template>
                      <b-col class="ml-8" cols="3">
                        <b-button variant="light" block class="border" @click="addPackage">
                          <font-awesome-icon icon="plus"></font-awesome-icon>
                          <span class="ml-2">{{
                            $t("components.contentEditPage.newPackage")
                          }}</span>
                        </b-button>
                      </b-col>
                    </template>
                  </b-row>

                  <template>
                    <CustomAlert v-if="!content.packages || !content.packages.length">
                      <font-awesome-icon icon="info-circle"></font-awesome-icon>
                      <span class="ml-2">{{
                        $t("components.packageEditor.noPackage")
                      }}</span>
                    </CustomAlert>

                    <CustomTabset v-if="content.packages && content.packages.length > 0" :tabs="packagesTabs"
                      :editable="true" :minTabs="1" @tab-removed="content.packages.splice($event, 1)">
                      <template v-for="(newPackage, index) of packagesTabs" :slot="'tab-pane-' + newPackage.id">
                        <PackageEditor :key="'package-' + index" v-model="content.packages[index]" />
                      </template>
                    </CustomTabset>
                  </template>
                </div>
              </template>

              <template slot="tab-pane-external-links">
                <div class="content-external-links" key="external-links">
                  <ExternalLinksEditor :externalLinks="content.externalLinks" />
                </div>
              </template>
            </CustomTabset>

            <b-row align-h="between" no-gutters class="mt-3 border-top p-3">
              <FlatButton @click.native="$router.push({ name: 'ContentsList' })">
                <b-icon icon="chevron-left"></b-icon>
                <span class="ml-2">{{ $t("actions.cancel") }}</span>
              </FlatButton>
              <RaisedButton type="submit" variant="success">
                <b-icon icon="check2-circle"></b-icon>
                <span class="ml-2">{{ $t("actions.saveContent") }}</span>
              </RaisedButton>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.content-edit {
  overflow: hidden;
  background: white;
  border-radius: 0.25rem;

  .editr--content {
    min-height: 200px;
    max-height: 350px;
    overflow-y: auto;
  }

  .content-info-card {
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    overflow: hidden;

    .info-title {
      border-bottom: 1px solid #ddd;
      padding: 0.5rem;
      font-weight: 500;
    }

    .info-body {
      padding: 0.5rem;

      &.tags-wrapper {
        max-height: 300px;
        overflow-y: auto;
      }
    }
  }

  .content-location-contacts .map-container {
    height: 200px;
  }
}
</style>

<script>
import LeafletMap from "../components/LeafletMap.vue";
import FlatButton from "../components/FlatButton.vue";
import RaisedButton from "../components/RaisedButton.vue";
import GalleryEditor from "../components/GalleryEditor.vue";
import Loader from "../components/Loader.vue";
import CustomBreadcrumb from "../components/CustomBreadcrumb.vue";
import CustomTabset from "../components/CustomTabset.vue";
import ExtraFieldsEditor from "../components/ExtraFieldsEditor.vue";
import CustomAlert from "../components/CustomAlert.vue";
import PackageEditor from "../components/PackageEditor.vue";
import ExternalLinksEditor from "../components/ExternalLinksEditor.vue";
import CustomCheckbox from "../components/CustomCheckbox.vue";

import { MULTIMEDIA_API } from "../globals";
import { Contents, Multimedia, Settings, BookingRules } from "../api";

import { NewContent } from "../models/NewContent";
import { FileMetadata } from "../models/FileMetadata";
import { PricePlan } from "../models/PricePlan";
import { NewPackage } from "../models/NewPackage";
import { handleError } from "../utils";
export default {
  name: "ContentEdit",
  components: {
    CustomAlert,
    ExtraFieldsEditor,
    CustomTabset,
    CustomBreadcrumb,
    Loader,
    GalleryEditor,
    RaisedButton,
    FlatButton,
    LeafletMap,
    /*     
    BookingRulesList,
    PricePlanEditor,
    CustomCheckbox, 
    */
    PackageEditor,
    CustomCheckbox,
    ExternalLinksEditor,
  },
  created: function () {
    // this.initContent();
    this.initSettings();
  },
  data: function () {
    return {
      content: null,
      extraFields: [],
      categories: [],
      tags: [],
      error: false,
      attachments: null,
      contentImages: null,
      newImages: null,
      descriptionError: false,
      bookingRules: null,
      userLocation: null,
      bookingOptions: [
        { value: "auto", text: "Automatic" },
        { value: "manual", text: "Manual" },
        { value: "none", text: "None" },
      ],
      languageTabs: [
        { id: "ita", title: "Italiano" },
        { id: "eng", title: "English" },
      ],
      contentTabs: [
        {
          id: "overview",
          title: this.$t("components.contentEditPage.overview"),
        },
        { id: "tags", title: "Tags" },
        {
          id: "location-contacts",
          title: this.$t("components.contentEditPage.location"),
        },
        { id: "package", title: this.$t("components.contentEditPage.package") },
        {
          id: "external-links",
          title: this.$t("components.contentEditPage.externalLinks"),
        },
      ],
    };
  },
  computed: {
    availableFields() {
      return this.extraFields
        .filter(f => !f.hidden)
        .filter(f => !this.content.extra.map((field) => field.id).includes(f.id));
    },
    tagsCheckboxes() {
      return this.tags
        .map((tag) => ({
          id: tag.id,
          label: tag.label,
          value: this.content.tags ? this.content.tags.includes(tag.id) : false,
        }))
        .sort((a, b) => a.text < b.text);
    },
    categoriesOptions() {
      return this.categories.map((cat) => ({
        value: cat.id,
        text: this.getFieldTranslation(cat, "name"),
      }));
    },
    pricePlansTabs() {
      return this.content.price.plans.map((plan, index) => ({
        id: "price-plan-" + index,
        title: this.getFieldTranslation(plan, "title"),
      }));
    },
    packagesTabs() {
      return this.content.packages.map((pack, index) => ({
        id: "package-" + index,
        title: this.getFieldTranslation(pack, "title"),
      }));
    },
  },
  methods: {
    initSettings() {
      Settings.getSettings().then(
        ({ data: settings }) => {
          this.extraFields = settings.fields ? settings.fields : [];
          this.categories = settings.categories || [];
          this.tags = settings.tags || [];
          this.initContent();
        },
        (err) => {
          console.log(err);
          this.error = true;
          handleError(
            this,
            err,
            "messages.retrieveInfoErrorMsg",
            "messages.contentError"
          );
        }
      );
    },
    initContent: function () {
      const id = this.$route.params.id;

      if (id) {
        const params = new URLSearchParams();
        params.set("draft", true);

        Contents.getContent(id, params).then(
          (res) => {
            this.content = new NewContent(res.data);
            this.content.location.coordinates = res.data.location.coordinates;
            if (!this.content.contacts) this.content.contacts = {};
            if (!this.content.price) this.content.price = {};
            if (!this.content.extra) this.content.extra = [];
            if (!this.content.packages) this.content.packages = [];
            this.getContentMultimedia();
            this.fixExtraFields();
            if (this.content.typeBook) this.getContentBookingRules();
            else this.bookingRules = [];
          },
          (err) => {
            console.log(err);
            handleError(
              this,
              err,
              "messages.contentDetailMsg",
              "messages.contentError"
            );
          }
        );
      } else {
        this.content = new NewContent({});
        this.contentImages = [];
        this.bookingRules = [];
      }
      this.initLocation();
    },
    initLocation() {
      if (this.$cookies.isKey("USER_POSITION")) {
        let cookiePosition = this.$cookies.get("USER_POSITION").split(",");
        this.userLocation = [
          Number(cookiePosition[0]),
          Number(cookiePosition[1]),
        ];

        if (!this.id) {
          this.content.location.coordinates = this.userLocation;
        }
      }
    },
    getContentBookingRules() {
      BookingRules.getRules(this.content.id).then(
        (res) => {
          this.bookingRules = res.data;
        },
        (err) => {
          console.log(err);
          this.bookingRules = [];
          handleError(
            this,
            err,
            "messages.bookingRulesMsg",
            "messages.contentError"
          );
        }
      );
    },
    getContentMultimedia() {
      Multimedia.getMultimediaList(this.content.id).then(
        (res) => {
          this.contentImages = res.data
            .map((f) => ({
              id: f.id,
              title: f.title,
              url: `${MULTIMEDIA_API}/data/${this.content.id}/${f.id}`,
              mainImage: f.mainImage,
            }))
            .sort((f) => (f.mainImage ? -1 : 0));
        },
        (err) => {
          if (err.response && err.response.status == 404) this.files = [];
          else
            handleError(
              this,
              err,
              "messages.multimediaErrorMsg",
              "messages.multimediaError"
            );
        }
      );
    },
    toggleTag(value, index) {
      this.$set(this.tagsCheckboxes[index], "value", value);
      this.content.tags = this.tagsCheckboxes
        .filter((tag) => tag.value)
        .map((tag) => tag.id);
    },
    fixExtraFields() {
      this.content.extra
        .sort((a, b) => a.position < b.position)
        .forEach((field, index) => {
          const completeField = this.extraFields.find((f) => f.id == field.id);

          if (!completeField) this.content.extra.splice(index, 1);
          else {
            field.icon = completeField.icon;
            field.title = completeField.title;
            field.type = completeField.type;
            if (field.type == "string") {
              field.stringValue ==
                [
                  { language: "it", text: "" },
                  { language: "en", text: "" },
                ];
            }
          }
        });
    },
    addPricePlan() {
      this.content.price.plans.push(new PricePlan());
    },
    addPackage() {
      this.content.packages.push(new NewPackage());
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    saveContent() {
      delete this.content.price;
      delete this.content.duration;
      this.$loading.show();

      if (this.content.description[0].text == "") {
        this.descriptionError = true;
        this.$loading.hide();
        return;
      }

      if (this.content.id) {
        Contents.updateContent(this.content).then(
          (res) => this.uploadMultimedia(res),
          (err) => {
            console.log(err);
            this.$loading.hide();
            handleError(
              this,
              err,
              "messages.contentNotUpdateMsg",
              "messages.contentError"
            );
          }
        );
      } else {
        Contents.createContent(this.content).then(
          (res) => this.uploadMultimedia(res),
          (err) => {
            console.log(err);
            this.$loading.hide();
            handleError(
              this,
              err,
              "messages.contentNotSavedMsg",
              "messages.contentError"
            );
          }
        );
      }
    },
    locationUpdate(evt) {
      this.content.location.coordinates = [evt.lng, evt.lat];
    },
    invertCoords(coords) {
      return [coords[1], coords[0]];
    },
    uploadMultimedia(res) {
      const newContent = res.data;
      const fileMetadata = [];
      const multimediaArray = [];

      if (this.contentImages) {
        const deletedImages = this.contentImages.filter((file) => file.deleted);

        if (deletedImages.length != 0) {
          for (let file of deletedImages) {
            const meta = new FileMetadata(
              file.name || file.title,
              false,
              file.id,
              file.deleted
            );
            fileMetadata.push(meta);
          }
        }
      }

      if (this.newImages) {
        for (let file of this.newImages) {
          const meta = new FileMetadata(
            file.name || file.title,
            file.mainImage,
            file.id
          );
          fileMetadata.push(meta);
        }
        multimediaArray.push(...this.newImages);
      }

      if (!multimediaArray.length) {
        this.$loading.hide();

        this.$root.$bvToast.toast(
          this.content.id
            ? this.$t("messages.contentEditOkMsg", {
              cnt: this.getFieldTranslation(this.content, "title"),
            })
            : this.$t("messages.contentCreateOkMsg", {
              cnt: this.getFieldTranslation(this.content, "title"),
            }),
          {
            title: "Success!",
            variant: "success",
            solid: true,
          }
        );

        this.$router.push({
          name: "ContentPreview",
          params: { id: newContent.id },
        });
        return;
      }

      const formData = new FormData();

      for (const file of multimediaArray) {
        formData.append("images", file);
      }
      if (fileMetadata.length) {
        formData.append("metadata", JSON.stringify(fileMetadata));
      }

      Multimedia.createMultimedia(newContent.id, formData).then(
        () => {
          this.$loading.hide();

          this.$root.$bvToast.toast(
            this.content.id
              ? this.$t("messages.contentEditOkMsg", {
                cnt: this.getFieldTranslation(this.content, "title"),
              })
              : this.$t("messages.contentCreateOkMsg", {
                cnt: this.getFieldTranslation(this.content, "title"),
              }),
            {
              title: "Success!",
              variant: "success",
              solid: true,
            }
          );

          this.$router.push({
            name: "ContentPreview",
            params: { id: newContent.id },
          });
        },
        (err) => {
          console.log(err);
          this.$loading.hide();
          handleError(this, err, "messages.saveErrorMsg", "messages.saveError");
        }
      );
    },
  },
};
</script>