<template>
  <div :class="['price-label', size]">
    <span class="price-currency">€</span>
    <span class="price-integers">{{ priceIntegers }}</span>
    <span class="price-decimals">{{ priceDecimals }}</span>
  </div>
</template>

<script>
export default {
  name: "PriceLabel",
  props: {
    price: Number,
    size: {
      type: String,
      default: "lg",
    },
  },
  computed: {
    priceIntegers: function () {
      return isNaN(this.price) ? 0 : Math.trunc(this.price);
    },
    priceDecimals: function () {
      return isNaN(this.price)
        ? "00"
        : (this.price - this.priceIntegers).toFixed(2).split(".")[1];
    },
  },
};
</script>

<style lang="scss" scoped>
.price-label {
  font-family: "Quicksand";
  font-weight: 600;

  .price-currency,
  .price-decimals {
    vertical-align: super;
    margin: 0 0.2rem;
  }

  &.sm {
    .price-currency,
    .price-decimals {
      vertical-align: text-top;
      font-size: 0.75rem;
    }
    .price-integers {
      font-size: 1rem;
    }
  }

  &.md {
    .price-currency,
    .price-decimals {
      font-size: 0.75rem;
    }
    .price-integers {
      font-size: 1.5rem;
    }
  }

  &.lg {
    .price-currency,
    .price-decimals {
      font-size: 1rem;
    }
    .price-integers {
      font-size: 1.75rem;
    }
  }
}
</style>