<template>
  <div class="checkout-wizard">
    <form-wizard
      :title="$t('components.checkoutWizard.pay')"
      subtitle=""
      ref="checkoutWizard"
      :start-index.sync="activeTabIndex"
      stepSize="xs"
      shape="circle"
      color="#20a0ff"
      error-color="#ff4949"
    >
      <tab-content
        :title="$t('components.bookingWizard.secondStep')"
        icon="ti-settings"
        :before-change="() => validateUserInfo()"
      >
        <template ref="userInfo">
          <div class="user-info m-3">
            <validation-observer ref="userInfoValidator">
              <b-form class="user-info-form">
                <validation-provider
                  :name="$t('common.name')"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="user-name-fieldset"
                    :label="$t('common.name')"
                    label-cols="4"
                    label-for="user-name-field"
                  >
                    <b-form-input
                      id="user-name-field"
                      :placeholder="$t('common.name')"
                      v-model.trim="bookingCheckout.name"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback id="user-name-field-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  :name="$t('common.email')"
                  :rules="{ required: true, email: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="user-email-fieldset"
                    :label="$t('common.email')"
                    label-cols="4"
                    label-for="user-email-field"
                  >
                    <b-form-input
                      id="user-email-field"
                      :placeholder="$t('common.email')"
                      type="email"
                      v-model.trim="bookingCheckout.email"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="user-email-field-live-feedback"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  :name="$t('common.telephone')"
                  :rules="{
                    required: true,
                    regex: /^[+]?[(]?[0-9]{1,4}[)]?[- \./0-9]*$/,
                  }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="user-telephone-fieldset"
                    :label="$t('common.telephone')"
                    label-cols="4"
                    label-for="user-telephone-field"
                  >
                    <b-form-input
                      id="user-telephone-field"
                      type="tel"
                      :placeholder="$t('common.telephone')"
                      :state="getValidationState(validationContext)"
                      v-model="bookingCheckout.phone"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="user-telephone-field-live-feedback"
                    >
                      {{ $t("errors.invalidTelephone") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form>
            </validation-observer>
          </div>
        </template>
      </tab-content>

      <tab-content
        :title="$t('components.checkoutWizard.recap')"
        icon="ti-fullscreen"
      >
        <h5>{{$t('components.checkoutWizard.goingPay')}}</h5>
        <b-row>
          <b-col md="8">
            <b-row
              class="align-items-center border rounded p-2"
              v-for="(item, index) of auto"
              :key="index"
            >
              <b-col md="4" class="d-flex flex-column">
                <span class="text-left"
                  >{{ getFieldTranslation(item.content, "title") }}
                </span>
                <span class="text-left"
                  >{{ getFieldTranslation(item.package, "title") }}
                </span>
              </b-col>
              <b-col md="4" class="d-flex flex-column">
                <span class="text-center"
                  >{{
                    item.start === item.end
                      ? item.start
                      : item.start + " -> " + item.end
                  }}
                </span>
                <span class="text-center" v-if="item.slotId"
                  >{{ item.slot.from }} -> {{ item.slot.to }}
                </span>
              </b-col>
              <b-col md="2" class="d-flex flex-column">
                <span class="text-right"
                  >{{ $t("common.adults") }}: {{ item.adultAmount }}
                </span>
                <span class="text-right"
                  >{{ $t("common.children") }}: {{ item.kidAmount }}
                </span>
              </b-col>
              <b-col md="2" class="d-flex flex-column">
                <strong v-if="item.discount === 0" class="text-right"> {{ item.sum }} €</strong>
                <strong v-else class="text-right"> {{calculateDiscount(item.sum,item.discount)}} €</strong>
              </b-col>
            </b-row>
            <hr v-if="manual.length > 0" />
            <b-row>
              <CustomAlert
                type="warning"
                class="w-100"
                v-if="manual.length > 0"
              >
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
                <span class="ml-2"
                  >{{$t('components.checkoutWizard.manualContent')}} </span
                >
                <b-row
                  class="align-items-center border border-warning rounded p-1"
                  v-for="(item, index) of manual"
                  :key="index"
                >
                  <b-col md="4" class="d-flex flex-column">
                    <span class="text-left"
                      >{{ getFieldTranslation(item.content, "title") }}
                    </span>
                    <span class="text-left"
                      >{{ getFieldTranslation(item.package, "title") }}
                    </span>
                  </b-col>
                  <b-col md="4" class="d-flex flex-column">
                    <span class="text-center"
                      >{{
                        item.start === item.end
                          ? item.start
                          : item.start + " -> " + item.end
                      }}
                    </span>
                    <span class="text-center" v-if="item.slot.from"
                      >{{ item.slot.from }} -> {{ item.slot.to }}
                    </span>
                  </b-col>
                  <b-col md="2" class="d-flex flex-column">
                    <span class="text-right"
                      >{{ $t("common.adults") }}: {{ item.adultAmount }}
                    </span>
                    <span class="text-right"
                      >{{ $t("common.children") }}: {{ item.kidAmount }}
                    </span>
                  </b-col>
                  <b-col md="2" class="d-flex flex-column">
                    <strong v-if="item.discount === 0" class="text-right"> {{ item.sum }} €</strong>
                    <strong v-else class="text-right"> {{calculateDiscount(item.sum,item.discount)}} €</strong>
              <!--       <strong class="text-right"> {{ item.sum }} {{item.discount}}€</strong> -->
                  </b-col>
                </b-row>
              </CustomAlert>
            </b-row>
          </b-col>
          <b-col md="4">
            <div class="p-3 border bg-light d-flex justify-content-between">
              <h5>{{$t('components.bookingWizard.total')}}</h5>
              <PriceLabel class="text-right" :price="autoTotal" size="md" />
            </div>
            <div>
              <vue-recaptcha
                ref="recaptcha"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
                :sitekey="siteKey"
                :loadRecaptchaScript="true"
              ></vue-recaptcha>
            </div>
          </b-col>
        </b-row>
      </tab-content>

      <RaisedButton variant="secondary" slot="prev">
        <font-awesome-icon icon="angle-left" />
        <span class="ml-2">{{ $t("components.bookingWizard.back") }}</span>
      </RaisedButton>

      <RaisedButton variant="info" slot="next">
        <span class="mr-2">{{ $t("components.bookingWizard.next") }}</span>
        <font-awesome-icon icon="angle-right" />
      </RaisedButton>

      <RaisedButton
        @click.native="buyNow"
        variant="success"
        slot="finish"
        :disabled="!canProceed"
      >
        <font-awesome-icon icon="cash-register" />
        <span class="ml-2">{{ $t("components.bookingWizard.buyNow") }}</span>
      </RaisedButton>
    </form-wizard>
  </div>
</template>

<style lang="scss" >
.wizard-icon-container {
  margin: -1px;
}
</style>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import PriceLabel from "../components/PriceLabel.vue";
import RaisedButton from "./RaisedButton.vue";
import VueRecaptcha from "vue-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../globals";
import CustomAlert from "./CustomAlert.vue";
import { BookingCheckout } from "../models/BookingCheckout.js";
import BookingCheckoutApi from "../api/BookingCheckoutApi";
import { calculateDiscount } from "../utils";
export default {
  name: "CheckoutWizard",
  components: {
    FormWizard,
    TabContent,
    RaisedButton,
    VueRecaptcha,
    CustomAlert,
    PriceLabel,
  },
  props: {
    items: Array,
    auto: Array,
    manual: {
      type: Array,
      default: new Array(),
    },
    autoTotal: Number,
    fromCart: { type: Boolean, default: true },
  },
  data() {
    return {
      bookingCheckout: new BookingCheckout(),
      activeTabIndex: 0,
      captchaError: false,
      siteKey: RECAPTCHA_SITE_KEY,
      canProceed: false,
      user: null,
    };
  },
  created: function () {
    this.user = this.$cookies.get("atlas_user");
    if (this.user) {
      this.bookingCheckout.name =
        this.user.firstName + " " + this.user.lastName;
      this.bookingCheckout.email = this.user.contacts.email;
      this.bookingCheckout.phone = this.user.contacts.telephone;
      this.bookingCheckout.userId = this.user.id;
    }
    if (this.items.length > 0) {
      this.bookingCheckout.bookings = this.items.map((it) => ({
        packageId: it.packageId,
        contentId: it.contentId,
        contentOwner: it.contentOwner,
        kidAmount: it.kidAmount,
        adultAmount: it.adultAmount,
        start: it.start,
        end: it.end,
        slotId: it.slotId,
        name: it.name,
        email: it.email,
        phoneNumber: it.phoneNumber,
        options: it.options,
        contentName: this.getFieldTranslation(it.content, "title"),
      }));
    }
  },
  methods: {
    buyNow() {
      if (this.canProceed) {
        BookingCheckoutApi.sendBookingCheckout(this.bookingCheckout).then(
          (res) => {
            const checkoutUrl = res.data.checkoutUrl;
            if (this.fromCart) {
              this.$cookies.set("atlas_cart", JSON.stringify([]), Infinity);
            }

            window.location.href = checkoutUrl;
          },
          (err) => {
            console.error(err);
          }
        );
      }
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
      this.canProceed = false;
    },
    onCaptchaVerified(recaptchaToken) {
      this.bookingCheckout.captcha = recaptchaToken;
      this.canProceed = true;
    },
    forceClearError() {
      this.$refs.wizard.tabs[this.activeTabIndex].validationError = null;
    },
    validateUserInfo() {
      return this.$refs.userInfoValidator.validate();
    },
    nextTab() {
      this.activeTabIndex += 1;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    calculateDiscount(price,discount){
      return calculateDiscount(price,discount)
    }
  },
};
</script>
