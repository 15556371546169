<template>
  <div class="custom-tabset">
    <CustomTabNav
      :tabs="tabs"
      v-model="activeTab"
      :editable="editable"
      :minTabs="minTabs"
      @tab-removed="removeTab"
      @input="tabChanged"
    >
      <!-- <template :slot="''"></template> -->
    </CustomTabNav>
    <main class="tabset-content">
      <div class="tab-pane">
        <transition name="tab-fade" mode="out-in">
          <slot :name="tabPaneSlotName"></slot>
        </transition>
      </div>
    </main>
  </div>
</template>

<style lang="scss">
.tab-pane {
  padding: 1rem;
}

.tab-fade-enter-active,
.tab-fade-leave-active {
  transition: all 0.2s;
}
.tab-fade-enter,
.tab-fade-leave-to {
  opacity: 0;
  transform: scale(0.95);
}
</style>

<script>
import CustomTabNav from "./CustomTabNav.vue";
export default {
  components: { CustomTabNav },
  name: "CustomTabset",
  props: {
    initialTab: String,
    tabs: Array,
    editable: Boolean,
    minTabs: Number,
  },
  data() {
    return {
      activeTab: this.initialTab || this.tabs[0].id,
    };
  },
  computed: {
    tabPaneSlotName() {
      return `tab-pane-${this.activeTab}`;
    },
  },
  methods: {
    removeTab(tab) {
      this.$emit("tab-removed", tab);
      setTimeout(() => (this.activeTab = this.tabs[0].id), 100);
    },
    tabChanged(evt){
      this.$emit("tab-changed",evt)
    }
  },
};
</script>