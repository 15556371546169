<template>
  <div class="image-upload" @click="openFileBrowser">
    <input
      type="file"
      ref="fileInput"
      accept="image/*"
      :multiple="multiple"
      @input="handleImageUpload"
    />

    <slot></slot>
  </div>
</template>

<style lang="scss">
.image-upload {
  input[type="file"] {
    position: absolute;
    transform: scale(0);
  }
}
</style>

<script>
export default {
  name: "ImageUpload",
  props: {
    multiple: Boolean,
  },
  methods: {
    handleImageUpload: async function () {
      if (this.multiple) {
        const uploadedImages = this.$refs.fileInput.files;
        let loaded = 0;

        if (!uploadedImages) return;

        for (const image of uploadedImages) {
          const reader = new FileReader();
          reader.onload = (e) => {
            loaded++;
            image.url = e.target.result;

            if (loaded == uploadedImages.length) {
              this.$emit("uploaded-images", uploadedImages);
            }
          };
          reader.readAsDataURL(image);
        }
      } else {
        const uploadedImage = this.$refs.fileInput.files[0];
        if (!uploadedImage) return;
        const reader = new FileReader();
        reader.onload = (e) => {
          uploadedImage.url = e.target.result;
          this.$emit("uploaded-image", uploadedImage);
        };
        reader.readAsDataURL(uploadedImage);
      }
    },
    openFileBrowser: function () {
      this.$refs.fileInput.click();
    },
    propagateDeletionOfFile(realFiles){
      let list = new DataTransfer()
      realFiles.forEach((x) => list.items.add(x))
      
      this.$refs.fileInput.files = list.files
    }
  },
};
</script>