<template>
  <div class="discounts-list">
    <Loader :size="6" v-if="!discounts" />

    <template v-else>
      <b-row class="align-items-center">
        <b-col cols="4">
          <Multiselect
            :value="user"
            :options="userOptions"
            :placeholder="$t('admin.discounts.user')"
            @select="applyFilter($event, 'user')"
            label="name"
            track-by="id"
          ></Multiselect>
        </b-col>
        <b-col cols="4">
          <Multiselect
            :value="provider"
            :options="providerOptions"
            :placeholder="$t('admin.discounts.provider')"
            @select="applyFilter($event, 'provider')"
            label="name"
            track-by="name"
          ></Multiselect>
        </b-col>
        <b-col cols="2" class="text-left">
          <RaisedButton
            variant="danger"
            class="mr-3"
            @click.native="removeFilter()"
          >
            <font-awesome-icon icon="filter"></font-awesome-icon>
            <span class="ml-2">{{ $t("components.discounts.remFilter") }}</span>
          </RaisedButton>
        </b-col>
        <b-col cols="2" class="text-right">
          <RaisedButton
            variant="success"
            class="mr-3"
            @click.native="createDiscount()"
          >
            <font-awesome-icon icon="plus"></font-awesome-icon>
            <span class="ml-2">{{
              $t("components.discounts.newDiscount")
            }}</span>
          </RaisedButton>
        </b-col>
      </b-row>

      <br />

      <CustomAlert v-if="!discounts.length">
        <font-awesome-icon icon="info-circle"></font-awesome-icon>
        <span class="ml-2">{{ $t("admin.discounts.noResults") }}</span>
      </CustomAlert>

      <div class="discounts-wrapper" v-else>
        <b-row
          no-gutters
          class="discount-list-item"
          v-for="discount of discounts"
          :key="discount.contentId"
        >
          <b-col
            cols="12"
            class="discount-info"
            v-b-toggle="'collapse-' + discount.contentId"
          >
            <div class="d-flex align-items-center">
              <Avatar :src="contentImageUrl + discount.contentId + '/main'" />

              <h6 class="mb-0 mr-2 pl-3">
                {{ getFieldTranslation(discount, "contentTitle") }}
              </h6>
            </div>

            <IconButton class="mr-2">
              <font-awesome-icon icon="caret-down"></font-awesome-icon>
            </IconButton>
          </b-col>
          <b-collapse
            :id="'collapse-' + discount.contentId"
            class="col-12 bg-light-grey mt-2 p-2"
          >
            <div
              v-for="dis of discount.discounts"
              :key="dis.id"
              class="discount-item bg-white ml-5"
            >
              <b-col class="p-2">
                <font-awesome-icon  :fixedWidth="true" :icon="dis.userId === 'all'? 'users': 'user'" class="ml-2"></font-awesome-icon>
                <strong class="ml-3">{{ returnUserName(dis.userId) }}</strong>
              </b-col>
              <b-col class="p-2 text-center">
                {{ $t("components.discounts.appliedDiscount") }}
                <strong>{{ dis.amount }}%</strong>
              </b-col>
              <b-col
                class="d-flex align-items-center justify-content-end shallow"
              >
                <IconButton
                  class="mr-2"
                  @click.native="openEditModal(dis.id, dis.amount)"
                >
                  <font-awesome-icon
                    icon="pen"
                    class="text-warning"
                  ></font-awesome-icon>
                </IconButton>
                <IconButton
                  class="mr-2"
                  @click.native="openDeleteModal(dis.id, discount.contentTitle)"
                >
                  <font-awesome-icon
                    icon="times"
                    class="text-danger"
                  ></font-awesome-icon>
                </IconButton>
              </b-col>
            </div>
          </b-collapse>
        </b-row>
      </div>

      <b-modal
        id="discount-wizard-modal"
        size="lg"
        centered
        hide-footer
        hide-header
        title="Wizard"
      >
        <DiscountsWizard
          :userOptions="userOptions"
          :providerOptions="providerOptions"
          @closeWizard="closeDiscountWizard()"
        >
        </DiscountsWizard>
      </b-modal>

      <b-modal
        hide-footer
        centered
        :title="'Edit Discount'"
        id="edit-discount-modal"
        size="lg"
        @hidden="resetEditingDiscount"
      >
        <EditDiscount
          :discount="editingDiscount"
          @cancel="$bvModal.hide('edit-discount-modal')"
          @submit-discount="editDiscount"
        />
      </b-modal>

      <DeleteModal @cancel="resetDeletingDiscount" @confirm="deleteDiscount">
        <p v-if="deletingDiscount">
          {{ $t("components.discounts.deleteDiscount") }}
          <b>{{ getFieldTranslation(deletingDiscount, "contentTitle") }}</b
          >?
        </p>
      </DeleteModal>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "@/variables.scss";

.discounts-wrapper {
  background-color: white;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
}

.bg-light-grey {
  background-color: #ddd;
  border-radius: 6px;
}

.discount-list-item {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-of-type) {
    border-bottom: 1px solid #ddd;
  }

  .discount-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .discount-item {
    display: flex;
    align-items: center;

    border: 1px solid #ddd;
    border-radius: 0.8rem;

    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
  }
}
</style>

<script>
import Loader from "../../components/Loader.vue";
import RaisedButton from "../../components/RaisedButton.vue";
import CustomAlert from "../../components/CustomAlert.vue";
import IconButton from "../../components/IconButton.vue";
import DiscountsWizard from "../../components/DiscountsWizard.vue";
import Multiselect from "vue-multiselect";
import Avatar from "../../components/Avatar.vue";
import DeleteModal from "../../components/DeleteModal.vue";
import EditDiscount from "../admin/discounts/EditDiscount.vue";
import { Discounts, Users } from "../../api";
import { MULTIMEDIA_API } from "../../globals";
import { handleError } from "../../utils";

export default {
  name: "discountsList",
  components: {
    Avatar,
    Multiselect,
    DiscountsWizard,
    IconButton,
    CustomAlert,
    DeleteModal,
    RaisedButton,
    EditDiscount,
    Loader,
  },
  computed: {},
  data() {
    return {
      discounts: null,
      contentImageUrl: null,
      user: null,
      userOptions: [],
      provider: null,
      providerOptions: [],
      deletingDiscount: null,
      editingDiscount: null,
    };
  },
  created() {
    this.getDiscounts();
    this.initUsers();
    this.contentImageUrl = `${MULTIMEDIA_API}/data/`;
  },
  methods: {
    initUsers() {
      Users.getUsers().then(
        ({ data: users }) => {
          this.userOptions = users
            .filter((u) => u.role === "user")
            .map((u) => ({ id: u.id, name: u.username }));
          this.providerOptions = users
            .filter((u) => u.role === "provider")
            .map((u) => ({ id: u.id, name: u.username }));
        },
        (err) => {
          console.log(err);
          handleError(
            this,
            err,
            "messages.userGetErrorMsg",
            "messages.userError"
          );
        }
      );
    },
    applyFilter(evt, filter) {
      switch (filter) {
        case "user":
          this.user = evt;
          break;
        case "provider":
          this.provider = evt;
          break;
      }
      this.discounts = null;
      this.getDiscounts();
    },
    removeFilter() {
      this.user = null;
      this.provider = null;
      this.discounts = null;
      this.getDiscounts();
    },
    getDiscounts() {
      Discounts.getDiscounts({
        userId: this.user ? this.user.id : "",
        providerId: this.provider ? this.provider.id : "",
      }).then(
        ({ data: discounts }) => {
          this.discounts = discounts;
        },
        (err) => {
          console.log(err);
          this.discounts = [];
          handleError(
            this,
            err,
            "messages.discountsErrorMsg",
            "messages.discountsError"
          );
        }
      );
    },
    createDiscount() {
      this.$bvModal.show("discount-wizard-modal");
    },
    closeDiscountWizard() {
      this.applyFilter();
      this.$bvModal.hide("discount-wizard-modal");
    },
    resetEditingDiscount() {
      this.$bvModal.hide("edit-discount-modal");
      this.editingDiscount = null;
    },
    resetDeletingDiscount() {
      this.$bvModal.hide("delete-modal");
      this.deletingDiscount = null;
    },
    openDeleteModal(id, contentTitle) {
      this.deletingDiscount = { id: id, contentTitle: contentTitle };
      this.$bvModal.show("delete-modal");
    },
    openEditModal(id, amount) {
      this.editingDiscount = { id: id, amount: amount };
      this.$bvModal.show("edit-discount-modal");
    },
    deleteDiscount() {
      this.$loading.show();
      Discounts.deleteDiscounts(this.deletingDiscount.id).then(
        () => {
          this.$bvToast.toast(this.$t("messages.discountDeleted"), {
            title: this.$t("messages.discountsSuccess"),
            variant: "success",
            solid: true,
          });
          this.discounts = null;
          this.getDiscounts();
          this.$bvModal.hide("delete-modal");
          this.$loading.hide();
          this.deletingDiscount = null;
        },
        (err) => {
          const error = err.response.data;
          handleError(
            this,
            err,
            error.message,
            error.error
          );
          this.$bvModal.hide("delete-modal");
          this.$loading.hide();
          this.deletingDiscount = null;
        }
      );
    },
    editDiscount() {
      this.$loading.show();
      Discounts.updateDiscounts(
        this.editingDiscount.id,
        this.editingDiscount.amount
      ).then(
        () => {
          this.$bvToast.toast(this.$t("messages.discountUpdate"), {
            title: this.$t("messages.discountsSuccess"),
            variant: "success",
            solid: true,
          });
          this.discounts = null;
          this.getDiscounts();
          this.$bvModal.hide("edit-discount-modal");
          this.$loading.hide();
          this.editingDiscount = null;
        },
        (err) => {
          const error = err.response.data;
          handleError(
            this,
            err,
            error.message,
            error.error
          );
          this.$bvModal.hide("edit-discount-modal");
          this.$loading.hide();
          this.editingDiscount = null;
        }
      );
    },
    returnUserName(id) {
      if(id === "all"){
        return this.$t("common.allUser")
      }
      return this.userOptions.find((u) => u.id === id).name;
    },
  },
};
</script>