<template>
  <div class="booking-overview">
    <Loader v-if="!content" :size="5" />
    <template v-else>
      <CustomAlert type="danger" v-if="error">
        <font-awesome-icon icon="exclamation-triangle"></font-awesome-icon>
        <span class="ml-2">{{
          error == 404
            ? $t("errors.contentNotFound")
            : $t("errors.unableToLoadReservation")
        }}</span>
      </CustomAlert>
      <CustomTabset :tabs="packageTabs" @tab-changed="tabChanged">
        <template v-for="pack of packageTabs" :slot="'tab-pane-' + pack.id">
          <b-row no-gutters :key="pack.id">
            <b-col cols="4">
              <VueCalendar
                :locale="locale"
                :validDates="validDates"
                :attributes="attributes"
                :startDate="focusDate"
                @month-change="monthChange"
                @day-click="dayClick"
              />

              <div class="mt-3 border rounded bg-light p-2" v-if="daySelected">
                <div class="booking-stat available">
                  <h6>
                    {{ $t("components.bookingOverview.stillAvailable") }}
                  </h6>
                  <span class="text-primary">{{ stillAvailableBook }}</span>
                </div>
                <div class="booking-stat confirmed">
                  <h6>
                    {{ $t("components.bookingOverview.confirmedBooking") }}
                  </h6>
                  <span class="text-success">{{ confirmedBookings }}</span>
                </div>
                <div class="booking-stat pending">
                  <h6>
                    {{ $t("components.bookingOverview.pendingBooking") }}
                  </h6>
                  <span class="text-warning">{{ pendingBookings }}</span>
                </div>
                <div class="booking-stat rejected">
                  <h6>
                    {{ $t("components.bookingOverview.rejectedBooking") }}
                  </h6>
                  <span class="text-danger">{{ rejectedBookings }}</span>
                </div>
                <div class="booking-stat expired">
                  <h6>
                    {{ $t("components.bookingOverview.expiredBooking") }}
                  </h6>
                  <span class="text-secondary">{{ expiredBookings }}</span>
                </div>

                <hr />

                <div class="booking-stat">
                  <h6>
                    {{ $t("components.bookingOverview.guestConfirmed") }}
                  </h6>
                  <span>{{ totalConfirmedGuests }} </span>
                </div>
                <div class="booking-stat">
                  <h6>
                    {{ $t("components.bookingOverview.guestPending") }}
                  </h6>
                  <span>{{ totalPendingGuests }}</span>
                </div>
                <div class="booking-stat">
                  <h6>
                    {{ $t("components.bookingOverview.totalProfit") }}
                  </h6>
                  <PriceLabel :price="totalProfit" size="md" />
                </div>
              </div>
            </b-col>

            <b-col class="pl-4" cols="8">
              <template v-if="slotTabs != undefined">
                <CustomTabset
                  :tabs="slotTabs[pack.id]"
                  @tab-changed="slotTabChanged"
                >
                  <template
                    v-for="tSlot of slotTabs[pack.id]"
                    :slot="'tab-pane-' + tSlot.id"
                  >
                    <div :key="tSlot.id" v-if="daySelected">
                      <h6>
                        {{
                          $t("components.bookingOverview.bookingList", {
                            date: daySelectedFormatted,
                          })
                        }}
                      </h6>

                      <CustomAlert
                        v-if="
                          !allDayBookedMap[daySelected].some(
                            (book) => book.slotId === tSlot.id
                          )
                        "
                        type="info"
                      >
                        <font-awesome-icon
                          icon="info-circle"
                        ></font-awesome-icon>
                        <span class="ml-2">{{
                          $t("components.bookingOverview.noBookings")
                        }}</span>
                      </CustomAlert>

                      <div class="bookings-list" v-else>
                        <div
                          v-for="(booking, index) of allDayBookedMap[
                            daySelected
                          ]"
                          :key="index"
                          @click="openBooking(booking.id)"
                        >
                          <div
                            :class="[
                              'booking-item',
                              getBookingClass(booking.status, false, true),
                            ]"
                            v-if="booking.slotId === tSlot.id"
                          >
                            <b-col class="pl-2">
                              {{ $t("common.adults") }}:
                              {{ booking.adultAmount }}
                              <br />
                              {{ $t("common.children") }}:
                              {{ booking.kidAmount }}
                            </b-col>
                            <b-col cols="4">
                              {{
                                booking.from
                                  | getDateTime({ minutes: timeZone })
                              }}
                              <font-awesome-icon
                                icon="angle-double-right"
                                class="text-info"
                                size="sm"
                              ></font-awesome-icon>
                              {{
                                booking.to | getDateTime({ minutes: timeZone })
                              }}
                            </b-col>
                            <b-col cols="3" class="text-right">
                              <PriceLabel :price="booking.sum" size="md" />
                            </b-col>
                            <b-col cols="1" class="text-center">
                              <font-awesome-icon
                                fixed-width
                                :icon="getBookingIcon(booking.status)"
                                :class="getBookingClass(booking.status, true)"
                              >
                              </font-awesome-icon>
                            </b-col>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </CustomTabset>
              </template>
              <template v-else>
                <div v-if="daySelected">
                  <h6>
                    {{
                      $t("components.bookingOverview.bookingList", {
                        date: daySelectedFormatted,
                      })
                    }}
                  </h6>

                  <CustomAlert
                    v-if="!allDayBookedMap[`${daySelected}`]"
                    type="info"
                  >
                    <font-awesome-icon icon="info-circle"></font-awesome-icon>
                    <span class="ml-2">{{
                      $t("components.bookingOverview.noBookings")
                    }}</span>
                  </CustomAlert>

                  <div class="bookings-list" v-else>
                    <div
                      v-for="(booking, index) of allDayBookedMap[
                        `${daySelected}`
                      ]"
                      :key="index"
                      :class="[
                        'booking-item',
                        getBookingClass(booking.status, false, true),
                      ]"
                      @click="openBooking(booking.id)"
                    >
                      <b-col class="pl-2">
                        {{ $t("common.adults") }}: {{ booking.adultAmount }}
                        <br />
                        {{ $t("common.children") }}: {{ booking.kidAmount }}
                      </b-col>
                      <b-col cols="4">
                        {{ booking.from | getDateTime({ minutes: timeZone }) }}
                        <font-awesome-icon
                          icon="angle-double-right"
                          class="text-info"
                          size="sm"
                        ></font-awesome-icon>
                        {{ booking.to | getDateTime({ minutes: timeZone }) }}
                      </b-col>
                      <b-col cols="3" class="text-right">
                        <PriceLabel :price="booking.sum" size="md" />
                      </b-col>
                      <b-col cols="1" class="text-center">
                        <font-awesome-icon
                          fixed-width
                          :icon="getBookingIcon(booking.status)"
                          :class="getBookingClass(booking.status, true)"
                        >
                        </font-awesome-icon>
                      </b-col>
                    </div>
                  </div>
                </div>
              </template>
            </b-col>
          </b-row>
        </template>
      </CustomTabset>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "../variables.scss";

.booking-overview {
  .booking-stat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-weight: 600;
    padding: 0rem 0.5rem 0 1rem;

    &:not(:last-of-type) {
      margin-bottom: 0.25rem;
    }

    h6 {
      margin: 0;
      font-weight: 600;
      font-size: 0.85rem;
      font-family: "Quicksand";
    }

    &.available::before {
      background-color: $geo-info;
    }
    &.confirmed::before {
      background-color: $geo-success;
    }
    &.pending::before {
      background-color: $geo-warning;
    }
    &.rejected::before {
      background-color: $geo-danger;
    }
    &.expired::before {
      background-color: $geo-muted;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0.125rem;
      border-radius: 5px;
      width: 5px;
      height: 85%;
    }
  }

  .bookings-list {
    .booking-item {
      padding: 0.25rem 1rem;
      border: 1px solid $default-gray;
      border-left: 5px solid transparent;
      cursor: pointer;
      display: flex;
      border-radius: 0.25rem;
      align-items: center;

      &:not(:last-of-type) {
        margin-bottom: 0.25rem;
      }

      &:hover {
        box-shadow: 0 0 0 3px rgba($geo-info, 0.75);
      }

      &.success {
        border-left-color: $geo-success;
      }
      &.warning {
        border-left-color: $geo-warning;
      }
      &.danger {
        border-left-color: $geo-danger;
      }
      &.secondary {
        border-left-color: $geo-muted;
      }
    }
  }
}
</style>

<script>
import { Availability, Booking, Contents } from "../api";

import CustomTabset from "../components/CustomTabset.vue";
import PriceLabel from "../components/PriceLabel.vue";
import VueCalendar from "../components/VueCalendar.vue";
import Loader from "../components/Loader.vue";
import CustomAlert from "../components/CustomAlert.vue";
import { handleError } from "../utils";

import {
  endOfMonth,
  startOfMonth,
  format,
  eachDayOfInterval,
  setMonth,
  setYear,
} from "date-fns";
import { getBookingClass, getBookingIcon } from "../utils";

export default {
  name: "BookingOverview",
  components: {
    PriceLabel,
    CustomTabset,
    VueCalendar,
    Loader,
    CustomAlert,
  },
  props: {
    contentId: {
      type: String,
      required: true,
    },
    startDate: {
      type: String,
      default: null,
    },
    packageId: String,
  },
  data: function () {
    return {
      initialized: false,
      error: undefined,
      focusDate: null,
      timeZone: new Date().getTimezoneOffset(),
      locale: this.$i18n.locale,
      monthStart: null,
      monthEnd: null,
      availabilities: null,
      availabilityMap: null,
      validDates: [],
      attributes: [
        {
          key: "partiallyBookedDay",
          highlight: {
            color: "yellow",
            fillMode: "outline",
          },
          dates: null,
        },
        {
          key: "totallyBookedDay",
          highlight: "green",
          dates: null,
        },
        {
          key: "selectedDay",
          highlight: "blue",
          dates: null,
        },
      ],
      packageBookings: null,
      content: null,
      packageTabs: null,
      slotTabs: null,
      activeSlotId: null,
      activePackageId: null,
      daySelected: null,
      mappedBookings: null,
      allDayBookedMap: null,
    };
  },
  created() {
    this.monthStart = startOfMonth(new Date());
    this.monthEnd = endOfMonth(this.monthStart);

    if (this.startDate) {
      this.focusDate = new Date(this.startDate);
      this.monthStart = startOfMonth(this.focusDate);
      this.monthEnd = endOfMonth(this.focusDate);
    }

    Contents.getContent(this.contentId).then(
      (res) => {
        this.content = res.data;
        this.packageTabs = this.content.packages.map((pack) => ({
          id: pack.id,
          title: this.getFieldTranslation(pack, "title"),
        }));
        if (this.packageId) {
          this.packageTabs = this.packageTabs.filter(
            (pack) => pack.id == this.packageId
          );
        }

        if (this.content.packages.some((pack) => pack.timeSlots.length > 0)) {
          this.slotTabs = {};
          this.content.packages.forEach((pack) => {
            if (pack.timeSlots.length > 0) {
              let timeslots = pack.timeSlots.map((x) => ({
                id: x.id,
                title: x.from + " > " + x.to,
              }));
              this.slotTabs[pack.id] = timeslots;
            }
          });
        }

        this.activePackageId = this.packageTabs[0].id;
        this.getPackageBookings();
        this.calculateAvailableDates();

        if (this.slotTabs) {
          let firstPackageSlot = Object.keys(this.slotTabs)[0];
          this.activeSlotId = this.slotTabs[firstPackageSlot][0].id;
        }
      },
      (err) => {
        const response = err.response;
        this.error = response.status;
        handleError(
          this,
          err,
          "messages.retrieveInfoErrorMsg",
          "messages.retrieveInfoError"
        );
      }
    );
  },
  computed: {
    daySelectedFormatted() {
      return format(new Date(this.daySelected), "dd-MM-yyyy");
    },
    confirmedBookings() {
      if (!this.allDayBookedMap[this.daySelected]) {
        return 0;
      }

      if (this.activeSlotId) {
        return this.allDayBookedMap[this.daySelected].reduce(
          (count, booking) => {
            if (
              booking.status == "paid" &&
              booking.slotId === this.activeSlotId
            ) {
              count += 1;
            }
            return count;
          },
          0
        );
      }

      return this.allDayBookedMap[this.daySelected].reduce((count, booking) => {
        if (booking.status == "paid") {
          count += 1;
        }
        return count;
      }, 0);
    },
    pendingBookings() {
      if (!this.allDayBookedMap[this.daySelected]) {
        return 0;
      }

      if (this.activeSlotId) {
        return this.allDayBookedMap[this.daySelected].reduce(
          (count, booking) => {
            if (
              booking.status == "pending" &&
              booking.slotId === this.activeSlotId
            ) {
              count += 1;
            }
            return count;
          },
          0
        );
      }

      return this.allDayBookedMap[this.daySelected].reduce((count, booking) => {
        if (booking.status == "pending") {
          count += 1;
        }
        return count;
      }, 0);
    },
    rejectedBookings() {
      if (!this.allDayBookedMap[this.daySelected]) {
        return 0;
      }

      if (this.activeSlotId) {
        return this.allDayBookedMap[this.daySelected].reduce(
          (count, booking) => {
            if (
              booking.status == "rejected" &&
              booking.slotId === this.activeSlotId
            ) {
              count += 1;
            }
            return count;
          },
          0
        );
      }

      return this.allDayBookedMap[this.daySelected].reduce((count, booking) => {
        if (booking.status == "rejected") {
          count += 1;
        }
        return count;
      }, 0);
    },
    expiredBookings() {
      if (!this.allDayBookedMap[this.daySelected]) {
        return 0;
      }

      if (this.activeSlotId) {
        return this.allDayBookedMap[this.daySelected].reduce(
          (count, booking) => {
            if (
              booking.status == "payment_expired" &&
              booking.slotId === this.activeSlotId
            ) {
              count += 1;
            }
            return count;
          },
          0
        );
      }

      return this.allDayBookedMap[this.daySelected].reduce((count, booking) => {
        if (booking.status == "payment_expired") {
          count += 1;
        }
        return count;
      }, 0);
    },
    totalConfirmedGuests() {
      if (!this.allDayBookedMap[this.daySelected]) {
        return 0;
      }

      if (this.activeSlotId) {
        return this.allDayBookedMap[this.daySelected].reduce(
          (count, booking) => {
            if (
              booking.status == "paid" &&
              booking.slotId === this.activeSlotId
            ) {
              count += booking.adultAmount + booking.kidAmount;
            }
            return count;
          },
          0
        );
      }

      return this.allDayBookedMap[this.daySelected].reduce((count, booking) => {
        if (booking.status == "paid") {
          count += booking.adultAmount + booking.kidAmount;
        }
        return count;
      }, 0);
    },
    totalPendingGuests() {
      if (!this.allDayBookedMap[this.daySelected]) {
        return 0;
      }

      if (this.activeSlotId) {
        return this.allDayBookedMap[this.daySelected].reduce(
          (count, booking) => {
            if (
              booking.status == "pending" &&
              booking.slotId === this.activeSlotId
            ) {
              count += booking.adultAmount + booking.kidAmount;
            }
            return count;
          },
          0
        );
      }

      return this.allDayBookedMap[this.daySelected].reduce((count, booking) => {
        if (booking.status == "pending") {
          count += booking.adultAmount + booking.kidAmount;
        }
        return count;
      }, 0);
    },
    stillAvailableBook() {
      if (!this.availabilityMap[this.daySelected]) {
        return 0;
      }
      if (this.activeSlotId) {
        return this.availabilityMap[this.daySelected].slots.find(
          (x) => x.slotId === this.activeSlotId
        ).amount;
      }
      return this.availabilityMap[this.daySelected].amount;
    },
    totalProfit() {
      if (!this.allDayBookedMap[this.daySelected]) {
        return 0;
      }

      if (this.activeSlotId) {
        return this.allDayBookedMap[this.daySelected].reduce(
          (count, booking) => {
            if (
              booking.status == "paid" &&
              booking.slotId === this.activeSlotId
            ) {
              count += booking.sum;
            }
            return count;
          },
          0
        );
      }

      return this.allDayBookedMap[this.daySelected].reduce((count, booking) => {
        if (booking.status == "paid") {
          count += booking.sum;
        }
        return count;
      }, 0);
    },
  },
  methods: {
    getPackageBookings(start, end) {
      Booking.getAllRequests({
        package: this.activePackageId,
        start: start || format(this.monthStart, "yyyy-MM-dd"),
        end: end || format(this.monthEnd, "yyyy-MM-dd"),
      }).then(
        (res) => {
          this.packageBookings = res.data.sort((a, b) => {
            if (this.statusAsNumber(a.status) < this.statusAsNumber(b.status)) {
              return -1;
            }
            if (this.statusAsNumber(a.status) > this.statusAsNumber(b.status)) {
              return 1;
            }
            return 0;
          });

          this.mappedBookings = this.packageBookings.map((book) => ({
            id: book.id,
            slotId: book.slotId,
            status: book.status,
            adultAmount: book.adultAmount,
            kidAmount: book.kidAmount,

            dates: eachDayOfInterval({
              start: new Date(book.start.split("T")[0]),
              end: new Date(book.end.split("T")[0]),
            }),
            from: book.start,
            to: book.end,
            sum: book.sum,
          }));

          this.allDayBookedMap = this.mappedBookings.reduce(
            (accumulator, book) => {
              book.dates.forEach((date) => {
                let key = format(date, "yyyy-MM-dd");
                if (!accumulator[key]) {
                  accumulator[key] = [];
                }
                accumulator[key].push(book);
              });
              return accumulator;
            },
            {}
          );

          this.attributes[0].dates = Object.keys(this.allDayBookedMap);
        },
        (err) => {
          const response = err.response;
          this.error = response.status;
          handleError(
            this,
            err,
            "messages.retrieveInfoErrorMsg",
            "messages.retrieveInfoError"
          );
        }
      );
    },
    calculateAvailableDates(start, end) {
      Availability.getAvailability(
        this.activePackageId,
        start || format(this.monthStart, "yyyy-MM-dd"),
        end || format(this.monthEnd, "yyyy-MM-dd")
      ).then(
        (res) => {
          this.availabilities = res.data;
          this.initialized = true;

          this.validDates = this.availabilities.map((date) => ({
            start: date.day,
            end: date.day,
          }));

          this.availabilityMap = this.availabilities.reduce(
            (accumulator, date) => {
              let key = date["day"];
              accumulator[key] = {
                amount: date["amount"],
                slots: date["slots"],
              };

              return accumulator;
            },
            {}
          );

          if (!this.initialized) {
            this.initialized = true;
          }

          let dayCompleted = [];
          Object.keys(this.allDayBookedMap).forEach((bookedDay) => {
            if (this.availabilityMap[`${bookedDay}`] == undefined) {
              dayCompleted.push(bookedDay);
            }
          });
          this.attributes[1].dates = dayCompleted;

          if (this.startDate) {
            this.dayClick({ id: format(this.focusDate, "yyyy-MM-dd") });
          }
        },
        (err) => {
          const response = err.response;
          this.error = response.status;
          handleError(
            this,
            err,
            "messages.retrieveInfoErrorMsg",
            "messages.retrieveInfoError"
          );
        }
      );
    },

    tabChanged(packageId) {
      this.activePackageId = packageId;
    },
    slotTabChanged(slotId) {
      this.activeSlotId = slotId;
    },
    monthChange(evt) {
      if (this.initialized) {
        this.daySelected = null;
        this.toggleHighlightSelectedDay();

        let start = startOfMonth(
          setYear(setMonth(new Date(), evt.month - 1), evt.year)
        );
        let end = endOfMonth(start);
        start = format(start, "yyyy-MM-dd");
        end = format(end, "yyyy-MM-dd");
        this.getPackageBookings(start, end);
        this.calculateAvailableDates(start, end);
      }
    },
    dayClick(evt) {
      if (!this.availabilityMap[evt.id] && !this.allDayBookedMap[evt.id]) {
        return;
      }
      this.daySelected = evt.id;
      this.toggleHighlightSelectedDay();
    },
    toggleHighlightSelectedDay() {
      this.daySelected
        ? (this.attributes[2].dates = this.daySelected)
        : (this.attributes[2].dates = null);
    },
    openBooking(bookingId) {
      let route = this.$router.resolve({
        name: "RequestDetail",
        params: { contentId: this.contentId, id: bookingId },
      });
      window.open(route.href, "_blank");
    },
    statusAsNumber(status) {
      switch (status) {
        case "wait_payment":
          return 0;
        case "pending":
          return 1;
        case "paid":
          return 2;
        case "rejected":
          return 3;
        default:
          return 4;
      }
    },
    getBookingClass(status, text, variant) {
      return getBookingClass(status, text, variant);
    },
    getBookingIcon(status) {
      return getBookingIcon(status);
    },
  },
};
</script>
