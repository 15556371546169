<template>
  <div class="custom-search">
    <div class="input-wrapper">
      <b-icon icon="search" class="search-icon" />
      <b-form-input
        :name="inputId"
        :id="inputId"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('input', $event)"
        @keydown.enter="$emit('search')"
        size="sm"
        :class="{ 'group-border': showButton }"
        :disabled="disabled"
      >
      </b-form-input>
      <div
        class="close-icon"
        v-if="value.length > 0"
        @click="$emit('input', '')"
      >
        <b-icon icon="x" />
      </div>
    </div>

    <FlatButton
      v-if="showButton"
      class="search-button"
      variant="warning"
      @click.native="$emit('search')"
    >
      <b-icon class="text-dark" icon="arrow-right"></b-icon>
    </FlatButton>
  </div>
</template>

<style lang="scss">
.custom-search {
  display: flex;

  .search-button {
    height: 32px;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border: 1px solid #ced4da;
  }

  .input-wrapper {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    .form-control {
      padding-left: 2rem;
      height: 32px;

      &.group-border {
        border-right-width: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .search-icon,
    .close-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .search-icon {
      left: 0.5rem;
    }

    .close-icon {
      padding: 0.125rem;
      cursor: pointer;
      right: 0;
      font-size: 1.5rem;
      line-height: 1;
    }
  }
}
</style>

<script>
import FlatButton from "./FlatButton.vue";

export default {
  components: { FlatButton },
  name: "CustomSearch",
  props: {
    inputId: String,
    placeholder: String,
    showButton: Boolean,
    value: String,
    disabled: Boolean,
  },
};
</script>