<template>
  <b-card class="user-cart" no-body>
    <b-row class="p-2 border-bottom" no-gutters align-v="center">
      <div class="px-3">
        <font-awesome-icon size="lg" icon="shopping-cart"></font-awesome-icon>
      </div>
      <b-col class="px-2">
        <h5 class="mb-0">{{$t('components.cart.cart')}}</h5>
        <span class="text-muted" v-if="items">{{ items.length }} {{$t('common.found')}}</span>
      </b-col>
    </b-row>

    <Loader v-if="!items" />

    <div v-else class="cart-items">
      <CustomAlert v-if="!items.length">
        <font-awesome-icon icon="info-circle"></font-awesome-icon>
        <span class="ml-2">{{$t('components.cart.noElements')}}</span>
      </CustomAlert>

      <CartItem
        v-for="(item, index) of items"
        :key="index"
        :item="item"
        @remove="removeItem(index)"
      />
    </div>

    <b-row no-gutters align-h="end" class="p-2 border-top">
      <PriceLabel :price="cartTotal" />
      <RaisedButton class="ml-3" variant="success" @click.native="showPayModal">
        <font-awesome-icon icon="cash-register"></font-awesome-icon>
        <span class="ml-2">{{$t('components.cart.pay')}}</span>
      </RaisedButton>
    </b-row>

    <b-modal
      id="checkout-wizard-modal"
      size="xl"
      centered
      hide-footer
      hide-header
      title="Wizard"
    >
      <CheckoutWizard
        :items="items"
        :auto="retrieveAutoBooking"
        :manual="retrieveManualBooking"
        :autoTotal="autoTotal"
      ></CheckoutWizard>
    </b-modal>
  </b-card>
</template>

<style lang="scss" scoped>
.user-cart {
  display: flex;
  flex-direction: column;
  height: 100%;

  .cart-items {
    flex: 1 0;
    padding: 1rem;
    overflow-y: auto;
  }
}
</style>

<script>
import Loader from "../components/Loader.vue";
import CartItem from "../components/CartItem.vue";
import CustomAlert from "../components/CustomAlert.vue";
import PriceLabel from "../components/PriceLabel.vue";
import { EventBus } from "../eventBus";
import RaisedButton from "../components/RaisedButton.vue";
import CheckoutWizard from "../components/CheckoutWizard.vue";
import { calculateDiscount } from "../utils";

export default {
  name: "Cart",
  components: {
    PriceLabel,
    CustomAlert,
    CartItem,
    Loader,
    RaisedButton,
    CheckoutWizard,
  },
  data: () => ({
    items: null,
  }),
  computed: {
    cartTotal: function () {
      return this.items
        ? this.items.reduce((acc, item) => acc + (item.discount === 0 ? item.sum : calculateDiscount(item.sum,item.discount)), 0)
        : 0;
    },
    retrieveAutoBooking: function () {
      return this.items.filter((it) => it.type === "auto");
    },
    autoTotal: function () {
      return this.retrieveAutoBooking
        ? this.retrieveAutoBooking.reduce((acc, item) => acc + (item.discount === 0 ? item.sum : calculateDiscount(item.sum,item.discount)), 0)
        : 0;
    },
    retrieveManualBooking: function () {
      return this.items.filter((it) => it.type === "manual");
    },
  },
  created() {
    this.initItems();
  },
  methods: {
    initItems() {
      const cart = this.$cookies.get("atlas_cart") || "[]";
      this.items = JSON.parse(cart);
    },
    removeItem(index) {
      this.items.splice(index, 1);
      const newCart = JSON.stringify(this.items);
      this.$cookies.set("atlas_cart", newCart, Infinity);
      EventBus.$emit("cart-item-removed");
    },
    showPayModal() {
      this.$bvModal.show("checkout-wizard-modal");
    },
  },
};
</script>