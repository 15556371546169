import { client } from './setup';
import { USERS_API } from '../globals';

export default {
    getUsers: (params) => client.get(USERS_API, { params }),
    getUser: (id) => client.get(`${USERS_API}/${id}`),
    createUser: (user, provider) => client.post(`${USERS_API}/${provider ? 'provider' : 'user'}`, user),
    setPassword: (token, password) => client.post(`${USERS_API}/verify`, { token, password }),
    updateUser: (user) => client.put(`${USERS_API}/${user.id}`, user),
    deleteUser: (id) => client.delete(`${USERS_API}/${id}`),
    toggleUserStatus: (id, status) => client.patch(`${USERS_API}/${id}/${status}`),
}