import { Availability } from "./Availability";
import { Price } from "./Price";
import { Translation } from "./Translation";
export class NewPackage {

    constructor(
        id,
        title,
        description,
        price,
        availability,
        timeSlots,
        minPeople,
        maxPeople,
    ) {
        this.id = id || undefined;
        this.title = title || new Translation('Nuovo Pacchetto', 'New Package');
        this.description = description || new Translation();
        this.price = price || new Price();
        this.availability = availability || new Availability();
        this.timeSlots = timeSlots || [];
        this.minPeople = minPeople || 1;
        this.maxPeople = maxPeople || 0;
    }
}