import { client } from './setup';
import { ACCOUNT_API } from '../globals';

export default {
    getAccountInfo: () => client.get(ACCOUNT_API),
    changePassword: (body) => client.patch(`${ACCOUNT_API}/change-password`, body),
    getAccountContents: (params) => client.get(`${ACCOUNT_API}/contents`, { params }),
    getAccountContentsPage: (page) => client.post(`${ACCOUNT_API}/contents`, page),
    updateAccount: (account) => client.put(ACCOUNT_API, account),
    deleteAccount: () => client.delete(ACCOUNT_API),
}