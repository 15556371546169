export class TimeSlot {

    constructor(
        from,
        to,
        amount,
    ) {
        this.from = from;
        this.to = to;
        this.amount = amount || 0;
    }
}