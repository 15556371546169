<template>
  <div class="pack-editor">
    <CustomTabset :tabs="packageTabs">
      <template slot="tab-pane-overview">
        <div class="package-overview" key="package-overview">
          <b-row>
            <b-col cols="12" xl="6" class="pr-2">
              <b-form-group
                id="pack-title-it-fieldset"
                :label="$t('components.pricePlanEditor.titleIt')"
                label-for="pack-title-it"
              >
                <b-form-input
                  id="pack-title-it"
                  v-model.trim="value.title[0].text"
                  :placeholder="$t('components.pricePlanEditor.titleIt')"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="pack-description-it-fieldset"
                :label="$t('components.pricePlanEditor.descriptionIt')"
                label-for="pack-description-it"
              >
                <b-form-textarea
                  id="pack-description-it"
                  rows="2"
                  v-model.trim="value.description[0].text"
                  :placeholder="$t('components.pricePlanEditor.descriptionIt')"
                ></b-form-textarea>
              </b-form-group>
            </b-col>

            <b-col cols="12" xl="6" class="pl-2">
              <b-form-group
                id="pack-title-en-fieldset"
                :label="$t('components.pricePlanEditor.titleEn')"
                label-for="pack-title-en"
              >
                <b-form-input
                  id="pack-title-en"
                  v-model.trim="value.title[1].text"
                  :placeholder="$t('components.pricePlanEditor.titleEn')"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="pack-description-en-fieldset"
                :label="$t('components.pricePlanEditor.descriptionEn')"
                label-for="pack-description-en"
              >
                <b-form-textarea
                  id="pack-description-en"
                  rows="2"
                  v-model.trim="value.description[1].text"
                  :placeholder="$t('components.pricePlanEditor.descriptionEn')"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="p-3 border">
            <h6 class="mb-3">
              <font-awesome-icon icon="users"></font-awesome-icon>
              <span class="ml-2">{{
                $t("components.pricePlanEditor.people")
              }}</span>
            </h6>

            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('components.pricePlanEditor.minPeople')"
                  class="mb-0"
                  label-cols="8"
                  label-for="pack-min-people-field"
                >
                  <b-form-input
                    id="pack-min-people-field"
                    type="number"
                    min="0"
                    placeholder="Min"
                    v-model.number="value.minPeople"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group
                  :label="$t('components.pricePlanEditor.maxPeople')"
                  class="mb-0"
                  label-cols="8"
                  label-for="pack-max-people-field"
                >
                  <b-form-input
                    id="pack-max-people-field"
                    type="number"
                    min="0"
                    placeholder="Max"
                    v-model.number="value.maxPeople"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
      <template slot="tab-pane-price">
        <div class="package-price" key="package-price">
          <CustomCheckbox
            :label="$t('components.pricePlanEditor.isFree')"
            id="is-free-checkbox"
            v-model="value.price.isFree"
            class="mb-3"
          />

          <b-row class="mb-3" align-v="end" v-if="!value.price.isFree">
            <b-col cols="12" xl="4">
              <b-form-group
                id="pack-adults-fieldset"
                class="mb-0"
                :label="
                  value.price.perPerson
                    ? $t('common.adults')
                    : $t('common.price')
                "
                label-for="pack-adults"
              >
                <PriceInput
                  id="pack-adults"
                  v-model.number="value.price.adultPrice"
                  :placeholder="
                    value.price.perPerson
                      ? $t('common.adults')
                      : $t('common.price')
                  "
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" xl="4" v-if="value.price.perPerson">
              <b-form-group
                id="pack-kids-fieldset"
                class="mb-0"
                :label="$t('common.children')"
                label-for="pack-kids"
              >
                <PriceInput
                  id="pack-kids"
                  v-model.number="value.price.kidPrice"
                  :placeholder="$t('common.children')"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" xl="4">
              <CustomCheckbox
                :label="$t('components.pricePlanEditor.pricePerPerson')"
                id="price-per-person-checkbox"
                v-model="value.price.perPerson"
              />
            </b-col>
          </b-row>

          <div class="p-3 border" v-if="!value.price.isFree">
            <b-row no-gutters class="mb-2">
              <b-col>
                <h6 class="mb-0">
                  <font-awesome-icon icon="plus-circle"></font-awesome-icon>
                  <span class="ml-2">{{
                    $t("components.pricePlanEditor.option")
                  }}</span>
                </h6>
              </b-col>

              <button
                type="button"
                class="btn btn-sm btn-light border"
                @click="$bvModal.show('new-option')"
              >
                <font-awesome-icon icon="plus"></font-awesome-icon>
              </button>
            </b-row>

            <div class="options-list">
              <CustomAlert type="info" v-if="!value.price.options.length">
                <font-awesome-icon icon="info-circle"></font-awesome-icon>
                <span class="ml-2">{{
                  $t("components.pricePlanEditor.noOption")
                }}</span>
              </CustomAlert>

              <template v-else>
                <div
                  class="option-item"
                  v-for="(option, index) of value.price.options"
                  :key="index"
                >
                  <b-col class="px-1">
                    <div class="option-title">
                      <span>{{ option.title[0].text }}</span>
                      <span class="mx-2">|</span>
                      <span class="text-muted">{{ option.title[1].text }}</span>
                    </div>

                    <table>
                      <tbody>
                        <tr>
                          <th class="pr-2" scope="col">
                            {{ $t("common.adults") }}
                          </th>
                          <td>
                            <span class="price-value"
                              >€ {{ option.adultPrice }}</span
                            >
                            <span class="ml-2" v-if="option.perPerson">{{
                              $t("common.pricePerPerson")
                            }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th class="pr-2" scope="col">
                            {{ $t("common.children") }}
                          </th>
                          <td>
                            <span class="price-value"
                              >€ {{ option.kidPrice }}</span
                            >
                            <span class="ml-2" v-if="option.perPerson">{{
                              $t("common.pricePerPerson")
                            }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>

                  <div class="px-3">
                    <IconButton class="mr-3" @click.native="editOption(index)">
                      <font-awesome-icon icon="pen"></font-awesome-icon>
                    </IconButton>
                    <IconButton
                      class="text-danger"
                      @click.native="value.price.options.splice(index, 1)"
                    >
                      <font-awesome-icon icon="trash"></font-awesome-icon>
                    </IconButton>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <b-modal
            hide-footer
            size="lg"
            :title="$t('components.pricePlanEditor.newOption')"
            id="new-option"
            @hidden="resetOptionModal"
          >
            <b-form-row>
              <b-col>
                <b-form-group
                  :label="$t('components.pricePlanEditor.titleIt')"
                  label-for="option-title-it-field"
                >
                  <b-form-input
                    id="option-title-it-field"
                    :placeholder="$t('components.pricePlanEditor.titleIt')"
                    v-model.trim="newOption.title[0].text"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group
                  :label="$t('components.pricePlanEditor.titleEn')"
                  label-for="option-title-en-field"
                >
                  <b-form-input
                    id="option-title-en-field"
                    :placeholder="$t('components.pricePlanEditor.titleEn')"
                    v-model.trim="newOption.title[1].text"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>

            <div class="p-3 border">
              <h6>
                <font-awesome-icon icon="wallet"></font-awesome-icon>
                <span class="ml-2">{{
                  $t("components.pricePlanEditor.prices")
                }}</span>
              </h6>

              <b-form-group
                :label="
                  newOption.perPerson ? $t('common.adults') : $t('common.price')
                "
                label-cols="4"
                label-for="option-adults-field"
              >
                <b-form-input
                  id="option-adults-field"
                  type="number"
                  step=".01"
                  :placeholder="
                    newOption.perPerson
                      ? $t('common.adults')
                      : $t('common.price')
                  "
                  v-model.number="newOption.adultPrice"
                >
                </b-form-input>
              </b-form-group>

              <b-form-group
                :label="$t('common.children')"
                label-cols="4"
                label-for="option-kids-field"
                v-if="newOption.perPerson"
              >
                <b-form-input
                  id="option-kids-field"
                  type="number"
                  step=".01"
                  :placeholder="$t('common.children')"
                  v-model.number="newOption.kidPrice"
                >
                </b-form-input>
              </b-form-group>

              <CustomCheckbox
                :label="$t('components.pricePlanEditor.pricePerPerson')"
                id="price-per-person-option-checkbox"
                v-model="newOption.perPerson"
              />
            </div>

            <b-row no-gutters class="pt-3" align-h="between">
              <b-button
                size="sm"
                variant="light"
                class="border"
                @click="$bvModal.hide('new-option')"
                >{{ $t("actions.cancel") }}</b-button
              >
              <b-button size="sm" variant="success" @click="submitOption">{{
                $t("components.pricePlanEditor.addOption")
              }}</b-button>
            </b-row>
          </b-modal>
        </div>
      </template>
      <template slot="tab-pane-availability">
        <div class="package-availability" key="package-availability">
          <div class="mb-2" v-if="value.timeSlots.length == 0">
            <b-row no-gutters align-v="center">
              <font-awesome-icon icon="user-friends"></font-awesome-icon>
              <b-form-group
                :label="$t('components.availabilityVerifier.availableSpots')"
                class="mb-0 ml-2"
                label-cols="9"
                label-for="available-spots-field"
              >
                <b-form-input
                  id="available-spots-field"
                  type="number"
                  min="0"
                  v-model.number="value.availability.amount"
                >
                </b-form-input>
              </b-form-group>
            </b-row>
            <hr />
          </div>

          <div>
            <b-row no-gutters align-v="center" class="mb-2">
              <h6 class="mb-0">
                <font-awesome-icon icon="calendar-alt"></font-awesome-icon>
                <span class="ml-2">
                  {{ $t("components.availabilityForm.months") }}
                </span>
              </h6>

              <div class="ml-auto">
                <FlatButton
                  class="px-2 border"
                  size="sm"
                  @click.native="selectAllMonths"
                >
                  <font-awesome-icon
                    :icon="allMonthsSelected ? 'times' : 'check'"
                  ></font-awesome-icon>
                  <span class="ml-2">{{
                    allMonthsSelected
                      ? $t("actions.deselectAll")
                      : $t("actions.selectAll")
                  }}</span>
                </FlatButton>
              </div>
            </b-row>

            <b-row no-gutters>
              <b-col :cols="2" v-for="(month, index) of months" :key="index">
                <CheckableItem
                  :label="month.label"
                  :value="month.selected"
                  @input="monthChecked($event, index)"
                />
              </b-col>
            </b-row>
          </div>
          <br />
          <hr />
          <b-row class="mb-2" no-gutters align-v="center">
            <h6 class="mb-0">
              <font-awesome-icon icon="calendar-week"></font-awesome-icon>
              <span class="ml-2">
                {{ $t("components.availabilityForm.daysOfTheWeek") }}
              </span>
            </h6>

            <div class="ml-auto">
              <FlatButton
                class="px-2 border"
                size="sm"
                @click.native="selectAllDays"
              >
                <font-awesome-icon
                  :icon="allDaysSelected ? 'times' : 'check'"
                ></font-awesome-icon>
                <span class="ml-2">{{
                  allDaysSelected
                    ? $t("actions.deselectAll")
                    : $t("actions.selectAll")
                }}</span>
              </FlatButton>
            </div>
          </b-row>

          <b-row no-gutters>
            <b-col v-for="(day, index) of daysOfWeek" :key="index">
              <CheckableItem
                :label="day.label"
                :value="day.selected"
                @input="dayChecked($event, index)"
              />
            </b-col>
          </b-row>

          <br />
          <hr />
          <b-row no-gutters align-v="center" class="mb-2">
            <b-col>
              <h6 class="mb-0">
                <font-awesome-icon icon="calendar-times"></font-awesome-icon>
                <span class="ml-2">{{
                  $t("components.availabilityForm.negRange")
                }}</span>
              </h6>
            </b-col>
            <button
              type="button"
              class="btn btn-sm btn-light border"
              @click="showRangeModal(true)"
            >
              <font-awesome-icon icon="plus"></font-awesome-icon>
            </button>
          </b-row>

          <div class="availability-list">
            <CustomAlert
              type="info"
              v-if="!value.availability.negativeRanges.length"
            >
              <font-awesome-icon icon="info-circle"></font-awesome-icon>
              <span class="ml-2">{{
                $t("components.packageEditor.noUnavailableRange")
              }}</span>
            </CustomAlert>
            <template v-else>
              <AvailabilityRangesList
                :rangeList="value.availability.negativeRanges"
                @edit-range="openRangeModal"
                :isNegative="true"
                @delete-range="deleteRange"
              ></AvailabilityRangesList>
            </template>
          </div>
          <b-modal
            hide-footer
            size="md"
            id="availability-range-modal"
            :title="
              isAddingNegativeRange
                ? editRangeIndex != null
                  ? $t('components.availabilityForm.editNegRange')
                  : $t('components.availabilityForm.newNegRange')
                : editRangeIndex != null
                ? $t('components.availabilityForm.editPosRange')
                : $t('components.availabilityForm.newPosRange')
            "
            @hidden="resetRangeModal"
          >
            <AvailabilityRangeForm
              :editRangeDates="editRangeDates"
              @add-range="addRange"
              @edit-range="editRange"
            ></AvailabilityRangeForm>
          </b-modal>
        </div>
      </template>
      <template slot="tab-pane-timeslot">
        <div class="package-timeslot" key="package-timeslot">
          <b-row no-gutters align-h="center" class="mb-2">
            <RaisedButton
              type="button"
              class="px-3"
              @click.native="$bvModal.show('time-slot-modal')"
            >
              <font-awesome-icon icon="plus"></font-awesome-icon>
              <span class="ml-2">{{ $t("actions.add") }}</span>
            </RaisedButton>
          </b-row>
          <div class="timeslots-list">
            <CustomAlert type="warning" v-if="!value.timeSlots.length">
              <font-awesome-icon icon="info-circle"></font-awesome-icon>
              <span class="ml-2">{{
                $t("components.timeSlotEditor.needTime")
              }}</span>
            </CustomAlert>
            <template v-else>
              <TimeSlotsList
                :timeSlots="value.timeSlots"
                @edit-time-slot="openTimeSlotModal"
                @delete-time-slot="deleteTimeSlot"
              >
              </TimeSlotsList>
            </template>
          </div>

          <b-modal
            hide-footer
            size="md"
            id="time-slot-modal"
            :title="
              editTimeIndex != null
                ? $t('components.timeSlotEditor.editTimeSlot')
                : $t('components.timeSlotEditor.addTimeSlot')
            "
            @hidden="resetTimeSlotModal"
          >
            <TimeSlotsEditor
              :editTime="editTime"
              @add-time-slot="addTimeSlot"
              @edit-time-slot="editTimeSlot"
            />
          </b-modal>
        </div>
      </template>
    </CustomTabset>
  </div>
</template>

<style lang="scss" scoped>
@import "@/variables.scss";

.options-list {
  max-height: 250px;
  overflow-y: auto;

  .option-item {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;

    .option-title {
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
    }
    .price-label {
      font-weight: 500;
    }
  }
}
</style>

<script>
import IconButton from "../components/IconButton.vue";
import RaisedButton from "../components/RaisedButton.vue";
import CustomCheckbox from "../components/CustomCheckbox.vue";
import CustomAlert from "../components/CustomAlert.vue";
import CustomTabset from "../components/CustomTabset.vue";
import PriceInput from "../components/PriceInput.vue";
import TimeSlotsEditor from "./TimeSlotsEditor.vue";
import TimeSlotsList from "./TimeSlotsList.vue";
import AvailabilityRangeForm from "./AvailabilityRangeForm.vue";
import AvailabilityRangesList from "./AvailabilityRangesList.vue";
import CheckableItem from "./CheckableItem.vue";
import FlatButton from "./FlatButton.vue";
import { PricePlanOption } from "../models/PricePlanOption";

import _ from "lodash";
import moment from "moment";

export default {
  name: "PackageEditor",
  props: {
    value: Object,
  },
  components: {
    RaisedButton,
    IconButton,
    PriceInput,
    CustomAlert,
    CustomCheckbox,
    CustomTabset,
    TimeSlotsEditor,
    TimeSlotsList,
    AvailabilityRangeForm,
    FlatButton,
    CheckableItem,
    AvailabilityRangesList,
  },
  data() {
    return {
      packageTabs: [
        {
          id: "overview",
          title: this.$t("components.packageEditor.description"),
        },
        {
          id: "price",
          title: this.$t("components.packageEditor.price"),
        },
        {
          id: "availability",
          title: this.$t("components.packageEditor.availability"),
        },
        {
          id: "timeslot",
          title: this.$t("components.packageEditor.timeslots"),
        },
      ],
      availability: this.value.availability,
      timeSlots: this.value.timeSlots,
      newOption: new PricePlanOption(),
      editIndex: undefined,
      isAddingNegativeRange: false,
      editRangeIndex: null,
      editRangeDates: [],
      editTime: undefined,
      editTimeIndex: null,
      months: [...Array(12)].map((_, index) => ({
        label: `months.${index}`,
        value: this.$t(`months.${index}`, "en"),
        selected: true,
      })),
      daysOfWeek: [...Array(7)].map((_, index) => ({
        label: `weekDays.${index}`,
        value: this.$t(`weekDays.${index}`, "en"),
        selected: true,
      })),
    };
  },
  created: function () {
    if (
      this.availability.daysOfWeek &&
      this.availability.daysOfWeek.length > 0
    ) {
      this.daysOfWeek.forEach((day) => {
        day.selected = this.availability.daysOfWeek.includes(day.value);
      });
    } else {
      this.availability.daysOfWeek = this.daysOfWeek.map((day) => day.value);
    }

    if (
      this.availability.monthsOfYear &&
      this.availability.monthsOfYear.length > 0
    ) {
      this.months.forEach((month) => {
        month.selected = this.availability.monthsOfYear.includes(month.value);
      });
    } else {
      this.availability.monthsOfYear = this.months.map((month) => month.value);
    }
  },
  computed: {
    allMonthsSelected: function () {
      return this.months.find((month) => !month.selected) == undefined;
    },
    allDaysSelected: function () {
      return this.daysOfWeek.find((day) => !day.selected) == undefined;
    },
  },
  methods: {
    selectAllMonths() {
      this.months = this.months.map((month) => ({
        ...month,
        selected: !this.allMonthsSelected,
      }));

      this.availability.monthsOfYear = this.months
        .filter((month) => month.selected)
        .map((mont) => mont.value);
    },
    selectAllDays() {
      this.daysOfWeek = this.daysOfWeek.map((day) => ({
        ...day,
        selected: !this.allDaysSelected,
      }));

      this.availability.daysOfWeek = this.daysOfWeek
        .filter((day) => day.selected)
        .map((day) => day.value);
    },
    monthChecked(checked, index) {
      this.months[index].selected = checked;

      this.availability.monthsOfYear = this.months
        .filter((month) => month.selected)
        .map((mont) => mont.value);
    },
    dayChecked(checked, index) {
      this.daysOfWeek[index].selected = checked;

      this.availability.daysOfWeek = this.daysOfWeek
        .filter((day) => day.selected)
        .map((day) => day.value);
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("ddd D MMM YYYY");
    },
    resetOptionModal() {
      this.editIndex = undefined;
      this.newOption = new PricePlanOption();
    },
    submitOption() {
      this.editIndex != undefined
        ? (this.value.price.options[this.editIndex] = this.newOption)
        : this.value.price.options.push(this.newOption);
      this.$bvModal.hide("new-option");
    },
    editOption(index) {
      this.editIndex = index;
      this.newOption = _.cloneDeep(this.value.price.options[index]);
      this.$bvModal.show("new-option");
    },
    showRangeModal(isNegative) {
      if (isNegative) {
        this.isAddingNegativeRange = isNegative;
      }
      this.$bvModal.show("availability-range-modal");
    },
    addRange(start, end) {
      if (!this.isAddingNegativeRange) {
        this.availability.positiveRanges.push([start, end]);
      } else {
        this.availability.negativeRanges.push([start, end]);
      }
      this.$bvModal.hide("availability-range-modal");
    },
    openRangeModal(index, isNegative) {
      this.editRangeIndex = index;
      this.editRangeDates = isNegative
        ? this.availability.negativeRanges[index]
        : this.availability.positiveRanges[index];
      this.isAddingNegativeRange = isNegative;
      this.$bvModal.show("availability-range-modal");
    },
    editRange(start, end) {
      this.isAddingNegativeRange
        ? this.$set(this.availability.negativeRanges, this.editRangeIndex, [
            start,
            end,
          ])
        : this.$set(this.availability.positiveRanges, this.editRangeIndex, [
            start,
            end,
          ]);
      this.$bvModal.hide("availability-range-modal");
    },
    deleteRange(index, isNegative) {
      isNegative
        ? this.availability.negativeRanges.splice(index, 1)
        : this.availability.positiveRanges.splice(index, 1);
    },
    resetRangeModal() {
      this.editRangeIndex = null;
      this.editRangeDates = [];
      this.isAddingNegativeRange = false;
    },
    addTimeSlot(timeSlot) {
      this.value.timeSlots.push(timeSlot);
      this.$bvModal.hide("time-slot-modal");
    },
    openTimeSlotModal(index) {
      this.editTimeIndex = index;
      this.editTime = this.value.timeSlots[index];
      this.$bvModal.show("time-slot-modal");
    },
    editTimeSlot(timeSlot) {
      this.$set(this.value.timeSlots, this.editTimeIndex, timeSlot);
      this.$bvModal.hide("time-slot-modal");
    },
    deleteTimeSlot(index) {
      this.value.timeSlots.splice(index, 1);
    },
    resetTimeSlotModal() {
      this.editTimeIndex = null;
      this.editTime = undefined;
    },
  },
};
</script>