<template>
  <div class="cart-item">
    <div
      class="item-image"
      :style="{
        backgroundImage: 'url(' + contentImageUrl + item.content.id + '/main)',
      }"
    ></div>

    <b-col class="pl-3 ml-2">
      <b-row align-v="center" no-gutters>
        <b-col class="overflow-hidden">
          <router-link
            :to="{ name: 'ContentDetail', params: { id: item.contentId } }"
          >
            <h6 class="mb-0 text-truncate">
              {{ getFieldTranslation(item.content, "title") }}
            </h6>
          </router-link>
        </b-col>

        <div class="pl-2">
          <FlatButton class="text-danger" @click.native="$emit('remove')">
            <font-awesome-icon icon="trash"></font-awesome-icon>
            <span class="ml-2">{{ $t("actions.delete") }}</span>
          </FlatButton>
        </div>
      </b-row>

      <table>
        <tbody>
          <tr>
            <th scope="col">Pacchetto</th>
            <td>{{ getFieldTranslation(item.package, "title") }}</td>
          </tr>
          <tr>
            <th scope="col">Date</th>
            <td
              class="ml-2"
              v-if="item.start.substring(0, 10) === item.end.substring(0, 10)"
            >
              {{ item.start | formatDate(true) }}
            </td>
            <td v-else class="ml-2">
              {{ item.start | formatDate(true) }} ->
              {{ item.end | formatDate(true) }}
            </td>
          </tr>
          <tr v-if="item.slotId">
            <th scope="col">Slot Orario</th>
            <td>{{ item.slot.from }} - {{ item.slot.to }}</td>
          </tr>
          <tr>
            <th scope="col">Partecipanti</th>
            <td>{{ item.adultAmount + item.kidAmount }}</td>
          </tr>
        </tbody>
      </table>

      <PriceLabel
        v-if="item.discount === 0"
        class="text-muted text-right"
        :price="item.sum"
      />
      <div v-else class="d-flex justify-content-end pb-3">
        <PriceLabel
          class="discounted text-right"
          :price="calculateDiscount(item.sum, item.discount)"
        />
      </div>
    </b-col>
  </div>
</template>

<script>
import { MULTIMEDIA_API } from "../globals";
import PriceLabel from "./PriceLabel.vue";
import FlatButton from "./FlatButton.vue";
import { calculateDiscount } from "../utils";

export default {
  name: "CartItem",
  props: {
    item: Object,
  },
  components: {
    FlatButton,
    PriceLabel,
  },
  data: () => ({
    contentImageUrl: `${MULTIMEDIA_API}/data/`,
  }),
  methods: {
    calculateDiscount(price, discount) {
      return calculateDiscount(price, discount);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/variables.scss";

.cart-item {
  display: flex;
  padding: 0.25rem;
  border: 1px solid $default-gray;

  .discounted {
    transform: skewY(-8deg);
    background-color: #ff3a56;
    border-radius: 3px 4px 4px 3px;
    position: relative;
    color: white;
    padding: 0 10px 0 10px;
  }

  table {
    width: 40%;
    min-width: 300px;
    th,
    td {
      padding: 0.125rem 0.5rem;
    }
    td {
      text-align: right;
    }
  }

  .item-image {
    width: 12rem;
    height: 12rem;
    // border: 1px solid $default-gray;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
}
</style>