import { client } from './setup';
import { CONTENTS_API, CONTENTS_SERVICE_API } from '../globals';

export default {
    getContents: (params) => client.get(CONTENTS_SERVICE_API, { params }),
    retrievePageContents: (page, params) => client.post(CONTENTS_SERVICE_API, page, {params}),
    getShortContents: (params) => client.get(`${CONTENTS_API}/short`, {params}),
    getContent: (id, params) => client.get(`${CONTENTS_API}/${id}`, { params }),
    createContent: (content) => client.post(CONTENTS_API, content),
    updateContent: (content) => client.put(`${CONTENTS_API}/${content.id}`, content),
    deleteContent: (id) => client.delete(`${CONTENTS_API}/${id}`),
    contentApproval: (id, approved, message) => client.patch(`${CONTENTS_API}/${id}/approval`, { approved, message }),
    getSuperClusterContents: (clusterContents) => client.post(`${CONTENTS_API}/brieflist`, clusterContents),
}