export class PricePlan {

    constructor(
        title, // required
        description, // required
        baseAdults,
        baseKids,
        perPerson,
        minPeople,
        maxPeople,
        options,
    ) {
        this.title = title || { it_IT: 'Nuovo Piano' };
        this.description = description || { it_IT: '' };
        this.baseAdults = baseAdults || undefined;
        this.baseKids = baseKids || undefined;
        this.perPerson = perPerson || false;
        this.minPeople = minPeople || 1;
        this.maxPeople = maxPeople || 0;
        this.options = options || [];
    }
}