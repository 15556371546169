<template>
  <b-dropdown class="flat-dropdown" variant="flat" right no-caret>
    <template #button-content>
      <font-awesome-icon icon="language" size="lg"></font-awesome-icon>
    </template>

    <b-dropdown-item
      @click="changeLocale('it')"
      :class="{ selected: isCurrentLocale('it') }"
    >
      <b-row no-gutters align-v="center">
        <img src="../assets/flag_ita.png" width="18" />
        <span class="ml-3">{{ $t("language.it.short") }}</span>
      </b-row>
    </b-dropdown-item>

    <b-dropdown-item
      @click="changeLocale('en')"
      :class="{ selected: isCurrentLocale('en') }"
    >
      <b-row no-gutters align-v="center">
        <img src="../assets/flag_eng.png" width="18" />
        <span class="ml-3">{{ $t("language.en.short") }}</span>
      </b-row>
    </b-dropdown-item>
  </b-dropdown>
</template>

<style lang="scss">
@import "@/variables.scss";

.flat-dropdown {
  .selected .dropdown-item {
    font-weight: 600;
    color: $accent;

    &::after {
      content: "";
      position: absolute;
      right: 1.5rem;
      top: 50%;
      transform: translateY(-50%);
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: $accent;
    }
  }

  .dropdown-item {
    position: relative;

    &:active {
      background-color: #d5d5d5;
      color: #2e2e2e;
    }
    &:focus {
      background-color: #e5e5e5;
    }
  }

  &.show > .btn-flat.dropdown-toggle {
    box-shadow: 0 0 0 3px #ccc;
    background-color: #f3f3f3;
    border: none;
    color: #2e2e2e;
  }

  & > .btn {
    background-color: transparent;
    border: none;
    color: #5f5f5f;

    &:focus,
    &:active {
      border: none;
      box-shadow: none;
      background-color: #e5e5e5;
    }

    &:hover {
      background-color: #e5e5e5;
      color: #3f3f3f;
    }
  }
}
</style>

<script>
import moment from "moment";
export default {
  name: "LocalesCombo",
  methods: {
    changeLocale(locale) {
      if (this.$i18n.locale == locale) return;

      moment.locale(locale);
      this.$i18n.locale = locale;
      localStorage.setItem("geo_locale", locale);
    },
    isCurrentLocale(locale) {
      return this.$i18n.locale == locale;
    },
  },
};
</script>