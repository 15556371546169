<template>
  <div class="user-edit">
    <Loader v-if="user === undefined"></Loader>
    <div v-else>
      <b-row
        class="border-bottom flex-column px-3 pb-3 justify-content-between mb-3"
      >
        <h5 class="mb-1">
          {{
            isProvider
              ? $t("admin.users.edit.newProvider")
              : $t("admin.users.edit.newUser")
          }}
        </h5>
        <p class="text-muted mb-0">
          {{
            isProvider
              ? $t("admin.users.edit.newProviderDescription")
              : $t("admin.users.edit.newUserDescription")
          }}
        </p>
      </b-row>

      <validation-observer ref="userObserver" v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(saveUser)">
          <b-row>
            <b-col>
              <validation-provider
                name="Username"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-group
                  id="user-username-fieldset"
                  label="Username"
                  label-for="user-username"
                >
                  <b-form-input
                    id="user-username"
                    v-model.trim="user.username"
                    :placeholder="
                      $t('admin.users.edit.placeholders.accountUsername')
                    "
                    :state="getValidationState(validationContext)"
                  ></b-form-input>

                  <b-form-invalid-feedback id="user-username-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col>
              <validation-provider
                name="Platform Fees"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-group
                  id="provider-platformFees-fieldset"
                  :label="$t('admin.users.edit.platformFee')"
                  label-for="provider-platformFee"
                >
                  <b-form-input
                    id="provider-platformFee"
                    type="number"
                    min="0"
                    max="100"
                    v-model.number="user.platformFee"
                    :placeholder="
                      $t('admin.users.edit.placeholders.platformFee')
                    "
                    :state="getValidationState(validationContext)"
                  ></b-form-input>

                  <b-form-invalid-feedback id="user-platformFee-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                name="First Name"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-group
                  id="user-firstName-fieldset"
                  :label="$t('admin.users.edit.firstname')"
                  label-for="user-firstName"
                >
                  <b-form-input
                    id="user-firstName"
                    v-model.trim="user.firstName"
                    :placeholder="
                      $t('admin.users.edit.placeholders.userFirstname')
                    "
                    :state="getValidationState(validationContext)"
                  ></b-form-input>

                  <b-form-invalid-feedback id="user-firstName-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col>
              <validation-provider
                name="Last Name"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-group
                  id="user-lastName-fieldset"
                  :label="$t('admin.users.edit.lastname')"
                  label-for="user-lastName"
                >
                  <b-form-input
                    id="user-lastName"
                    v-model.trim="user.lastName"
                    :placeholder="
                      $t('admin.users.edit.placeholders.userLastname')
                    "
                    :state="getValidationState(validationContext)"
                  ></b-form-input>

                  <b-form-invalid-feedback id="user-lastName-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <br />

          <h6 class="text-muted">{{ $t("admin.users.edit.contacts") }}</h6>
          <b-form-group
            id="user-email-contact-fieldset"
            label="Email"
            label-for="user-email-contact"
            :label-cols="4"
          >
            <b-form-input
              id="user-email-contact"
              v-model.trim="user.contacts.email"
              :placeholder="$t('admin.users.edit.placeholders.contactEmail')"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="user-telephone-contact-fieldset"
            :label="$t('admin.users.edit.telephone')"
            label-for="user-telephone-contact"
            :label-cols="4"
          >
            <b-form-input
              id="user-telephone-contact"
              v-model.trim="user.contacts.telephone"
              :placeholder="
                $t('admin.users.edit.placeholders.contactTelephone')
              "
            ></b-form-input>
          </b-form-group>

          <br />

          <b-row class="mx-0 align-items-center justify-content-between">
            <b-button
              type="button"
              variant="light"
              class="border"
              @click="$router.push({ name: 'UsersList' })"
              >{{ $t("admin.users.edit.cancel") }}</b-button
            >
            <RaisedButton type="submit" variant="success">{{
              $t("admin.users.edit.submit")
            }}</RaisedButton>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-edit {
  border-radius: 0.25rem;
  background-color: white;
  padding: 1rem;
}
</style>

<script>
import { Users } from "../../../api";
import { User } from "../../../models/User";
import RaisedButton from "../../../components/RaisedButton.vue";
import Loader from "../../../components/Loader.vue";
import { handleError } from "../../../utils";
export default {
  name: "UserEdit",
  components: {
    RaisedButton,
    Loader,
  },
  data() {
    return {
      isEditing: false,
      user: undefined,
      isProvider: this.$route.name == "RegisterProvider",
    };
  },
  created: function () {
    this.initUser();
  },
  methods: {
    initUser() {
      const id = this.$route.params.id;
      if (id) {
        Users.getUser(id).then(
          (res) => {
            this.user = res.data;
            this.isEditing = true;
          },
          (err) => {
            console.error(err);
            handleError(
              this,
              err,
              "messages.userInfoErrorMsg",
              "messages.userError"
            );
          }
        );
      } else {
        this.user = new User();
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    saveUser() {
      this.$loading.show();

      this.user.lang = this.$i18n.locale;

      if (this.isEditing) {
        Users.updateUser(this.user).then(
          () => {
            this.$loading.hide();
            this.$root.$bvToast.toast(this.$t("messages.userUpdatedMsg"), {
              title: this.$t("messages.userUpdated"),
              variant: "success",
              solid: true,
            });
            this.$router.push({ name: "UsersList" });
          },
          (err) => {
            this.$loading.hide();
            handleError(
              this,
              err,
              "messages.userUpdatedErr",
              "messages.userError"
            );
          }
        );
      } else {
        Users.createUser(this.user, this.isProvider).then(
          () => {
            this.$loading.hide();
            this.$root.$bvToast.toast(this.$t("messages.userCreatedMsg"), {
              title: this.$t("messages.userCreated"),
              variant: "success",
              solid: true,
            });
            this.$router.push({ name: "UsersList" });
          },
          (err) => {
            this.$loading.hide();
            handleError(
              this,
              err,
              "messages.userSaveErr",
              "messages.userError"
            );
          }
        );
      }
    },
  },
};
</script>
